<template>
  <div class="conceptions">
    <section-scroller :handleClick="nextSection" />

    <fullpage-dots
      :activeIndex="getConceptionActiveIndex"
      :length="getConceptionLength"
      @changeScreen="changeScreen"
      @toUp="moveSectionUp"
      @toDown="moveSectionDown"
    />

    <div
      class="conceptions__images"
      v-if="getConceptionList.length !== 0"
      ref="images"
    >
      <div
        class="conceptions__imagewrap"
        v-for="(item, index) in getConceptionList[activeIndex].images"
        :key="index"
        :class="getImageClass(index)"
      >
        <img
          :src="item"
          alt=""
          class="conceptions__image conceptions__image--center"
        />
      </div>
    </div>

    <h1 class="hide">Как мы работаем</h1>

    <full-page
      ref="fullpage"
      :options="options"
      id="fullpage"
      :skip-init="true"
    >
      <div
        class="section conceptions__section"
        v-for="(item, index) in getConceptionList"
        :key="index"
      >
        <div class="container">
          <div class="row">
            <div class="col-5">
              <div class="conceptions__itemwrap">
                <div class="conceptions__item">
                  <div class="conceptions__titlewrap">
                    <h2
                      class="conceptions__title conceptions__title--active"
                      v-html="item.title"
                    ></h2>
                  </div>

                  <div class="conceptions__textwrap" v-html="item.text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import SectionScroller from "./components/SectionScroller";
import FullpageDots from "./components/FullpageDot";

import { TweenMax, Linear } from "gsap";

export default {
  name: "Conceptions",
  computed: {
    ...mapGetters([
      "getConceptionList",
      "getConceptionLength",
      "getConceptionActiveIndex",
      "getCurrentLanguage",
    ]),
  },
  components: {
    SectionScroller,
    FullpageDots,
  },
  mounted() {
    this.fetchConceptions();
  },
  updated() {
    if (!this.iterated) {
      this.componentsReady();
      this.iterated = true;
    }
  },
  watch: {
    getCurrentLanguage: function () {
      this.fetchConceptions();
    },
  },
  data() {
    return {
      iterated: false,
      activeIndex: 0,
      options: {
        licenseKey: "B8E82C8C-C7DF4CDB-8316FC6F-EE23BEC2",
        menu: "#menu",
        scrollingSpeed: 1600,
        easingcss3: "cubic-bezier(0.645, 0.045, 0.355, 1.000)",
        lockAnchors: true,

        afterLoad: (origin, destination, direction) =>
          this.afterSlideLoaded(origin, destination, direction),
        onLeave: (origin, destination, direction) =>
          this.onSlideLeave(origin, destination, direction),
      },
    };
  },
  methods: {
    ...mapMutations([
      "afterConceptionLoad",
      "onConceptionLeave",
      "setConceptionActiveIndex",
    ]),

    ...mapActions(["fetchConceptions"]),

    componentsReady() {
      this.$refs.fullpage.init();
    },

    afterSlideLoaded(origin, destination, direction) {
      this.afterConceptionLoad({ origin, destination, direction });
    },

    onSlideLeave(origin, destination, direction) {
      this.onConceptionLeave({ origin, destination, direction });

      let leftImage = document.querySelector(".conceptions__imagewrap--left");
      let mainImage = document.querySelector(".conceptions__imagewrap--main");
      let rightImage = document.querySelector(".conceptions__imagewrap--right");

      TweenMax.to(leftImage, 0.8, {
        yPercent: 340,
        ease: Linear.easeNone,
        onComplete() {
          TweenMax.set(leftImage, {
            yPercent: -340,
            onComplete() {
              TweenMax.to(leftImage, 0.8, {
                yPercent: 0,
                ease: Linear.easeNone,
              });
            },
          });
        },
      });

      TweenMax.to(rightImage, 0.8, {
        yPercent: 200,
        ease: Linear.easeNone,
        onComplete() {
          TweenMax.set(rightImage, {
            yPercent: -200,
            onComplete() {
              TweenMax.to(rightImage, 0.8, {
                yPercent: 0,
                ease: Linear.easeNone,
              });
            },
          });
        },
      });

      TweenMax.to(mainImage, 0.8, {
        yPercent: "-=150",
        ease: Linear.easeNone,
        onComplete(context) {
          TweenMax.set(mainImage, {
            yPercent: 150,
            onComplete() {
              TweenMax.to(mainImage, 0.8, {
                yPercent: 0,
                ease: Linear.easeNone,
              });
            },
          });

          context.activeIndex = destination.index;
        },
        onCompleteParams: [this],
      });
    },

    nextSection() {
      this.$refs.fullpage.api.moveSectionDown();
    },

    changeScreen(index) {
      this.$refs.fullpage.api.moveTo(index);
    },

    moveSectionUp() {
      this.$refs.fullpage.api.moveSectionUp();
    },

    moveSectionDown() {
      this.$refs.fullpage.api.moveSectionDown();
    },

    getImageClass(index) {
      if (index == 0) {
        return "conceptions__imagewrap--left conceptions__imagewrap--side";
      } else if (index == 1) {
        return "conceptions__imagewrap--center  conceptions__imagewrap--main";
      } else if (index == 2) {
        return "conceptions__imagewrap--right conceptions__imagewrap--side";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>