<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
    import {mapMutations, mapActions} from 'vuex'

    export default {
        name:'NewsPage',
        mounted(){
            this.fetchNewsList()
            this.setMetaInfo({
                page:'news',
            })
        },
        methods:{
            ...mapMutations([
                'setMetaInfo',
            ]),
            ...mapActions([
                'fetchNewsList',
            ])
        }
    }
</script>

<style lang="scss" scoped>

</style>