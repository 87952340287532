<template>
	<section class="white">
		<div class="container">
			<div class="seo_form">
				<div class="seo_form_left">
					<div class="seo_form_title">Остались вопросы?</div>
					<div class="seo_form_title">
						Напишите нам, и мы обязательно ответим!
					</div>
				</div>
				<div class="seo_form_right sended" v-if="sended">
					<div class="seo_form_title">
						{{ $t("messages.mainpage.contacts3") }}
					</div>
				</div>
				<div class="seo_form_right" v-else>
					<div class="seo_form_input input_fio">
						<div class="seo_form_title">ФИО</div>
						<input
							type="text"
							placeholder="Имя Фамилия"
							v-model="name"
						/>
					</div>
					<div class="seo_form_input input_tel">
						<div class="seo_form_title">Телефон</div>
						<input
							type="tel"
							placeholder="+7 (***) *** ** **"
							v-model="phone"
							v-mask="'+# ### ###-##-##'"
						/>
					</div>
					<div class="seo_form_input input_email">
						<div class="seo_form_title">Почта</div>
						<input
							type="email"
							placeholder="info@mail.com"
							v-model="email"
						/>
					</div>
					<div
						class="seo_form_error"
						v-if="error.status"
						v-html="error.text"
					></div>
					<button class="seo_button" @click="sendForm">
						Отправить!
					</button>
					<div class="seo_form_agree">
						Нажимая на кнопку, я соглашаюсь на обработку
						персональных данных и с правилами пользования Платформой
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { validationMixin } from "vuelidate";
	import { required, email, minLength } from "vuelidate/lib/validators";

	export default {
		name: "FormBlock",
		mixins: [validationMixin],
		validations: {
			name: {
				required,
			},
			phone: {
				required,
				minLength: minLength(6),
			},
			email: {
				required,
				email,
			},
		},
		data() {
			return {
				sended: false,

				error: {
					status: false,
					text: "",
				},

				name: "",
				email: "",
				phone: "",
			};
		},
		// props:{
		//     url:{
		//         type:String,
		//         required:false,
		//         default:'',},
		//     page:{
		//         type:String,
		//         required:false,
		//         default:'',},
		// },
		methods: {
			sendForm() {
				if (
					!this.$v.name.required ||
					!this.$v.phone.required ||
					!this.$v.email.required
				) {
					this.error.status = true;
					this.error.text = this.$t("messages.contacts.error0");
				} else if (!this.$v.phone.minLength) {
					this.error.status = true;
					this.error.text = this.$t("messages.contacts.error3");
				} else if (!this.$v.email.email) {
					this.error.status = true;
					this.error.text = this.$t("messages.contacts.error4");
				} else {
					this.error = {
						status: false,
						text: "",
					};

					this.$emit("onformsend", {
						name: this.name,
						email: this.email,
						phone: this.phone,
					});
					this.sended = true;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>