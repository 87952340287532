const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleClients = {
    state:{
        title:'',
        description:'',

        clients:[],

        _clients:[
            {
                srcWhite:require('@/assets/img/pages/clients/white/Astana Presidential Club_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/Astana Presidential Club.svg')
            },
            {
                srcWhite:require('@/assets/img/pages/clients/white/Astana Property Management_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/Astana Property Management.svg')
            },
            {
                srcWhite:require('@/assets/img/pages/clients/white/UNDP — United Nations Development Programme_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/UNDP — United Nations Development Programme.svg')
            },
            {
                srcWhite:require('@/assets/img/pages/clients/white/BI-Group_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/BI-Group.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/BTS Digital_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/BTS Digital.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/CSI — Центр стратегических инициатив_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/CSI — Центр стратегических инициатив.svg')
            },
            {
                srcWhite:require('@/assets/img/pages/clients/white/DOMA DOMA_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/DOMA DOMA.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/АО «КазМунайГаз»_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/АО «КазМунайГаз».svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/АО «Казпочта»_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/АО «Казпочта».svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/MMA Kazakhstan_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/MMA Kazakhstan.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/NAC Analytica_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/NAC Analytica.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/TEDx Astana_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/TEDx Astana.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/Penal Reform International (PRI)_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/Penal Reform International (PRI).svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/QazaqGeography_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/QazaqGeography.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/Qaztech Ventures_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/Qaztech Ventures.svg')
            },

            {
                srcWhite:require('@/assets/img/pages/clients/white/TEDx Almaty_white-01.svg'),
                srcDark:require('@/assets/img/pages/clients/TEDx Almaty.svg')
            },
            
        ]
    },

    mutations:{

    },

    actions:{
        fetchClients({state, rootGetters}){
            let locale  = rootGetters.getCurrentLanguageKey

            axios.post('https://api.blackandwhite.kz/bw/clients/?c=100&lang='+locale)
                .then((response)=>{
                    return response.data
                })
                .then((data)=>{
                    let {
                        clients,
                        header:{
                            TITLE:title,
                            DESCRIPTION:description
                        }
                    } = data

                    state.title = title
                    state.description = description

                    let sortedArr = clients.sort((a,b)=>{
                        return parseInt(a.SORT) - parseInt(b.SORT)
                    })

                    state.clients = sortedArr.map((item)=>{
                        return {
                            srcDark:item.IMG_SRC_BLACK,
                            srcWhite:item.IMG_SRC_WHITE,
                            link:item.LINK,
                            title:item.NAME,
                        }
                    })

                })
        }
    },

    getters:{

        getClientsInfo(state){
            return state.clients
        },

        getClientsTitle(state){
            return state.title
        },

        getClientsDescription(state){
            return state.description
        }

    }
}

export default moduleClients