import { render, staticRenderFns } from "./ProjectSiteBlock.vue?vue&type=template&id=ac545e26&scoped=true&"
import script from "./ProjectSiteBlock.vue?vue&type=script&lang=js&"
export * from "./ProjectSiteBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac545e26",
  null
  
)

export default component.exports