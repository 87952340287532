<template>
    <footer
        class="footer"
        :class="getFooterParams.fixed ? 'footer--fixed' : ''"
        id="footer"
    >
        <div class="container container--overvisible">
            <div class="row">
                <div class="col-10 col-xs-12 col-xs-start-1">
                    <div class="footer__linkswrap">
                        <div class="footer__textwrap">
                            <p
                                class="footer__text"
                                v-html="$t('messages.mainpage.footer')"
                            ></p>
                        </div>

                        <div class="footer__textwrap">
                            <p class="footer__text">© Black&White, 2022</p>
                        </div>
                        <div class="footer__textwrap">
                            <header-order-btn
                                :text="$t('messages.header.contacts')"
                            />
                        </div>

                        <div class="footer__textwrap">
                            <a href="tel:+7 778 234 12 95" class="footer__text">
                                +7 778 234 12 95
                            </a>
                        </div>

                        <div class="footer__textwrap">
                            <a
                                :href="'mailto:' + mail"
                                class="footer__text"
                                v-html="mail"
                            ></a>
                        </div>
                    </div>
                </div>

                <div class="col-2 col-xs-12">
                    <div
                        class="footer__socials"
                        v-lazy-container="{ selector: 'img' }"
                    >
                        <div class="footer__social">
                            <a
                                href="https://www.facebook.com/BlackWhiteGroup/"
                                target="_blank"
                            >
                                <img
                                    data-src="https://api.blackandwhite.kz/upload/bw/report/facebook.svg"
                                    alt=""
                                    class="footer__social-icon"
                                />
                            </a>
                        </div>

                        <div class="footer__social">
                            <a
                                href="https://vimeo.com/blackandwhiteproduction"
                                target="_blank"
                            >
                                <img
                                    data-src="https://api.blackandwhite.kz/upload/bw/report/vimeo.svg"
                                    alt=""
                                    class="footer__social-icon"
                                />
                            </a>
                        </div>

                        <div class="footer__social">
                            <a
                                href="https://instagram.com/bw_videoproduction?igshid=166ykkvlh4etu"
                                target="_blank"
                            >
                                <img
                                    data-src="https://api.blackandwhite.kz/upload/bw/report/instagram.svg"
                                    alt=""
                                    class="footer__social-icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";

import { TimelineMax, Power3 } from "gsap";
import HeaderOrderBtn from "../common/HeaderOrderBtn";
let footerTl = new TimelineMax();

export default {
    name: "AppFooter",
    components: { HeaderOrderBtn },
    mounted() {
        if (this.getFooterParams.fixed) {
            footerTl.set("#footer", {
                yPercent: 100,
            });
        } else {
            footerTl.set("#footer", {
                yPercent: 0,
            });
        }
    },
    computed: {
        ...mapGetters(["isLastSection", "getFooterParams", "getContactsInfo"]),
    },
    watch: {
        isLastSection: function (val) {
            if (val) {
                this.showFooter();
            } else {
                this.hideFooter();
            }
        },

        "getFooterParams.fixed": function (val) {
            if (val) {
                this.hideFooter();
            } else {
                this.showFooter();
            }
        },

        getContactsInfo: function (info) {
            if (info) {
                info.map((item) => {
                    item.map((info) => {
                        if (info.type == "tel" && this.phone == "") {
                            this.phone = info.value;
                        }

                        if (info.type == "mailto" && this.mail == "") {
                            this.mail = info.value;
                        }
                    });
                });
            }
        },
    },
    data() {
        return {
            phone: "",
            mail: "",
        };
    },
    methods: {
        showFooter() {
            footerTl.to("#footer", 0.6, {
                yPercent: 0,
                ease: Power3.easeInOut,
            });
        },

        hideFooter() {
            footerTl.to("#footer", 0.4, {
                yPercent: 100,
                ease: Power3.easeInOut,
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
