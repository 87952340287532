const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleBlog = {
	state: {
		bloglist: [],
		blogdetail: {},
	},

	actions: {
		fetchBlogList({ state, rootGetters }) {
			let locale = rootGetters.getCurrentLanguageKey;

			axios
				.post("https://api.blackandwhite.kz/bw/blog/?c=30&lang=" + locale)
				.then((response) => {
					return response.data.blog;
				})
				.then((blog) => {
					let bloglist = [];

					blog.map((item) => {
						let {
							CODE: router,
							ACTIVE_FROM: date,
							ID: id,
							NAME: title,
							PREVIEW_PICTURE: poster,
							PREVIEW_TEXT,
						} = item;

						bloglist.push({
							router,
							id,
							title,
							poster,

							// TODO: потом после изменений со стороны админки
							date: date.split(" ")[0],
							text: PREVIEW_TEXT,
						});
					});

					state.bloglist = bloglist;
				});
		},

		fetchBlogDetail({ state, rootGetters }, info) {
			let locale = rootGetters.getCurrentLanguageKey;
			let id = info.id;

			axios
				.post(
					"https://api.blackandwhite.kz/bw/blog/detail.php?id=" +
						id +
						"&lang=" +
						locale
				)
				.then((response) => {
					return response.data.blog[0];
				})
				.then((blogdetail) => {
					let {
						ID: id,
						CODE: route,
						ACTIVE_FROM: date,

						DETAIL_PICTURE: image,

						NAME: title,
						PREVIEW_TEXT: slogan,
						DETAIL_TEXT: text,

						DETAIL_PICTURE_SRC,
						ELEMENT_META_DESCRIPTION,
						ELEMENT_META_TITLE,
						CODE,
					} = blogdetail;

					let dateArr = date.split(" ");

					state.blogdetail = {
						id,
						date: dateArr[0],
						dateTime: dateArr[1],
						route,
						title,
						slogan,
						image: image == "https://api.blackandwhite.kz" ? "" : image,
						text,

						DETAIL_PICTURE_SRC,
						ELEMENT_META_DESCRIPTION,
						ELEMENT_META_TITLE,
						CODE,
					};
				});
		},
	},

	mutations: {},

	getters: {
		getBlogList(state) {
			return state.bloglist;
		},

		getBlogDetail(state) {
			return state.blogdetail;
		},
	},
};

export default moduleBlog;
