<template>
    <div id="fdots" class="fdots" ref="dots">

        <div class="fdots__arrow">
            <img src="@/assets/img/icons/arrow--dark.svg" @click="toUp" alt="" class="fdots__arrow-icon fdots__arrow-icon--dark">
            <img src="@/assets/img/icons/arrow.svg" @click="toUp" alt="" class="fdots__arrow-icon fdots__arrow-icon--light">
        </div>

        <div class="fdots__dots">
            <div class="fdots__dot" 
                 v-for="n in length" 
                 :key="n"
                 :class="(activeIndex+1)==n?'fdots__dot--active':''"
                 @click="changeScreen(n)"
                 ></div>

        </div>


        <div class="fdots__arrow  fdots__arrow--bottom">
            <img src="@/assets/img/icons/arrow--dark.svg" @click="toDown" alt="" class="fdots__arrow-icon fdots__arrow-icon--dark">
            <img src="@/assets/img/icons/arrow.svg" @click="toDown" alt="" class="fdots__arrow-icon fdots__arrow-icon--light">
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {
        TimelineMax,
        Power3,
    } from 'gsap'

    let fdotstl = new TimelineMax()

    export default {
        name:'FullpageDots',
        props:{
            length:{
                type:Number,
                required:false,
                default:0
            },
            activeIndex:{
                type:Number,
                required:false,
                default:0,
            }
        },
        computed:{
            ...mapGetters([
                'getScrolling',
                'getAppTheme',
            ]),
        },
        watch:{
            getScrolling:function(isscrolled){
                if(isscrolled){
                    fdotstl.reverse()
                } else{
                    fdotstl.play()

                }
            }
        },
        mounted(){
            let fdots = this.$refs.dots

            fdotstl
                .from(fdots, .5, {
                    alpha:0,
                    ease:Power3.easeInOut
                })

        },
        methods:{
            changeScreen(index){
                this.$emit('changeScreen',index)
            },
            toUp(){
                this.$emit('toUp')
            },
            toDown(){
                this.$emit('toDown')
            }
        }
    }
</script>

<style lang="scss" scoped>
    $arrowMargin:43px;
    $arrowMargin1600:33px;

    .fdots{
        position: fixed;
        top:0;
        bottom: 0;

        right: 70px;
        
        margin-top: auto;
        margin-bottom: auto;

        display:flex;
        flex-direction:column;
        justify-content:center;
        align-items:center;

        z-index: 97;

        &__arrow{
            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;

            margin-bottom: $arrowMargin;

            &-icon{
                cursor: pointer;

                width: 15px;
                height: 15px;

                transform: rotate(-90deg);
            }
        }
        
        &__arrow--bottom{
            transform: rotate(180deg);

            margin-bottom: 0px;
            margin-top: $arrowMargin;
        }

        &__dots{
            display: grid;
            grid-template: auto / auto ;
            grid-gap: $arrowMargin 0px;
        }

        &__dot{
            width: 11px;
            height: 11px;

            border:1px solid #fff;

            border-radius: 35px;

            cursor: pointer;
        }

        &__dot--active{
            background-color: #fff;;
        }
    }

     @media (max-width: 1600.98px) {
        .fdots{
            
            &__arrow{
                margin-bottom: $arrowMargin1600;
            }

            &__arrow--bottom{
                margin-top: $arrowMargin1600;
            }

            &__dots{
                grid-row-gap: $arrowMargin1600;
            }

            &__dot{
                width: 9px;
                height: 9px;
            }
        }
    }

    @media (max-width: 1024.98px) {
        .fdots{
            right: 15px;

            &__dots{
                grid-row-gap:22px;
            }
            
        }
    }

    @media (max-width: 768.98px) {
        .fdots{
            right: 15px;

            &__dots{
                grid-row-gap: 15px;
            }
            
        }
    }
</style>