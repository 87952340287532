<template>
    <div class="project">
        <script v-html="jsonld" type="application/ld+json"></script>
        <div
            class="project__back"
            @click="
                $router.push({ path: '/projects/', params: { fetch: false } })
            "
        >
            <div class="project__back-iconwrap">
                <img
                    src="@/assets/img/icons/arrow-double.svg"
                    alt=""
                    class="project__back-icon project__back-icon--light"
                />
                <img
                    src="@/assets/img/icons/arrow-double--dark.svg"
                    alt=""
                    class="project__back-icon project__back-icon--dark"
                />
            </div>
            <div class="project__back-textwrap">
                <p class="project__back-text">
                    {{ $t("messages.projectdetail.back") }}
                </p>
            </div>
        </div>

        <div class="container container--mobilenopad">
            <div class="row row--mobilepad">
                <div class="col-start-2 col-10">
                    <div class="project__titlewrap project__titlewrap--mb">
                        <h1
                            class="project__title"
                            v-html="getProjectInfo.title"
                        ></h1>
                    </div>

                    <div
                        class="project__textwrap"
                        v-html="getProjectInfo.headerText"
                    ></div>
                </div>
            </div>

            <div class="row" v-if="getProjectInfo.video">
                <div class="col-12">
                    <div class="project__imagewrap">
                        <div
                            style="
                                padding: 56.25% 0 0 0;
                                position: relative;
                                width: 100%;
                                height: auto;
                            "
                        >
                            <iframe
                                :src="
                                    'https://player.vimeo.com/video/' +
                                    getProjectInfo.video +
                                    '?title=0&byline=0&portrait=0'
                                "
                                style="
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                "
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row--mobilepad">
                <div class="col-start-2 col-10">
                    <div
                        class="project__textwrap"
                        v-html="getProjectInfo.mainText"
                    ></div>
                </div>
            </div>

            <div class="row">
                <div class="col-10 col-start-2"></div>
            </div>
        </div>

        <div class="project__sitewrap" v-if="getProjectInfo.siteInfo">
            <project-site-block
                v-if="getProjectInfo.siteInfo.hasSite"
                :info="getProjectInfo.siteInfo"
            />
        </div>

        <div class="project__slider" v-if="getProjectInfo.slider">
            <slider :elements="getProjectInfo.slider" :dots="true" />
        </div>

        <div class="container container--mobilenopad project__content">
            <div class="row row--mobilepad">
                <div class="col-start-2 col-10">
                    <div
                        class="project__textwrap"
                        v-html="getProjectInfo.footerText"
                    ></div>
                </div>
            </div>

            <div class="row" v-if="getProjectInfo.backstage">
                <div class="col-12">
                    <div class="project__imagewrap">
                        <div
                            style="
                                padding: 56.25% 0 0 0;
                                position: relative;
                                width: 100%;
                                height: auto;
                            "
                        >
                            <iframe
                                :src="
                                    'https://player.vimeo.com/video/' +
                                    getProjectInfo.backstage +
                                    '?title=0&byline=0&portrait=0'
                                "
                                style="
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                "
                                frameborder="0"
                                allow="autoplay; fullscreen"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row--mobilepad" v-if="getProjectInfo.rewards">
                <div
                    class="col-start-2 col-10"
                    v-if="getProjectInfo.rewards.length > 0"
                >
                    <div class="project__titlewrap">
                        <h2
                            class="project__title"
                            v-html="$t('messages.projectdetail.awards')"
                        ></h2>
                    </div>

                    <div class="project__awards">
                        <project-award
                            v-for="(item, index) in getProjectInfo.rewards"
                            :key="index"
                            :info="item"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="container" v-if="getProjectInfo.infolist">
            <div class="row">
                <div class="col-12">
                    <div
                        class="project__info"
                        v-if="getProjectInfo.infolist.length > 0"
                    >
                        <div
                            :class="
                                'project__info-item ' +
                                (!item.image
                                    ? 'project__info-item--noimage'
                                    : '')
                            "
                            v-for="(item, index) in getProjectInfo.infolist"
                            :key="index"
                        >
                            <div
                                class="project__info-item-imagewrap"
                                v-if="item.image"
                            >
                                <img
                                    :src="item.image"
                                    alt=""
                                    class="project__info-item-image"
                                />
                            </div>

                            <div
                                class="project__info-item-textwrap"
                                v-if="item.image"
                            >
                                <p class="project__info-item-text">
                                    <span>{{ item.info.value }}</span
                                    >{{ item.info.text }}
                                </p>
                            </div>

                            <div class="project__info-item-textwrap" v-else>
                                <p class="project__info-item-text">
                                    <span>{{ item.info.value }}</span>
                                </p>
                                <p class="project__info-item-text">
                                    {{ item.info.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="project__team" v-if="getProjectInfo.persons">
            <div class="container">
                <div class="row">
                    <div class="col-start-2 col-10">
                        <project-team
                            :persons="getProjectInfo.persons"
                            :info="getProjectInfo.info"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="project__similar" v-if="getProjectInfo.hasSimilar">
            <div class="container container--overvisible">
                <div class="row">
                    <div class="col-start-2 col-10" style="width: 100%">
                        <div class="project__titlewrap project__titlewrap--mb">
                            <h2
                                class="project__title"
                                v-html="$t('messages.projectdetail.similar')"
                            ></h2>
                        </div>

                        <div class="project__similar-list">
                            <project-similar
                                :elements="getProjectInfo.similar"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectTeam from "./components/ProjectTeam";
import ProjectAward from "./components/ProjectAward";
import ProjectSimilar from "./components/ProjectSimilar";
import ProjectSiteBlock from "./components/ProjectSiteBlock";

import Slider from "../../components/common/Slider";

// тестовое подключение свипера, если не работает удалить
// import Swiper from 'swiper';
import "swiper/swiper-bundle.css";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "ProjectsDetail",
    components: {
        ProjectAward,
        ProjectTeam,
        Slider,
        ProjectSimilar,
        ProjectSiteBlock,
    },
    data() {
        const jsonld = {
            "@context": "http://schema.org",
            "@type": "Article",
            headline: this.getProjectInfo.title,
            description: this.getProjectInfo.headerText,
            articleBody: this.getProjectInfo.headerText,
            wordcount: this.getProjectInfo.headerText.length,
        };
        return { jsonld };
    },
    computed: {
        ...mapGetters(["getProjects", "getProjectInfo", "getCurrentLanguage"]),
    },
    mounted() {
        setTimeout(() => {
            if (JSON.stringify(this.getProjectInfo) == JSON.stringify({})) {
                this.$router.push("/404");
            }
        }, 7000);
        if (this.getProjects.length > 0) {
            this.fetchProjectDetail({
                routename: this.$route.params.symbolcode,
            });
        }
    },
    destroyed() {
        this.resetProjectInfo();
    },
    watch: {
        getProjects: function (projects) {
            if (projects.length > 0) {
                this.fetchProjectDetail({
                    routename: this.$route.params.symbolcode,
                });
            }
        },
        getCurrentLanguage: function () {
            /*
                    todo: тут переключение изначально не может корректно работать, 
                    так как некоторых проектов просто нет в русской или в английской версии. 
                    Для того, чтобы этого не было, необходимо прописать функцию для проеверки условия. 
                    Если проект есть в другой языковой версии, то переключать язык, а если нет,
                    то просто возвращать на главную страницу. Пока просто будет возврашать на главную страницу
                */
            // this.fetchProjectDetail({routename:this.$route.params.symbolcode})
            this.$router.push("/projects");
        },

        getProjectInfo: function (info) {
            this.setMetaInfoByParams({
                title: info.seo.title,
                type: info.info[2].list[0],
                description: this.getProjectInfo.headerText,
                link: "https://bw.com.kz/projects/" + info.routeName + "/",
                image: "https://bw.com.kz/projects/" + info.routeName + ".jpg",
            });
        },
    },
    methods: {
        ...mapMutations([
            "setActiveProjectRoutename",
            "resetProjectInfo",

            "setMetaInfoByParams",
        ]),

        ...mapActions(["fetchProjects", "fetchProjectDetail"]),
    },
};
</script>

<style lang="scss" scoped></style>
