<template>
    <div class="menu" :class="menuStatus?'menu_opened':'menu_closed'"  id="menu">

        <div class="container">
            <div class="row">
                <div class="col-12 menu__navwrap">
                    
                    <nav class="menu__nav">
                        
                        <router-link v-for="(item,index) in getMenuItems" :to="item.path" :key='index'>
                            <div class="menu__nav-item"
                                :class="menuItemClass(item)" 
                                >
                                <p class="menu__nav-item-text" v-html="menuItemText(item)"></p>
                            </div>
                        </router-link>

                    </nav>
                </div>
            </div>
        </div>
        

    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'
    import {
            TweenMax,
            TimelineMax,
            Power3,
        } from 'gsap'

    let menuTl = new TimelineMax()

    export default {
        name:'AppMenu',
        computed:{
            ...mapGetters([
                'menuStatus',
                'getMenuItems',
                'getVacanciesCount',
            ]),
        },
        mounted(){
            TweenMax.set('#menu',{
                yPercent:'-=100'
            })
        },
        watch:{
            menuStatus:(val)=>{

                if(val){
                    menuTl.to('#menu',.75,{
                        yPercent:'+=100',
                        ease:Power3.easeInOut
                    })
                } else{
                    menuTl.to('#menu',.75,{
                        yPercent:'-=100',
                        ease:Power3.easeInOut
                    })
                }
            }
        },
        methods:{
            ...mapMutations([
                'toggleMenu'
            ]),

            menuItemClass(item){
                let classList = []

                if(item.isactive){
                    classList.push('menu__nav-item--active')
                }

                if(item.path=='/specialprojects/svyaz-vremen'){
                    classList.push('menu__nav-item_special')
                }

                if(item.path=='/vacancies/'){
                    classList.push('menu__nav-item_vacancies')
                }

                return classList
            },

            menuItemText(item){
                if(item.path=='/vacancies/'){
                    return item.title+'<span>'+this.getVacanciesCount+'</span>'
                }else {
                    return item.title
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>