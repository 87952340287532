<template>
    <header
        class="header"
        :class="menuStatus ? 'header--menuOpened' : ''"
        id="header"
    >
        <div class="header__burgerwrap">
            <button
                class="hamburger hamburger--spin"
                :class="menuStatus ? 'is-active' : ''"
                type="button"
                @click="toggleMenuStatus"
            >
                <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                </span>
            </button>

            <app-theme-switcher v-if="getHeaderInfo.theme" />

            <div class="header__menu">
                <router-link
                    :to="item.route"
                    v-for="(item, index) in getHeaderMenu"
                    :key="index"
                >
                    <div class="header__menu-item" :class="item.class">
                        <p
                            class="header__menu-item-text"
                            v-html="$t(item.textLink)"
                        ></p>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="header__right">
            <app-lang-switcher v-if="getHeaderInfo.lang" />
            <!-- @clickHandler="orderBtnHandler" 
            
                v-if="getHeaderInfo.order"-->
            <header-order-btn :text="$t('messages.header.contacts')" />
            <!--  -->
            <div class="header__mobiles">
                <a href="tel:+77782341295" class="header__mobiles-text">
                    +7 778 234 12 95
                </a>
            </div>
            <div class="header__mobile-contacts">
                <div class="header__mobile-contacts-iconwrap">
                    <a href="tel:+77782341295">
                        <img
                            src="@/assets/img/icons/contacts-icon.svg"
                            alt=""
                            class="header__mobile-contacts-icon"
                        />
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import AppThemeSwitcher from "./AppThemeSwitcher";
import AppLangSwitcher from "./AppLangSwitcher";
import HeaderOrderBtn from "../common/HeaderOrderBtn";

import { TimelineMax, Power3 } from "gsap";

let headerTl = new TimelineMax();

export default {
    name: "AppHeader",
    components: {
        AppThemeSwitcher,
        AppLangSwitcher,
        HeaderOrderBtn,
    },
    computed: {
        ...mapGetters([
            "menuStatus",
            "getScrolling",
            "getHeaderInfo",
            "getAppTheme",
            "getHeaderMenu",

            "getIsMain",
        ]),
    },
    mounted() {
        headerTl.from("#header", 0.6, {
            yPercent: "-=100",
            ease: Power3.easeInOut,
        });
    },
    methods: {
        ...mapMutations(["toggleMenu"]),

        toggleMenuStatus() {
            this.toggleMenu();
            if (this.menuStatus) {
                document.querySelector("body").classList.add("app--unscroll");
            } else {
                document
                    .querySelector("body")
                    .classList.remove("app--unscroll");
            }
        },

        openPage(path) {
            this.$router.push(path);
        },

        orderBtnHandler() {
            this.openPage("/contacts/");
        },
    },
};
</script>

<style lang="scss" scoped></style>
