<template>
    <div>
        <div  class="servcard" :class="isactive?'servcard_active':''" @click="clickHandler(serviceIndex)">
            <p class="servcard__text" v-html="data.position"></p>
        </div>
        <transition>
            <div class="servcard__des" v-if="isactive">
                <p class="servcard__des-text" v-html="data.des"></p>
                <p class="servcard__des-more" @click="moreClickHandler(data.position, serviceIndex)" v-html="$t('messages.services.details')"></p>
            </div>
        </transition>
    </div> 
</template>

<script>
    export default {
        name:'ServicesCard',
        props:{
            data:{
                type:Object,
                required:false,
                default:()=>{},
            },
            isactive:{
                type:Boolean,
                required:false,
                default:false,
            },
            serviceIndex:{
                type:Number,
                default:0,
                required:false,
            }
        },
        methods:{
            clickHandler(index){
                this.$emit('changeService',index)
            },

            moreClickHandler(tag, index){
                this.$emit('mobileOpenMore', tag, index)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>