<template>
    <div class="mainpage">
        <showreel-block
            :data="getMainpageData.showreel"
            @clickHandler="openModal"
        />

        <projects-block
            :data="getMainpageData.projects"
            :ismobile="getIsMobile"
        />
        <clients-block :light="getAppTheme" :data="getMainpageData.clients" />

        <div class="mainpage__preview" id="filmmain">
            <div class="mainpage__preview-des">
                <div
                    class="mainpage__preview-btn"
                    @click="openModal(getMainpageData.film)"
                >
                    <img
                        src="@/assets/img/pages/mainpage/mplay.svg"
                        alt=""
                        class="mainpage__preview-btn-play"
                    />

                    <div class="mainpage__preview-btn-des">
                        <img
                            src="@/assets/img/pages/mainpage/mlogo.svg"
                            alt=""
                            class="mainpage__preview-btn-logo"
                        />
                        <p
                            class="mainpage__preview-btn-text"
                            v-html="$t('messages.mainpage.about1')"
                        ></p>
                    </div>
                </div>
            </div>
            <video
                playsinline
                webkit-playsinline
                preload="metadata"
                muted
                loop
                autoplay
                class="mainpage__preview-video"
                poster="@/assets/img/pages/mainpage/movie.jpg"
                v-if="initVideo"
            >
                <source
                    src="@/assets/video/special/back-2.mp4"
                    type="video/mp4"
                />
            </video>
        </div>

        <contacts-block @onformsend="sendContactsForm" />

        <report-block :light="getAppTheme" :data="getMainpageData.reports" />

        <transition name="fade">
            <div class="mainpage__form" v-if="initMap">
                <contacts-form />
            </div>
            <div v-else class="mainpage__lazy"></div>
        </transition>

        <div class="mainpage__modal">
            <vimeomodal
                :opened="modalOpened"
                :video="modalVideo"
                @close="closeModal"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import ShowreelBlock from "./components/ShowreelBlock";
import ProjectsBlock from "./components/ProjectsBlock";
import ClientsBlock from "./components/ClientsBlock";
import ContactsBlock from "./components/ContactsBlock";
import ReportBlock from "./components/ReportBlock";

import ContactsForm from "../contacts/components/ContactsForm";

import * as ScrollMagic from "scrollmagic";
import { TimelineMax } from "gsap";

var controller = new ScrollMagic.Controller();

import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TimelineMax);

export default {
    name: "MainIndex",
    components: {
        ShowreelBlock,
        ProjectsBlock,
        ClientsBlock,
        ContactsBlock,
        ReportBlock,
        ContactsForm,
    },
    computed: {
        ...mapGetters([
            "getMainpageData",
            "getSiteLoader",

            "getCurrentLanguage",
            "getIsMobile",
            "getAppTheme",
        ]),
    },
    created() {
        this.fetchMainpage();
    },
    mounted() {
        this.setMetaInfo({
            page: "mainpage",
        });
    },
    watch: {
        getCurrentLanguage: function () {
            this.fetchMainpage();
        },
        getSiteLoader: function (val) {
            if (!val) {
                setTimeout(() => {
                    this.setShowreelAnimation();
                    this.setClientsAnimation();
                    this.setProjectsAnimation();
                    this.setFilmAnimation();
                    this.setContactsAnimation();
                    this.setReportAnimation();
                }, 1000);
            }
        },
    },
    data() {
        return {
            modalOpened: false,
            modalVideo: "",

            initVideo: false,
            initMap: false,
        };
    },
    methods: {
        ...mapActions(["fetchMainpage", "sendContactsMainpage"]),

        ...mapMutations(["setMetaInfo"]),

        sendContactsForm(data) {
            Object.assign(data, {pageUrl: "/"});
            Object.assign(data, {page: "Главная страница"});
            this.sendContactsMainpage(data);
        },

        openModal(id) {
            this.modalVideo = id;
            this.modalOpened = true;
        },

        closeModal() {
            this.modalOpened = false;
            this.modalVideo = "";
        },

        setShowreelAnimation() {
            let timeline = new TimelineMax();

            let widthToTranslate = document.querySelector(
                ".showreelblock__logo"
            ).clientWidth;

            let isTablet = window.innerWidth < 800;

            if (isTablet) {
                timeline
                    .from(".showreelblock__logowrap", 0.6, {
                        x: "-=100",
                    })
                    .from(
                        ".showreelblock__main-video",
                        0.6,
                        {
                            xPercent: "+=100",
                        },
                        "-=.2"
                    )
                    .from(".showreelblock__btn", 0.6, {
                        alpha: 0,
                    })
                    .from(
                        ".showreelblock__mobile-text",
                        0.6,
                        {
                            xPercent: "-=100",
                        },
                        "-=.4"
                    )
                    .from(
                        ".showreelblock__mobile-callback",
                        0.6,
                        {
                            xPercent: "-=100",
                        },
                        "-=.4"
                    );
            } else {
                timeline
                    .from(".showreelblock__logowrap", 0.6, {
                        x: "-=" + widthToTranslate,
                    })
                    .from(
                        ".showreelblock__text",
                        0.6,
                        {
                            x: "-=" + widthToTranslate,
                        },
                        "-=.4"
                    )
                    .from(
                        ".showreelblock__callback",
                        0.6,
                        {
                            x: "-=" + widthToTranslate,
                        },
                        "-=.4"
                    )
                    .from(
                        ".showreelblock__main-video",
                        0.6,
                        {
                            xPercent: "-=100",
                        },
                        "-=.2"
                    )
                    .from(".showreelblock__btn", 0.6, {
                        alpha: 0,
                    });
            }

            new ScrollMagic.Scene({
                triggerElement: "#showreelmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .setTween(timeline)
                .addTo(controller);
        },

        setProjectsAnimation() {
            let timeline = new TimelineMax();

            timeline
                .from(".projectsblock__title", 0.4, {
                    yPercent: "+=100",
                })
                .from(".projectsblock__link", 0.4, {
                    yPercent: "+=100",
                })
                .from(".projectsblock__videowrap", 0.4, {
                    alpha: 0,
                });

            new ScrollMagic.Scene({
                triggerElement: "#projectsmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .setTween(timeline)
                .addTo(controller);
        },

        setClientsAnimation() {
            let timeline = new TimelineMax();

            timeline
                .from(".clientsblock__title", 0.4, {
                    yPercent: "+=100",
                })
                .from(".clientsblock__link", 0.4, {
                    yPercent: "+=105",
                })
                .staggerFrom(
                    ".clientsblock__row-item",
                    0.4,
                    {
                        yPercent: "+=100",
                    },
                    0.1
                );

            new ScrollMagic.Scene({
                triggerElement: "#clientsmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .on(
                    "start",
                    function () {
                        this.initVideo = true;
                    }.bind(this)
                )
                .setTween(timeline)
                .addTo(controller);
        },

        setFilmAnimation() {
            let timeline = new TimelineMax();

            timeline
                .from(".mainpage__preview-btn", 0.8, {
                    delay: 0.8,
                    alpha: 0,
                })
                .from(
                    ".mainpage__preview-video",
                    0.8,
                    {
                        alpha: 0,
                    },
                    "-=.4"
                );

            new ScrollMagic.Scene({
                triggerElement: "#filmmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .setTween(timeline)
                .addTo(controller);
        },

        setContactsAnimation() {
            let timeline = new TimelineMax();

            timeline
                .staggerFrom(
                    ".contactsblock__title",
                    0.4,
                    {
                        yPercent: "+=100",
                        alpha: 0,
                    },
                    0.2
                )
                .staggerFrom(
                    ".contactsblock__form",
                    0.4,
                    {
                        yPercent: "+=100",
                        alpha: 0,
                    },
                    0.1
                )
                .from(".contactsblock__submit", 0.4, {
                    yPercent: "+=100",
                    alpha: 0,
                })
                .from(".contactsblock__tip", 0.4, {
                    alpha: 0,
                });

            new ScrollMagic.Scene({
                triggerElement: "#contactsmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .on(
                    "start",
                    function () {
                        this.initMap = true;
                    }.bind(this)
                )
                .setTween(timeline)
                .addTo(controller);
        },

        setReportAnimation() {
            let timeline = new TimelineMax();

            timeline
                .from(".reportblock__title", 0.4, {
                    yPercent: "+=100",
                })
                .staggerFrom(
                    ".reportblock__item",
                    0.4,
                    {
                        yPercent: "+=20",
                        alpha: 0,
                    },
                    0.2
                );

            new ScrollMagic.Scene({
                triggerElement: "#reportmain",
                triggerHook: 0.6,
                reverse: false,
            })
                .on(
                    "start",
                    function () {
                        let reportCounters = document.querySelectorAll(
                            ".reportblock__item-count"
                        );

                        reportCounters.forEach((item) => {
                            this.setCountAnimation(item);
                        });
                    }.bind(this)
                )
                .setTween(timeline)
                .addTo(controller);
        },

        setCountAnimation(element) {
            let startCount = 0;
            let count = element.innerHTML;

            element.innerHTML = 0;

            let counterInterval = setInterval(() => {
                let step =
                    count > 1000
                        ? Math.floor(Math.random() * 150)
                        : Math.floor(Math.random() * 25);
                if (startCount > count - step) {
                    element.innerHTML = count;
                    clearInterval(counterInterval);
                } else {
                    startCount += step;
                    element.innerHTML = startCount;
                }
            }, 50);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>