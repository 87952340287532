import metaConstants from "../../helpers/constants/metaConstants";

const moduleMeta = {
	state: {
		title: "",
		metaArray: [],
		link: [],
	},
	mutations: {
		// Данная функция просто берет параметром названия страницы
		// и по данному ключу вытаскивает данные из metaConstants
		setMetaInfo(state, payload) {
			let { page } = payload;

			state.title = metaConstants[page].title;
			state.metaArray = metaConstants[page].metaArray;
			state.link = metaConstants[page].link;
		},

		// Данная мутация сделана для данных, которых нет в metaConstants
		// Вводятся как аргумент определенные переменные и зависимости от них уже прописывается мета
		setMetaInfoByParams(state, payload) {
			console.log("params", payload);
			let {
				title = "Black&White - профессиональное производство видеороликов и фильмов",
				type = undefined,
				description = "Профессиональное производство видеороликов и фильмов. Компания начала свою работу в 2013 году и за это время успешно реализовала более 200 видеопроектов. Это документальные и корпоративные фильмы, имиджевые, социальные инфографические и анимационные видеоролики.",
				image = "https://bw.com.kz/social.jpg",
				link = "'https://bw.com.kz'",
			} = payload;

			// Если есть информация о типах, то добавляет его в заголовок
			let metaTitle = title + (type ? " | " + type : "");

			state.title = metaTitle;
			state.metaArray = [
				{ name: "application-name", content: metaTitle },
				{ id: "desc", name: "description", content: description },

				{ property: "og:title", content: metaTitle },
				{ property: "og:description", content: description },
				{ property: "og:image", content: image },
				{ property: "og:url", content: "https://bw.com.kz" },
				{ property: "og:site_name", content: "https://bw.com.kz" },

				{ itemprop: "name", content: metaTitle },
				{ itemprop: "description", content: description },
				{ itemprop: "image", content: image },

				{ name: "twitter:card", content: "summary_large_image" },
				{ name: "twitter:title", content: metaTitle },
				{ name: "twitter:description", content: description },
				{ name: "twitter:image:src", content: image },
				{ name: "twitter:image:alt", content: metaTitle },

				{ name: "fragment", content: "!" },
			];
			state.link = [{ rel: "canonical", href: link }];
		},
	},
	actions: {},
	getters: {
		getMetaTitle(state) {
			return state.title;
		},

		getMetaArray(state) {
			return state.metaArray;
		},

		getMetaLink(state) {
			return state.link;
		},
	},
};

export default moduleMeta;
