<template>
  <div class="psimilar">
    <div class="psimilars-slider swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in elements"
          :key="index"
          @click="openPage(item.code)"
        >
          <div class="psimilar__item">
            <img :src="item.src" :alt="item.alt" class="psimilar__item-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";

export default {
  name: "ProjectSimilar",
  props: {
    elements: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mounted() {
    new Swiper(".psimilars-slider", {
      loop: true,
      spaceBetween: 30,
      slidesPerView: 4,
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        600: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1000: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        1700: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
      },
    });
  },
  methods: {
    openPage(path) {
      this.$router.push({ path: "/projects/" + path + "/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  max-width: 400px;
}
.swiper-wrapper,
.swiper-slide {
  -moz-transform-style: flat;
  -webkit-transform-style: flat;
  -ms-transform-style: flat;
  transform-style: flat;
}
</style>
