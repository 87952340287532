<template>
  <div class="smodal">
    <div class="smodal__block">
      <div class="smodal__title">
        <p class="smodal__title-text">о проекте</p>
      </div>

      <p class="smodal__text">
        Видеролик был снят в июне 2020 года в Алматы. В съемках принимали
        участие более 100 человек, включая каскадеров и актеров массовых сцен.
      </p>
      <p class="smodal__text">
        Замысел был реализован благодаря творческому союзу компании Black &
        White и лучших специалистов киноиндустрии Казахстана, в числе которых
        художник-постановщик Куат Тлеубаев, режиссер Ален Ниязбеков и другие
        профессионалы своего дела.
      </p>
      <p class="smodal__text">
        Видео широко транслировалось на республиканских телеканалах в июне 2020
        года.
      </p>
    </div>

    <div class="smodal__block smodal__block_nopadxs">
      <div class="smodal__list">
        <div
          v-for="(item, index) in testData"
          :key="index"
          class="smodal__info"
          :style="{
            backgroundImage: 'url(' + item.image + ')',
          }"
        >
          <p class="smodal__info-text" v-html="item.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecialAbout",
  data() {
    return {
      testData: [
        {
          image: require("@/assets/img/pages/special/about/sabout-1.jpg"),
          text: "<span>2 </span>съемочных дня ",
        },
        {
          image: require("@/assets/img/pages/special/about/sabout-2.jpg"),
          text: "<span>3</span>дня на застройку <br> семи локаций ",
        },
        {
          image: require("@/assets/img/pages/special/about/sabout-3.jpg"),
          text: "<span>1800</span> кв м.<br>  размер павильона",
        },
        {
          image: require("@/assets/img/pages/special/about/sabout-4.jpg"),
          text: "более <span>100</span><br> человек в команде, из которых 61 актеры ",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>