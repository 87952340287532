import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

//vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// дополнительные модули
import router from "./router";
import store from "./store";

// Для использования разных языковых версий
import i18n from "./localization/index";

// Метрики
import VueGtag from "vue-gtag";
import VueYandexMetrika from "vue-yandex-metrika";

// Конфигурация модулей для аналитики
Vue.use(VueYandexMetrika, {
	id: 58295551,
	router: router,
	env: process.env.NODE_ENV,
});

Vue.use(
	VueGtag,
	{
		config: { id: "UA-136796290-1" },
		appName: "BWG",
	},
	router
);

// TODO: настроить Facebook Pixel

// Модуль для мета отображения
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
	keyName: "metaInfo",
	attribute: "data-vue-meta",
	ssrAttribute: "data-vue-meta-server-rendered",
	tagIDKeyName: "vmid",
	refreshOnceOnNavigation: true,
});

// scroll in casting

var VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);

// маска для форм
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

// Vue cookie use
var VueCookie = require("vue-cookie");
Vue.use(VueCookie);

// // Модуль для постраниченого скролла     // PageSpeed
import VueFullPage from "vue-fullpage.js";
Vue.use(VueFullPage);

// Global components
import AppBtn from "@/components/ui/AppBtn";
import VimeoModal from "@/components/common/VimeoModal";
import AppAddFile from "@/components/ui/AppAddFile";

Vue.component("app-btn", AppBtn);
Vue.component("vimeomodal", VimeoModal);

Vue.component("upload-file", AppAddFile);

import VueSlider from "vue-slider-component"; // PageSpeed
Vue.component("range-slider", VueSlider);

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

new Vue({
	render: (h) => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
	router,
	store,
	i18n,
}).$mount("#app");
