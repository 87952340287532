<template>
    <div class="langswitcher">
        <div class="langswitcher__item" @click="toggleLang">
            <p class="langswitcher__item-text" v-html="getLanguageToChange"></p>
        </div>
    </div>
</template>

<script>
    import {mapGetters, mapMutations} from 'vuex'

    export default {
        name:'AppLangSwitcher',
        computed:{
            ...mapGetters([
                'getLanguageToChange',
                'getCurrentLanguage',
                'getCurrentLanguageKey',
            ])
        },
        data(){
            return{
                
            }
        },
        methods:{
            ...mapMutations([
                'setSelectedLang',
                'setSiteLoader'
            ]),

            toggleLang(){
                this.setSiteLoader(true)

                if(this.getCurrentLanguageKey=='ru'){
                    this.$i18n.locale = 'en' 
                    this.setSelectedLang('en')
                } else{
                    this.$i18n.locale = 'ru' 
                    this.setSelectedLang('рус')
                }

                setTimeout(()=>{
                    this.setSiteLoader(false)
                }, 1000)
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>