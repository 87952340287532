<template>
	<div class="sdetail">
		
		<div class="sdetail__header">

            <video playsinline
                    webkit-playsinline
                    preload="metadata" 
                    muted
                    loop 
                    autoplay
                    class="sdetail__back"
                    poster="@/assets/img/pages/special/main.jpg"
                    >
                <source src="@/assets/video/special/back.mp4" type="video/mp4"/>
            </video>

			<div class="sdetail__anim">
				<img src="@/assets/img/pages/special/round-sm.svg" alt="" class="sdetail__round sdetail__round_sm">
				<img src="@/assets/img/pages/special/round-sm.svg" alt="" class="sdetail__round sdetail__round_sm-2">
				<img src="@/assets/img/pages/special/round-md.svg" alt="" class="sdetail__round sdetail__round_md">
				<img src="@/assets/img/pages/special/round-l.svg" alt="" class="sdetail__round sdetail__round_l">
				<img src="@/assets/img/pages/special/round-xl.svg" alt="" class="sdetail__round sdetail__round_xl">
				<div class="sdetail__play" @click="openModal(4)">
					<img src="@/assets/img/pages/special/play.svg" alt="" class="sdetail__play-icon">
					<p class="sdetail__play-text">Посмотреть видео</p>
				</div>
			</div>

			<div class="container">
				<div class="row">
					<div class="col-5 col-xs-12">

						<div class="sdetail__title">
							<img src="@/assets/img/pages/special/svyaz.svg" alt="" class="sdetail__title-image sdetail__title-image_1">
							<img src="@/assets/img/pages/special/time.svg" alt="" class="sdetail__title-image sdetail__title-image_2">
						</div>

						<div class="sdetail__subtitle">
							<div class="sdetail__subtitle-text">
								Видеоролик «Связь времён» показывает ключевые моменты 30-летия Независимости Республики Казахстан. Прошлое, настоящее и будущее неразрывно связаны одной нитью. Это истории побед и потерь, личные трагедии и тысячи уникальных судеб казахстанцев.
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>

		<div class="sdetail__nav">
			<div class="container">
				<div class="row">
					<div class="col-12">

						<div class="sdetail__nav-list">
							
							<div class="sdetail__nav-item" @click="openModal(1)">
								<p class="sdetail__nav-item-text">о проекте</p>
							</div>

							<div class="sdetail__nav-item" @click="openModal(2)">
								<p class="sdetail__nav-item-text">подробнее</p>
							</div>

							<div class="sdetail__nav-item" @click="openModal(3)">
								<p class="sdetail__nav-item-text">команда проекта</p>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="sdetail__review">

			<div class="container">
				<div class="row">
					<div class="col-12">

						<div class="sdetail__blocktitle">
							<p class="sdetail__blocktitle-text">Отзывы</p>
						</div>

						<div class="sdetail__block">
							<comment-form/>
						</div>

						<div 
							class="sdetail__block"
							:class="index==getSpecialProjectComments.length-1?'sdetail__block_noborder':''" 
							v-for="(comment,index) in getSpecialProjectComments" 
							:key="index"
							>
							<comment-view :data='comment'/>
						</div>

						<div class="sdetail__more" v-if="getSpecialProjectComments.length>3">
							<p class="sdetail__more-text">Смотреть другие комментарии</p>
						</div>

					</div>
				</div>
			</div>

		</div>

		<div class="sdetail__same">

			<div class="container container--overvisible">
				<div class="row">
					<div class="col-12">

						<div class="sdetail__blocktitle sdetail__blocktitle_color_accent">
							<p class="sdetail__blocktitle-text">аналогичные проекты</p>
						</div>

                        <div class="sdetail__same-list">
                            <project-similar :elements="similarProjects" />
                        </div>

					</div>
				</div>
			</div>

		</div>

		<transition name="fade" mode="out-in">
			<div class="sdetail__modal" :class="modalID==4?'sdetail_mode_video':''" v-if="modalOpened">
				<div class="sdetail__modal-overlay" @click="closeModal"></div>

				<div class="sdetail__modal-content">

					<img v-if="modalID==4" src="@/assets/img/icons/close.svg" @click="closeModal" alt="" class="sdetail__modal-close">
					<img v-else src="@/assets/img/icons/close-dark.svg" @click="closeModal" alt="" class="sdetail__modal-close">

                    <transition name="fade">
                        <special-about v-if="modalID==1"></special-about>
                        <special-more  v-else-if="modalID==2"></special-more>
                        <special-team  v-else-if="modalID==3"></special-team>
                        <div class="sdetail__video" v-else-if="modalID==4">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="sdetail__videowrap">
                                            <div style="padding:56.25% 0 0 0;position:relative;width:100%;height:auto">
                                                <iframe :src="'https://player.vimeo.com/video/436674660?title=0&byline=0&portrait=0'" 
                                                style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                                frameborder="0" 
                                                allow="autoplay; fullscreen" 
                                                allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
							
				</div>
			</div>
		</transition>


	</div>
</template>

<script>
	import CommentForm from './components/CommentForm';
	import CommentView from './components/CommentView';

	import SpecialAbout from './modals/SpecialAbout'
	import SpecialMore from './modals/SpecialMore'
    import SpecialTeam from './modals/SpecialTeam'
    
    import Swiper from 'swiper';
    import 'swiper/swiper-bundle.css';
    
    import ProjectSimilar from "../projects/components/ProjectSimilar"

	import {
		mapGetters,
        mapActions,
        mapMutations,
	} from 'vuex'

	import {
		TweenMax,
		Linear,
	} from 'gsap'

	export default {
		name:'SpecialDetail',
		components:{
			CommentForm,
			CommentView,

			SpecialAbout,
			SpecialMore,
            SpecialTeam,
            
            ProjectSimilar,
		},
		computed:{
			...mapGetters([
				'getSpecialProjectComments',
			])
		},
		watch:{
			modalOpened:function(val){
				if(val){
					document.querySelector('body').classList.add('app--unscroll')
				} else {
					document.querySelector('body').classList.remove('app--unscroll')
				}
			}
		},
		mounted(){
            this.switchOffIsmain(true)

			document.querySelector('.header').classList.add('header--absolute')
			document.querySelector('.langswitcher').classList.add('notvisible')

            this.fetchComments('route')
            
             this.setMetaInfoByParams({
                title:'Связь времен',
                type:'Социальный видеоролик',
                description:'Видеоролик «Связь времён» показывает ключевые моменты 30-летия Независимости Республики Казахстан. Прошлое, настоящее и будущее неразрывно связаны одной нитью. Это истории побед и потерь, личные трагедии и тысячи уникальных судеб казахстанцев.',
                link:'https://bw.com.kz/projects/specialprojects/svyaz-vremen',
                image:'https://api.blackandwhite.kz/upload/resize_cache/iblock/363/706_401_2/363ead9ade896d173be78e0d1f673795.jpg'
            })

            new Swiper('.swiper-container', {
                loop: true,
                spaceBetween: 30,
                slidesPerView:3,
            });

			// this.setHeaderAnimation()
		},
		beforeDestroy(){
			document.querySelector('.header').classList.remove('header--absolute')
			document.querySelector('body').classList.remove('app--unscroll')
			document.querySelector('.langswitcher').classList.remove('notvisible')

		},
		data(){
			return {
				modalOpened:false,
                modalID:0,
                similarProjects:[
                    {
                        "id": "40",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/6d3/419_236_2/6d3fafa4e29eeab329c33fb96eef68c1.jpg",
                        "name": "Давайте помнить о победах чаще!",
                        "code": "davayte-pomnit-o-pobedakh-chashche"
                    },
                    {
                        "id": "42",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/a81/419_236_2/a81ff8ff20d45df9a3a09fe2b4d011f7.jpg",
                        "name": "Армандастар",
                        "code": "armandastar"
                    },
                    {
                        "id": "77",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/643/419_236_2/6431557eecc1258268857c8c1c417303.jpg",
                        "name": "Социальный эксперимент ERG",
                        "code": "sotsialnyy-eksperiment-erg"
                    },
                    {
                        "id": "81",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/fac/419_236_2/fac9767894f05ab0396aa798ebc377ef.jpg",
                        "name": "Приходи на выборы!",
                        "code": "idi-na-vybory"
                    },
                    {
                        "id": "83",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/103/419_236_2/103a20ccda2f7002e9c3abe79115812d.jpg",
                        "name": "Больше, чем коллеги, больше, чем семья",
                        "code": "bolshe-chem-kollegi-bolshe-chem-semya"
                    },
                    {
                        "id": "86",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/293/419_236_2/293221f1437aae1bfbce0a204ae7a36e.jpg",
                        "name": "Маленькими шагами к большой цели",
                        "code": "malenkimi-shagami-k-bolshoy-tseli"
                    },
                    {
                        "id": "87",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/366/419_236_2/36695373e5ec66dd2306244b690d2069.jpg",
                        "name": "Меня зовут Ардак",
                        "code": "menya-zovut-ardak"
                    },
                    {
                        "id": "467",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/b02/419_236_2/b0295ed63a030678c1d29254e9f115fa.jpg",
                        "name": "Социальный ролик «Отан Ана»",
                        "code": "rodina-mat"
                    },
                    {
                        "id": "468",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/02f/419_236_2/02f74c932afd48899d983693638120c9.jpg",
                        "name": "Социальный ролик «Взрослый мир глазами ребенка»",
                        "code": "videorolik-glazami-detey"
                    },
                    {
                        "id": "483",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/e30/419_236_2/e30381426e95fd6abd0b4238cc209b69.jpg",
                        "name": "Новая реальность. «Самоизолироваться #НеСтыдно»",
                        "code": "rolik-1-na-kazakhskom-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey-"
                    },
                    {
                        "id": "484",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/365/419_236_2/365fb7de41e8fc8850a04b5430a64554.jpg",
                        "name": "Новая реальность. «Носить маску #НеСтыдно»",
                        "code": "rolik-2-na-kazakhskom-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "485",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/b8f/419_236_2/b8f0ceddcff647612df49d35391b2fe9.jpg",
                        "name": "Новая реальность. «Соблюдать правлила #НеСтыдно»",
                        "code": "rolik-3-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "486",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/a6f/419_236_2/a6f863b30411df1f3a956234e95c0bb8.jpg",
                        "name": "Новая реальность. «Дистанцироваться #НеСтыдно»",
                        "code": "rolik-4-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "487",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/f5a/419_236_2/f5ae9147ad81d6c078e543a959201d48.jpg",
                        "name": "Стоп VIRUS",
                        "code": "rolik-ekspleyner-stopvirus"
                    },
                    {
                        "id": "488",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/f31/419_236_2/f31bd3a34867903eb699bf808cc9a1f4.jpg",
                        "name": "Казатомпром. Наши истории. Акмарал Сарыбаева",
                        "code": "videoroliki-o-sotrudnikakh-kap-akmaral-"
                    },
                    {
                        "id": "489",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/62b/419_236_2/62baed26ee4d3f22eeb49d67f04cc5c0.jpg",
                        "name": "Казатомпром. Наши истории. Ринат Байсултанов",
                        "code": "videoroliki-o-sotrudnikakh-kap-2"
                    },
                    {
                        "id": "490",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/848/419_236_2/848a8c1451331c2af7361da4cbef256f.jpg",
                        "name": "Казатомпром. Наши истории. Жаксылык Тажибаев",
                        "code": "videoroliki-o-sotrudnikakh-kap-3"
                    },
                    {}
                ]

			}
		},
		methods:{
			...mapActions([
				'fetchComments',
            ]),
            

            ...mapMutations([
                'switchOffIsmain',
                'setMetaInfoByParams',
            ]),

			openModal(id){
				this.modalOpened=true
				this.modalID=id
			},

			closeModal(){
				this.modalOpened=false
				this.modalID=0
			},

			setHeaderAnimation(){
				let round1 = document.querySelector('.sdetail__round_sm')
				let round2 = document.querySelector('.sdetail__round_md')
				let round3 = document.querySelector('.sdetail__round_l')
				let round4 = document.querySelector('.sdetail__round_xl')

				TweenMax.to(round1,10,{
					rotation:'360deg',
					reapeatDelay:1,
					repeat:-1,
					ease:Linear.easeNone,
				})

				TweenMax.to(round2,20,{
					rotation:'-360deg',
					reapeatDelay:1,
					repeat:-1,
					ease:Linear.easeNone,
				})

				TweenMax.to(round3,30,{
					rotation:'360deg',
					reapeatDelay:1,
					repeat:-1,
					ease:Linear.easeNone,
				})

				TweenMax.to(round4,40,{
					rotation:'-360deg',
					reapeatDelay:1,
					repeat:-1,
					ease:Linear.easeNone,
				})

			}
		}
	}
</script>

<style lang="scss" scoped>

</style>