<template>
  <div class="seo">

    <section class="black">
      <div class="container">
        <div class="seo_about seo_section">
          <div class="container_mini">
            <div class="app__titlewrap">
              <h1 class="app__title">Социальная реклама</h1>
            </div>
            <div class="seo_text">
              Компания Black & White известна в Казахстане и за его пределами, в
              первую очередь, своими социальными проектами. В их числе как отдельные социальные видеоролики, так и масштабные благотворительные кампании. 
              Поэтому, если Вы задумались о создании социального видеоролика или организации социального эксперимента, то Вам точно к нам.
            </div>
            <div class="seo_text">
              У команды Black & White большой опыт реализации подобных проектов,
              которые высоко оценены профессиональным сообществом.
            </div>
          </div>

          <div class="seo_about_slider">
            <div class="swiper-wrapper">
              <div class="swiper-slide shadow" v-for="(item,index) in sliderImages" :key="index" >                    
                <div class="seo_about_slider_item">
                  <img :src="item.src" alt="">
                  <div class="container_mini">
                    <div class="seo_about_slider_content">
                      <div class="seo_about_slider_number">{{ index + 1 }}/{{ sliderImages.length }}</div>
                      <div class="seo_about_slider_title">
                        {{ item.title }}
                      </div>
                      <div class="seo_text">
                        {{ item.text }}
                      </div>
                      <a :href="item.link" target="_black" class="seo_button">
                        Узнать больше
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>

        <div class="seo_benefits seo_section">
          <div class="container_mini">
            <h2 class="seo_title">Особенности формата</h2>
          </div>
          <div class="seo_benefits_tabs">
            <div class="seo_benefits_tabs_menu">
              <div class="seo_benefits_tabs_menu_item"
                v-for="(item, index) in tabsMenu" 
                :key="index" 
                v-html="item.tabsMenuItem"
                @click="selectService(index)"
                :class="index==selectedService?'active':''"
              ></div>
            </div>
            <div class="seo_benefits_tabs_content">
              <div class="seo_benefits_tabs_item"
                v-for="(item, index) in tabsItem"
                :key="index"
                :class="index==selectedService?'show':'hide'"
              >
                <div 
                  class="seo_text" 
                  v-for="(itemText, index) in item" 
                  :key="index"
                  v-html="itemText"
                ></div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="seo_experience seo_section">
          <div class="container_mini">
            <h2 class="seo_title">Опыт Black & White</h2>
            <div class="seo_text">
              Начиная с 2013 года, компания Black & White снимала серию
              социальных роликов для АО «Самрук-Қазына» (<a class='seo_link' href="/projects/malenkimi-shagami-k-bolshoy-tseli/" target="_blank">«Маленькими шагами к
              большой цели»</a>, <a class='seo_link' href="/projects/bolshe-chem-kollegi-bolshe-chem-semya/" target="_blank">«Трудовые династии»</a>, <a class='seo_link' href="/projects/davayte-pomnit-o-pobedakh-chashche/" target="_blank">«Давайте помнить о победах
              чаще!»</a> и другие). Эти видеоролики широко транслировались на
              казахстанском телевидении и получили признание на
              Центрально-азиатском фестивале коммуникаций Red Jolbors.
            </div>
            <div class="seo_text">
              Социальный ролик «Трудовые династии» взял «серебро», а ролик
              «Давайте помнить о победах чаще!» получил сразу две награды:
              «серебро» в номинации «Социальные ролики» и «бронзу» в номинации
              «FILM Мастерство исполнения». На Киевском международном фестивале
              рекламы социальный ролик о ветеране завоевал «бронзу» за лучший
              сценарий.
            </div>
          </div>
          <div class="seo_experience_videowrap">
            <video
              class="seo_experience_videowrap-video"
              playsinline=""
              muted="muted"
              loop="loop"
              poster="https://api.blackandwhite.kz/upload/resize_cache/iblock/6d3/706_401_2/6d3fafa4e29eeab329c33fb96eef68c1.jpg"
            >
              <source
                src="https://api.blackandwhite.kz/upload/iblock/c3b/c3b07e039fa809b9073b1fb0446b6124.m4v"
                type="video/mp4"
              />
            </video>
            <div class="seo_experience_btn" @click="openModal">
              <img
                src="@/assets/img/pages/mainpage/mplay.svg"
                alt=""
                class="seo_experience_btn-icon"
              />
              <p class="seo_experience_btn-text" v-html="'Смотреть ролик'"></p>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="white">
      <div class="container">
        <div class="seo_projects seo_section">
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/socialpage/social3.jpg" alt="">
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold">
                Армандастар
              </div>
              <div class="seo_text">
                Широко транслировались на казахстанском и зарубежном телевидении 20 социальных видеороликов из серии <a class='seo_link' href="/projects/armandastar/" target="_blank">«Армандастар»</a>. 
                Проект о 20 героях — неравнодушных казахстанцах был создан при поддержке Фонда Первого Президента — Елбасы и Министерства информации и коммуникаций РК.
              </div>
            </div>
          </div>
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/socialpage/social4.jpg" alt="">
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold">
                Ұят емес
              </div>
              <div class="seo_text">
                В период пандемии, вызванной коронавирусной инфекцией COVID-19, вся команда Black & White оперативно снимала жизненно важные на тот момент социальные ролики о культуре поведения.
                Так родилась серии социальных роликов «Нам не всё равно» (<a class='seo_link' href="/projects/nam-ne-vsye-ravno-dedushka/" target="_blank">«Наплевать легко, исправить сложно»</a>), <a class='seo_link' href="/projects/glyantsevyy-rolik-patrioticheskogo-soderzhaniya-v-etom-net-styda-3/" target="_blank">«Ұят емес»</a>
                («В этом нет стыда») и <a class='seo_link' href="/projects/rolik-4-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey/" target="_blank">«Новая реальность»</a>.
              </div>
            </div>
          </div>
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/socialpage/social5.jpg" alt="">
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold">
                Отан ана
              </div>
              <div class="seo_text">
                Социальный ролик <a class='seo_link' href="/projects/rodina-mat/" target="_blank">«Отан Ана»</a> транслировался на государственном телевидении в самое тяжелое время пандемии. В ролике использовались уникальные архивные видеокадры, фото из государственного архива времен Великой Отечественной войны, голодомора и 90-х годов.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="black">
      <div class="container">
        <div class="seo_nominations seo_section">
          <div class="container_mini">
            <div class="seo_text">
              Специальный приз премии «Ак Мерген» в 2021 году получил проект <a class='seo_link' href="/projects/shyn-jurekten/" target="_blank">«Шын Жүректен»</a> («От чистого сердца») в номинации «Социальные коммуникации и благотворительность». Проект был приурочен ко Дню благодарности 1 марта в Казахстане и предоставлял возможность сказать «спасибо» людям, которые этого достойны.
            </div>
          </div>
          <div class="seo_nominations_video">        
            <iframe
              :src="
                'https://player.vimeo.com/video/517966183' +
                '?title=0&byline=0&portrait=0&autoplay=0'
              "
              style="width: 100%; height: 100%"
              frameborder="0"
              allow="autoplay;fullscreen"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <section class="white">
      <div class="container">
        <div class="seo_corporate seo_section">
          <div class="container_mini">
              <h3 class="seo_title">Социальные ролики в корпоративном секторе</h3>
              <div class="seo_text">
                Социальные видеоролики — отличный инструмент коммуникаций в корпоративном секторе. И это не только истории сотрудников, которые вдохновляют коллектив и ведут за собой (серия социальных роликов <a class='seo_link' href="/projects/videoroliki-o-sotrudnikakh-kap-akmaral-/" target="_blank">«Наши истории»</a> о сотрудниках АО «НАК «Казатомпром»), но более масштабные проекты.
              </div>
          </div>
          <div class="seo_corporate_block">
            <div class="seo_corporate_img">
              <img src="@/assets/img/pages/seopages/socialpage/social6.jpg" alt="">
            </div>
            <div class="seo_text">
              Смелым экспериментом стал <a class='seo_link' href="/projects/sotsialnyy-eksperiment-erg/" target="_blank">социальный проект</a> с «Евразийской группой» (ERG), в съемках которого приняли участие 100 сотрудников. Люди совершенно разных профессий собрались в одном зале и до последнего момента не знали, что их ждёт. Однако результат превзошел все ожидания. Сегодня ролик активно используют на внутрикорпоративных встречах и тренингах ERG. В 2020 году социальный эксперимент победил в номинации «Внутрикорпоративные коммуникации» премии в области PR «Ак Мерген».
            </div>
          </div>
          <div class="container_mini">
            <div class="seo_corporate_button">
              <a href="/projects" target='_blank' class="seo_button">
                Еще больше социальных видеороликов и проектов Black & White Вы можете найти в портфолио
                  <svg width="58" height="15" viewBox="0 0 58 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M57.7067 8.34236C58.0972 7.95184 58.0972 7.31867 57.7067 6.92815L51.3427 0.564186C50.9522 0.173662 50.319 0.173662 49.9285 0.564186C49.538 0.95471 49.538 1.58788 49.9285 1.9784L55.5853 7.63525L49.9285 13.2921C49.538 13.6826 49.538 14.3158 49.9285 14.7063C50.319 15.0968 50.9522 15.0968 51.3427 14.7063L57.7067 8.34236ZM0.583984 8.63525H56.9996V6.63525H0.583984V8.63525Z" fill="#ECECEC"/>
                  </svg>
                <div class="seo_corporate_arrow"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="black">
      <div class="container">

        <div class="seo_needs seo_section">
          <div class="container_mini">
            <h3 class="seo_title">Кому необходимы социальные ролики?</h3>
            <div class="seo_text">
              Социальные видеоролики нужны всем, кто задумывается о социальной ответственности, общественной пользе и устойчивом развитии.
            </div>
            <div class="seo_text">
              Универсальность формата социального ролика обусловлена тем, что он фокусирует внимание аудитории на проблемах в обществе, которые касаются каждого. В основе сюжета, как правило, идея с мощной социальной ценностью. 
            </div>
            <div class="seo_text">
              Социальные ролики — это универсальный формат, который подходит, практически, всем: малому, среднему и крупному бизнесу, спортивным, культурным, образовательным и медицинским учреждениям, некоммерческим организациям, государственным органам и квазигосударственному сектору, компаниям в сферах медиа и рекламы.
            </div>
            <div class="seo_text">
              В числе основных заказчиков социальных роликов Black & White — национальные компании и корпоративный сектор (АО Самрук-Казына, АО «НАК «Казатомпром», ERG и др.), телеканалы (Qazaqstan, АО «Хабар»).
            </div>
          </div>
        </div>

        <div class="seo_create seo_section">
          <div class="container_mini">
            <h3 class="seo_title">Как создаются социальные ролики</h3>
            <div class="seo_text">
              Для создания социальных роликов необходим профессиональный подход, фантазия, креатив. Идея должна быть сильной, при этом необходимо демонстрировать решение проблемы, выход из ситуации: социальный ролик должен отвечать на вопрос «Что делать, чтобы решить проблему?».
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">1. Pre-production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in preProduction" :key="index">   
                  <img :src="item.src" alt="">
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                Первым этапом выступает сбор и анализ информации по заявленной проблематике. Правильный анализ социальной проблемы — половина успеха. Не менее важно определиться с портретом целевой аудитории: зрители должны увидеть в ролике себя, свою боль, понять, что тема касается непосредственно их, и они могут решить проблему.
              </div>
              <div class="seo_text">
                Идея, рожденная на основе анализа и креатива, отражается в концепции. Далее — согласование концепции с клиентом. После успешных переговоров и соглашений мы переходим к созданию сценария видеоролика.
              </div>
              <div class="seo_text">
                Следующий этап — раскадровка. Она помогает визуально представить, как режиссер видит картину, каким образом он будет создавать ролик. Это последовательный набор рисунков, которые рассказывают историю, разбивая ее на фрагменты.
              </div>
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">2. Production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in production" :key="index">   
                  <img :src="item.src" alt="">
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                Во время подготовки к съемке проводится кастинг актеров, подбирается площадка и декорации, осуществляется съемка.
              </div>
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">3. Post-production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item,index) in postProduction" :key="index">   
                  <img :src="item.src" alt="">
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                После окончания съемки начинается постпродакшн: специалисты монтируют видео, добавляют звуковые эффекты, осуществляют цветокоррекцию.
              </div>
            </div>
          </div>

        </div>
        
        <div class="seo_section">
          <div class="container_mini">
            <h4 class="seo_title">Зачем Вашей организации социальные видеоролики?</h4>
            <div class="seo_text">
              Цель социального ролика — напомнить о существовании проблем в обществе, привлечь внимание, призвать к действию и обозначить пути решения. Социальные ролики не только помогают решить социальные проблемы, но и улучшают имидж компании, организации.
            </div>
            <div class="seo_text">
              Это способ заявить о миссии компании, об отношении к общественно значимым вопросам. 
              В отличие от стандартной рекламы, социальные проекты ничего не навязывают, они призывают изменить свою точку зрения, взглянуть на проблему под другим углом, поэтому воспринимаются аудиторией максимально положительно.
            </div>
            <div class="seo_text">
              Социальные ролики работают на долгосрочное устойчивое развитие бренда, неслучайно все крупные известные компании инвестируют в социальную рекламу, в общественно полезные проекты.
            </div>
          </div>
        </div>
        
        <div class="seo_price seo_section">
          <div class="container_mini">
            <h4 class="seo_title">Стоимость создания социального ролика</h4>
            <div class="seo_text">
              Стоимость создания социального ролика зависит от нескольких переменных:
            </div>
            <div class="seo_text">
              <ul>
                <li>Сложность съемочного процесса (количество локаций и др.);</li>
                <li>Количество занятых актеров и персонала (чем их больше, тем выше цена);</li>
                <li>Наполненность графикой и визуальными эффектами;</li>
                <li>Хронометраж.</li>
              </ul>
            </div>
            <div class="seo_text">
              Таким образом, стоимость создания социального ролика напрямую зависит от концепции и сценария, поэтому более точную оценку возможно получить после консультации со специалистами Black & White.
            </div>
            <div>
              <a href="/contacts" target="_blank" class="seo_button bold">Узнать</a>
            </div>
          </div>
        </div>
        
        <div class="seo_section">
          <div class="container_mini">
            <h4 class="seo_title">Почему важно заказать социальный ролик у Black & White?</h4>
          </div>
          <div class="seo_privilege">
            <div class="seo_privilege_item">
              <div class="seo_mini_title">1. Опыт</div>
              <div class="seo_text">Компания Black & White известна в Казахстане и за его пределами своими социальными роликами и проектами, многие из которых удостоены <a class='seo_link' href="/awards/" target="_blank">наград</a> профессионального сообщества.
              </div>
            </div>
            <div class="seo_privilege_item">
              <div class="seo_mini_title">2. Качество</div>
              <div class="seo_text">Компания Black & White успешно работает с 2013 года и дорожит своей репутацией. Мы работаем с лучшими специалистами — настоящими профессионалами, многие из работают в кинопроизводстве.
              </div>
            </div>
            <div class="seo_privilege_item">
              <div class="seo_mini_title">3. Креативность</div>
              <div class="seo_text">Мы убеждены, что фундамент хорошего социального ролика — это его идея и сценарий. Наши социальные ролики эффектно и эффективно донесут до целевой аудитории главный посыл, воплотив в жизнь любой замысел.
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>


    <form-block @onformsend="sendContactsForm" />
    <!-- <form-block :url="socialnyj-videorolik" :page="Социальный_видеоролик" @onformsend="sendContactsForm" /> -->
    
    
    <div class="seo_modal">
      <div class="seo_modal-wrap" v-if="modalOpened">
        <iframe
          :src="
            'https://player.vimeo.com/video/396916739' +
            '?title=0&byline=0&portrait=0&autoplay=1'
          "
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          class="seo_modal-video"
          frameborder="0"
          allow="autoplay;fullscreen"
          allowfullscreen
        >
        </iframe>
        <img
          src="@/assets/img/icons/close.svg"
          alt=""
          class="seo_modal-close"
          @click="closeModal"
        />
      </div>
    </div>

  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import Swiper, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import FormBlock from "../components/FormBlock";

Swiper.use([Navigation]);


export default {
  name: "socialpage",
  components: {
    FormBlock
  },
  mounted(){
    this.setMetaInfo({
        page: "socialpage",
    });

    new Swiper('.seo_about_slider', {
        loop: true,
        spaceBetween: 30,
        slidesPerView:1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints:{
            320:{
                slidesPerView:1,
            },
            600:{
                slidesPerView:1,
            },
        },
    });
    new Swiper('.seo_create_slider', {
        loop: false,
        spaceBetween: 30,
        slidesPerView: 3,
        breakpoints:{
            320:{
              slidesPerView: 'auto',
              spaceBetween: 10,
              centeredSlides: true,
            },
            600:{
              slidesPerView:3,
            },
        },
    });
  },
  data() {
    return {
      modalOpened: false,
      selectedService:0,
      isactive:false,
      swiper:'',
      sliderImages:[
        { 
          title: 'Трудовые династии',
          text: 'Помимо родственной близости у нас появляется одно общее увлечение — работа.',
          link: '/projects/bolshe-chem-kollegi-bolshe-chem-semya/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider1.jpg'),
          alt:'',
        },
        { 
          title: 'Давайте помнить о победах чаще!',
          text: 'Подарки, благодарственные речи, красочные представления. Но это ли ему нужно? Ведь он всё равно вернётся домой один…',
          link: '/projects/davayte-pomnit-o-pobedakh-chashche/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider2.jpg'),
          alt:'',
        },
        { 
          title: 'Маленькими шагами к большой цели',
          text: 'Даже самые незначительные действия могут стать началом чего-то великого.',
          link: '/projects/malenkimi-shagami-k-bolshoy-tseli/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider3.jpg'),
          alt:'',
        },
        { 
          title: 'Нам не всё равно',
          text: 'Первые впечатления о городе портит бескультурное поведение граждан.',
          link: '/projects/nam-ne-vsye-ravno-dedushka/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider4.jpg'),
          alt:'',
        },
        { 
          title: 'В этом нет стыда',
          text: 'Настоящий патриотизм, думать о здоровье своих родных и близких',
          link: '/projects/glyantsevyy-rolik-patrioticheskogo-soderzhaniya-v-etom-net-styda-3/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider5.jpg'),
          alt:'',
        },
        {
          title: 'Отан Ана',
          text: 'Помимо родственной близости у нас появляется одно общее увлечение — работа.',
          link: '/projects/rodina-mat/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider6.jpg'),
          alt:'',
        },
        {
          title: 'Взрослый мир глазами ребенка',
          text: 'О чём помнят дети, но часто забывают взрослые…',
          link: '/projects/videorolik-glazami-detey/',
          src:require('@/assets/img/pages/seopages/socialpage/social_slider7.jpg'),
          alt:'',
        },
      ],
      preProduction: [
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_1.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_2.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_3.png') },
      ],
      production: [
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_4.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_5.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_6.png') },
      ],
      postProduction: [
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_7.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_8.png') },
        { src:require('@/assets/img/pages/seopages/socialpage/social_create_9.png') },
      ],
      tabsMenu: [
        {
          tabsMenuItem: 'Цель социального ролика',
        },
        {
          tabsMenuItem: 'Преимущества социального ролика',
        },
        {
          tabsMenuItem: 'Кому подходит',
        },
        {
          tabsMenuItem: 'Какие задачи решает',
        },
      ],
      tabsItem: [
        {
          tabsItemText_1: 'Изменить мышление или привычное поведение людей, изменить модель общественного поведения и привлечь внимание к социальным проблемам. Как это осуществить? За счёт сильной идеи, которая должна «зацепить» аудиторию, привлечь внимание — это целое искусство.',
          tabsItemText_2: 'Как пример тем для социальных роликов — кампании по борьбе с наркотиками, соблюдению ПДД, пропаганде здорового образа жизни, охраны окружающей среды, просвещению аудитории и многие другие.',
        },
        {
          tabsItemText_1: '1. Дольше удерживает внимание зрителей — социальная тема всегда смотрится выгодней на фоне стандартной рекламы',
          tabsItemText_2: '2. Легко интегрировать в ролик свой продукт или организацию, когда нужно показать успехи в том или ином деле',
          tabsItemText_3: '3. Повышает уровень доверия аудитории, потенциальных и постоянных клиентов',
        },
        {
          tabsItemText_1: '1. Тем, кто неравнодушен к общественным проблемам, явлениям',
          tabsItemText_2: '2. Тем, кто хочет показать через свою деятельность достижение положительных результатов в решении той или иной проблемы',
          tabsItemText_3: '3. Тем, кто готов помогать',
        },
        {
          tabsItemText_1: '1. Привлекает внимание к конкретным общественными проблемам и явлениям',
          tabsItemText_2: '2. Повышает уровень гуманности в обществе, служит инструментом просвещения',
          tabsItemText_3: '3. Мотивирует людей к определенным действиям с целью решения проблемы',
          tabsItemText_4: '4. Формирует общественное мнение, модели поведения по какому-либо вопросу',
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.modalOpened = true;
    },
    closeModal() {
      this.modalOpened = false;
    },
    selectService(index){
      this.selectedService = index
    },
    ...mapMutations(["setMetaInfo"]),

    // Форма заявки, обязательно добавлять страницу и url
    ...mapActions(["sendContactsMainpage"]),
    sendContactsForm(data) {
        Object.assign(data, {pageUrl: "/socialnyj-videorolik"});
        Object.assign(data, {page: "Социальный видеоролик"});
        this.sendContactsMainpage(data);
    },
  },
};
</script>

<style lang="scss" scoped></style>