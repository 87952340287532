<template>
    <div class="title">
        {{ text }}
    </div>
</template>

<script>
    export default {
        name:'Title',
        props:{
            text:{
                type:String,
                required:false,
                default:'',
            }
        }
    }
</script>

<style lang="scss" scoped>
    .title{
        font-weight: 800;
        font-size: 48px;
        line-height: 59px;
        text-transform: uppercase;
    }

    @media (max-width: 1366.98px){
        .title{
            font-size: 36px;
            line-height: 48px;
        }
    }
</style>