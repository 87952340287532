<template>
  <div class="services">
    <div class="container">
      <div class="row">
        <div class="col-10 col-start-2">
          <div class="app__titlewrap">
            <h1 class="app__title" v-html="getServicesInfo.title"></h1>
          </div>

          <div class="app__pretitlewrap">
            <p
              class="app__pretitle app__pretitle--bold"
              v-html="getServicesInfo.headerTitle"
            ></p>
            <p
              class="app__pretitle"
              v-for="(item, index) in getServicesInfo.headerTexts"
              :key="index"
              v-html="item"
            ></p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="services__imagelist" v-if="getServicesImages.length > 0">
            <div
              class="services__imagewrap"
              v-for="(item, index) in getServicesImages"
              :key="index"
            >
              <img
                :ref="'simage' + index"
                :src="item.src"
                :alt="item.alt"
                class="services__image"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">
          <div class="services__detail">
            <div class="services__detail-list">
              <services-card
                v-for="(item, index) in getServicesList"
                :key="index"
                :data="item"
                :isactive="index == selectedService ? true : false"
                :serviceIndex="index"
                @changeService="selectService"
                @mobileOpenMore="moreButtonHandler"
              />
            </div>

            <div class="services__detail-content">
              <transition name="fade" mode="out-in">
                <p
                  class="services__detail-content-des"
                  v-if="getServicesList[selectedService]"
                  v-html="getServicesList[selectedService].des"
                ></p>
              </transition>
              <p
                class="services__detail-content-more"
                @click="
                  moreButtonHandler(getServicesList[selectedService].position)
                "
                v-html="$t('messages.services.details')"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServicesCard from "../services/components/ServicesCard";

import { TimelineMax, Power3 } from "gsap";

import { mapMutations, mapActions, mapGetters } from "vuex";

// import VacancyCard from "../../components/common/VacancyCard"

export default {
  name: "SpecialProjects",
  components: {
    ServicesCard,
  },
  computed: {
    ...mapGetters([
      "getServicesImages",
      "getServicesList",
      "getServicesInfo",

      "getProjectFilters",
      "getCurrentLanguage",
    ]),
  },
  watch: {
    getCurrentLanguage: function () {
      this.fetchServices();
    },
  },
  mounted() {
    this.fetchServices();

    this.setMetaInfo({
      page: "services",
    });
  },
  data() {
    return {
      selectedService: 0,
    };
  },
  methods: {
    ...mapMutations(["setMetaInfo"]),

    ...mapActions(["fetchServices"]),

    selectService(selectIndex) {
      this.selectedService = selectIndex;
    },

    moreButtonHandler(tag) {
      this.$router.push({
        name: "projectsIndex",
        params: {
          tag,
        },
      });
    },

    animateImages() {
      let { simage0, simage1, simage2 } = this.$refs;

      let timelineImages = new TimelineMax({ delay: 0.5 });

      timelineImages
        .from(simage0, 0.6, {
          xPercent: "-=100",
          ease: Power3.easeInOut,
        })
        .from(
          simage1,
          0.6,
          {
            xPercent: "-=100",
            ease: Power3.easeInOut,
          },
          "-=.4"
        )
        .from(
          simage2,
          0.6,
          {
            xPercent: 100,
            ease: Power3.easeInOut,
          },
          "-=.3"
        );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>