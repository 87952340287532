<template>
    <div class="vmodal" :class="customClassList">

        <div class="vmodal__overlay" @click="closeModal"></div>

        <div class="vmodal__main">
            <div class="container">
                <div class="row">
                    <div class="col-8 col-start-3 col-sm-10 col-sm-start-2 col-xs-12 col-xs-start-1">

                        <div class="vmodal__content">
                            <img src="@/assets/img/icons/close-dark.svg" alt="" class="vmodal__close" @click="closeModal">
                            
                            <p class="vcard__position" v-html="data.position"></p>

                            <div class="vcard__descr">
                                <p class="vcard__descr-text" v-html="data.des"></p>
                            </div>

                            <div class="vmodal__section" v-for="(item, index) in sectionTitlesArr" :key="index">
                                <p class="vmodal__section-title" v-html="data[item].title"></p>
                                <ul class="vmodal__section-list">
                                    <li v-for="(listitem,index) in data[item].list" :key="index" v-html="listitem"></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'VacancyModal',
        props:{
            opened:{
                type:Boolean,
                required:false,
                default:false,
            },
            data:{
                type:Object,
                requied:false,
                default:()=>{}
            }
        },
        computed:{

            customClassList(){
                let classList = []

                if(this.opened){
                    classList.push('vmodal__opened')
                }

                return classList
            }

        },
        data(){
            return {
                sectionTitlesArr:[
                    'responsibilities',
                    'requirements',
                    'desirable',
                    'conditions',
                ]
            }
        },
        methods:{
            closeModal(){
                this.$emit('closeModal')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>