<template>
  <div class="awards">
    <div class="container">
      <div class="row">
        <div class="col-10 col-start-2">
          <div class="app__titlewrap">
            <h1 class="app__title">Награды</h1>
          </div>

          <div class="awitem" v-for="(item, index) in getAwards" :key="index">
            <div class="awitem__yearwrap">
              <div class="awitem__yearblock">
                <h5
                  class="awitem__year"
                  v-html="item.year"
                  :id="'year' + index"
                ></h5>
              </div>

              <div class="awitem__linewrap">
                <div class="awitem__line"></div>

                <div class="awitem__trigger" :id="'trigger' + index"></div>

                <div class="awitem__dotwrap">
                  <div
                    class="awitem__dot awitem__dot--visible"
                    :id="'dot' + index + n"
                    :class="'awitem__dot--' + n"
                    v-for="n in item.blocks.length"
                    :key="n"
                  ></div>
                  <div class="awitem__dot awitem__dot--unvisible"></div>
                </div>
              </div>

              <div
                class="awitem__content"
                :id="'awardBlock' + index + indexAward"
                v-for="(award, indexAward) in item.blocks"
                :key="indexAward"
              >
                <div class="awitem__des" :class="getDesClass(indexAward)">
                  <div class="awitem__titlewrap">
                    <p class="awitem__title" v-html="award.title"></p>
                  </div>

                  <div
                    class="awitem__list"
                    v-for="(element, indexElement) in award.elements"
                    :key="indexElement"
                  >
                    <p class="awitem__list-title" v-html="element.title"></p>
                    <p
                      class="awitem__list-text"
                      v-for="(text, indexText) in element.list"
                      :key="indexText"
                      v-html="text"
                    ></p>
                  </div>
                </div>

                <div
                  class="awitem__imagewrap"
                  :class="getImageClass(indexAward)"
                >
                  <div
                    class="awitem__awards"
                    :class="
                      award.elements.length == 1 ? 'awitem__awards--single' : ''
                    "
                  >
                    <img
                      :src="elemewnt.image"
                      v-for="(elemewnt, index) in award.elements"
                      :key="index"
                      alt=""
                      class="awitem__awards-image"
                    />
                  </div>
                  <a :href="award.href">
                    <!-- <img v-if="award.show == true" src="/img/mplay.1d0291e5.svg" alt="" class="awitem__button"> -->
                    <img :src="award.image" alt="" class="awitem__image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { TweenMax, TimelineMax, SlowMo, Back, Power3 } from "gsap";

export default {
  name: "Awards",
  computed: {
    ...mapGetters(["getAwards"]),
  },
  mounted() {
    this.setMetaInfo({
      page: "awards",
    });

    document.body.style = "overflow: visible; height: auto;";
    document.querySelector("html").style = "unset";

    this.animateYears();
    this.animateLines();
    this.animateDots();
    this.animateAwardBlocks();
  },
  methods: {
    ...mapMutations(["setMetaInfo"]),

    getDesClass(index) {
      return (index + 1) % 2 == 0 && index !== 0 ? "awitem__des--even" : "";
    },

    getImageClass(index) {
      return (index + 1) % 2 == 0 && index !== 0
        ? "awitem__imagewrap--even"
        : "";
    },

    animateYears() {
      let years = document.querySelectorAll(".awitem__year");

      years.forEach((year) => {
        let id = "#" + year.getAttribute("id");

        const sceneYear = this.$scrollmagic
          .scene({
            triggerElement: id,
            triggerHook: 0.8,
            duration: 0,
          })
          .setTween(
            TweenMax.from(year, 1, {
              yPercent: 200,
              ease: Power3.easeInOut,
            })
          );

        this.$scrollmagic.addScene(sceneYear);
      });
    },

    animateLines() {
      let lines = document.querySelectorAll(".awitem__line");

      lines.forEach((item, index) => {
        let id = "#trigger" + index;

        const sceneLine = this.$scrollmagic
          .scene({
            triggerElement: id,
            triggerHook: 0.8,
            duration: 500,
          })
          .setTween(
            TweenMax.from(item, 5, {
              css: {
                height: 0,
              },
              delay: 0.6,
              ease: SlowMo.easeInOut,
            })
          );

        this.$scrollmagic.addScene(sceneLine);
      });
    },

    animateDots() {
      let dots = document.querySelectorAll(".awitem__dot--visible");

      TweenMax.set(dots, {
        scale: 0,
      });

      dots.forEach((item) => {
        let id = "#" + item.getAttribute("id");

        let timelineDot = new TimelineMax();

        timelineDot
          .to(item, 0.4, {
            scale: 2,
            ease: Back.easeInOut,
          })
          .to(item, 0.4, {
            scale: 1,
            ease: Power3.easeInOut,
          });

        const sceneDots = this.$scrollmagic
          .scene({
            triggerElement: id,
            triggerHook: 0.5,
            duration: 0,
          })
          .setTween(timelineDot);

        this.$scrollmagic.addScene(sceneDots);
      });
    },

    animateAwardBlocks() {
      let blocks = document.querySelectorAll(".awitem__content");

      blocks.forEach((item, index) => {
        let id = "#" + item.getAttribute("id");

        let percent = "-=100";
        let awardsImageMovePercent = "-=150";

        let imagewrap = item.querySelector(".awitem__imagewrap");
        if (imagewrap.classList.contains("awitem__imagewrap--even")) {
          percent = "100";
          awardsImageMovePercent = "150";
        }

        let image = item.querySelector(".awitem__image");

        let textwrap = item.querySelector(".awitem__des");

        let title = textwrap.querySelector(".awitem__title");
        let elements = textwrap.querySelectorAll(".awitem__list");

        let timelineBlock = new TimelineMax();

        timelineBlock
          .from(image, 0.4, {
            xPercent: percent,
            alpha: 0,
            ease: SlowMo.easeInOut,
          })
          .from(
            title,
            0.4,
            {
              yPercent: 40,
              alpha: 0,
              ease: Power3.easeInOut,
            },
            "-=.2"
          );

        elements.forEach((element) => {
          timelineBlock.from(
            element,
            0.3,
            {
              yPercent: 40,
              alpha: 0,
              ease: Power3.easeInOut,
            },
            "-=.2"
          );

          let awardsImageBlock = imagewrap.querySelector(".awitem__awards");

          if (awardsImageBlock && index != 0) {
            timelineBlock.from(awardsImageBlock, 0.4, {
              xPercent: awardsImageMovePercent,
              ease: Power3.easeInOut,
            });
          }
        });

        const sceneAwardBlocks = this.$scrollmagic
          .scene({
            triggerElement: id,
            triggerHook: 0.5,
            duration: 0,
          })
          .setTween(timelineBlock);

        this.$scrollmagic.addScene(sceneAwardBlocks);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>