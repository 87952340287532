<template>
    <div class="seopage">
        <div class="imagevideo">
            <div class="container">
                <div class="app__titlewrap">
                    <h1 class="app__title">Анимационный ролик</h1>
                </div>

                <div class="imagevideo__videowrap">
                    <video
                        class="imagevideo__videowrap-video"
                        autoplay="autoplay"
                        playsinline=""
                        muted="muted"
                        loop="loop"
                        poster="https://api.blackandwhite.kz/upload/resize_cache/iblock/77e/947_480_2/77e74d4ac4f4cb481804c5bb99471606.jpg"
                    >
                        <source
                            src="https://api.blackandwhite.kz/upload/iblock/310/31036d48b589300192c99c094ef3ee04.mp4"
                            type="video/mp4"
                        />
                    </video>
                    <div class="imagevideo__btn" @click="openModal">
                        <img
                            src="@/assets/img/pages/mainpage/mplay.svg"
                            alt=""
                            class="imagevideo__btn-icon"
                        />
                        <p
                            class="imagevideo__btn-text"
                            v-html="'Смотреть ролик'"
                        ></p>
                    </div>
                </div>

                <h2 class="imagevideo__title">
                    Главная цель
                    <span> Анимационного ролика </span>
                </h2>

                <div class="imagevideo__title-text">
                    <span>Анимационный ролик</span> — это небольшой мультфильм,
                    герои которого передают зрителю в легкой форме определенный
                    посыл. Чаще всего анимационные ролики используют для
                    получения удачного психологического эффекта на зрителя —
                    клиент должен захотеть приобрести тот или иной продукт, или
                    воспользоваться представленной в ролике услугой.
                </div>
                <div class="imagevideo__title-text">
                    <span>Примеры:</span> ролик на тему обязательного
                    социального медицинского страхования.
                </div>
                <div class="imagevideo__title-text">
                    <span>Преимущества:</span>
                    <ul>
                        <li>
                            1. Вызывает эмоции — анимация всегда привлекает
                            зрителей;
                        </li>
                        <li>
                            2. Простота — сложные факты показывают в новой
                            форме, облегчая восприятие;
                        </li>
                        <li>3. Яркая и стильная картинка;</li>
                        <li>
                            4. Кроссплатформенность и способность
                            распространяться на самые разные сегменты аудитории;
                        </li>
                        <li>5. Творческий подход — всегда привлекательнее.</li>
                    </ul>
                </div>

                <div class="imagevideo__title-text">
                    <span>Какие задачи решает:</span>
                    <ul>
                        <li>
                            - В доступной форме доносит до аудитории нужную
                            информацию
                        </li>
                        <li>
                            - Привлекает новых клиентов и партнеров за счёт
                            креативного визуального решения
                        </li>
                        <li>
                            - Способен надолго закрепиться в медиа-пространстве
                        </li>
                    </ul>
                </div>
                <div class="imagevideo__title-text">
                    <span>Кому подходит:</span>
                    <ul>
                        <li>
                            - Всем, кто готов коммуницировать со своей
                            аудиторией на разных языках и принимать
                            нестандартные решения
                        </li>
                    </ul>
                </div>
                <div class="imagevideo__title-text">
                    <span
                        >Компания Black & White — один из лидеров
                        видеопроизводства в Казахстане.</span
                    >
                    <ul>
                        <li>
                            Мы любим и умеем делать корпоративные и
                            документальные фильмы и видеоролики разных форматов.
                            По способу производства видеоролики могут быть
                            разные: постановочно-игровые, документальные,
                            инфографические, анимационные. Главное — все
                            видеопродукты будут решать поставленные задачи.
                        </li>
                    </ul>
                </div>
                <div class="imagevideo__title-text">
                    <span>Как заказать ролик для имиджа компании?</span>
                    <ul>
                        <li>
                            Имидж-контент отличается художественностью.
                            Благодаря кинематографическому стилю и ярким образам
                            он привлекает внимание и хорошо запоминается. Также
                            плюс подобных видео в том, что они всегда получают
                            эмоциональный отклик и делают тот или иной бренд
                            узнаваемым. Решив заказать создание имиджа, вы
                            получаете и повышение продаж. Так что это не просто
                            работа над статусом, а часть маркетинговой
                            стратегии. Цены на съемку варьируются от сценария и
                            других особенностей, поэтому даже небольшая фирма
                            может позволить себе данную стоимость услуги.
                        </li>
                    </ul>
                </div>

                <div class="order__wrap">
                    <button class="order__button">
                        <p class="order__button-text">Заказать</p>
                    </button>
                </div>
            </div>
        </div>

        <div class="seopage__modal">
            <div class="seopage__modal-wrap" v-if="modalOpened">
                <iframe
                    :src="
                        'https://player.vimeo.com/video/522706455' +
                        '?title=0&byline=0&portrait=0&autoplay=1'
                    "
                    style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    "
                    class="seopage__modal-video"
                    frameborder="0"
                    allow="autoplay;fullscreen"
                    allowfullscreen
                >
                </iframe>

                <img
                    src="@/assets/img/icons/close.svg"
                    alt=""
                    class="seopage__modal-close"
                    @click="closeModal"
                />
            </div>
        </div>

        <div class="work">
            <div class="container">
                <h2 class="work__title">Этапы работы</h2>

                <div class="work__steps">
                    <div class="work__step">
                        <div class="work__step-number">01</div>
                        <div class="work__step-title">Разрабатываем формат</div>
                        <div class="work__step-text">
                            Первым делом планируем структуру видео, которая
                            позволит удержать внимание зрителей.
                        </div>
                    </div>
                    <div class="work__step">
                        <div class="work__step-number">02</div>
                        <div class="work__step-title">Собираем команду</div>
                        <div class="work__step-text">
                            Мы за работу «по секциям», где каждый профессионал
                            своего дела и выполняет свой блокна 5+.
                            Люди-оркестры — не наш вариант.
                        </div>
                    </div>
                    <div class="work__step">
                        <div class="work__step-number">03</div>
                        <div class="work__step-title">Готовим локацию</div>
                        <div class="work__step-text">
                            Будет это уютная студия или большой зал? А может,
                            кабинет в бизнес-центре с видом на Москву с 59
                            этажа? Все зависит от цели и формата съемки.
                        </div>
                    </div>
                    <div class="work__step">
                        <div class="work__step-number">04</div>
                        <div class="work__step-title">Постпродакшн</div>
                        <div class="work__step-text">
                            <p>• Выравниваем звук</p>
                            <p>• Проводим цветокорекцию</p>
                            <p>
                                • Чистим запись от всяких «Э-э-э» и «Ой, что там
                                дальше?»
                            </p>
                            <p>• Накладываем титры</p>
                            <p>• Добавляем заставку, перебивки</p>
                            <p>
                                • При необходимости вносим правки от заказчика
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="seoprojects">
            <div class="container">
                <div class="seoprojects__header">
                    <div class="seoprojects__header-title">Примеры работ</div>
                    <div class="seoprojects__header-all">
                        <a href="/projects">
                            <div class="seoprojects__link-icon">
                                <svg
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="link__icon-src"
                                >
                                    <path
                                        d="M13.8375 4.88981C14.0328 4.69455 14.0328 4.37796 13.8375 4.1827L10.6555 1.00072C10.4603 0.805459 10.1437 0.805459 9.94842 1.00072C9.75315 1.19598 9.75315 1.51257 9.94842 1.70783L12.7768 4.53625L9.94842 7.36468C9.75315 7.55994 9.75315 7.87653 9.94842 8.07179C10.1437 8.26705 10.4603 8.26705 10.6555 8.07179L13.8375 4.88981ZM0.036377 5.03625H13.484V4.03625H0.036377V5.03625Z"
                                    />
                                </svg>
                            </div>
                            <p class="seoprojects__link-text">
                                Посмотреть все проекты
                            </p>
                        </a>
                    </div>
                </div>

                <div class="seoprojects__wrap">
                    <div class="seoprojects__item">
                        <a
                            href="/projects/gazoprovod-saryarka/"
                            class="seoprojects__item-link"
                        >
                            <img
                                src="https://api.blackandwhite.kz/upload/iblock/cd6/cd662ebe45d6eaa192deb6d6f227680c.jpg"
                                alt=""
                            />
                        </a>
                    </div>
                    <div class="seoprojects__item">
                        <a
                            href="/projects/svyaz-vremen/"
                            class="seoprojects__item-link"
                        >
                            <img
                                src="https://api.blackandwhite.kz/upload/iblock/909/epakw18r2xuv5zak5kslf54xvwpbream.webp"
                                alt=""
                            />
                        </a>
                    </div>
                    <div class="seoprojects__item">
                        <a
                            href="/projects/privychka-truditsya/"
                            class="seoprojects__item-link"
                        >
                            <img
                                src="https://api.blackandwhite.kz/upload/iblock/99d/99d32e5102b13d44e043e8f64b16406a.jpg"
                                alt=""
                            />
                        </a>
                    </div>
                    <div class="seoprojects__item">
                        <a
                            href="/projects/american-nomad/"
                            class="seoprojects__item-link"
                        >
                            <img
                                src="https://api.blackandwhite.kz/upload/iblock/c33/c339c1de963d9b63812c3a267eedd4fe.jpg"
                                alt=""
                            />
                        </a>
                    </div>
                </div>

                <div class="order__wrap">
                    <button class="order__button">
                        <p class="order__button-text">Заказать</p>
                    </button>
                </div>
            </div>
        </div>

        <contacts-form />
    </div>
</template>

<script>
import ContactsForm from "../contacts/components/ContactsForm";
import { mapMutations } from "vuex";
export default {
    name: "Animations",
    components: {
        ContactsForm,
    },
    data() {
        return {
            modalOpened: false,
        };
    },

    mounted() {
        this.setMetaInfo({
            page: "animations",
        });
    },
    methods: {
        ...mapMutations(["setMetaInfo"]),
        openModal() {
            this.modalOpened = true;
        },
        closeModal() {
            this.modalOpened = false;
        },
    },
};
</script>

<style lang="scss" scoped></style>
