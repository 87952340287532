<template>
    <div class="preloadlist">
        <preloader-card v-for="n in count" :key="n" />
    </div>
</template>

<script>
    import PreloaderCard from '@/components/common/PreloadCard'

    export default {
        name:'PreloaderList',
        components:{
            PreloaderCard,
        },
        props:{
            count:{
                type:Number,
                default:6,
                required:false,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>