import { render, staticRenderFns } from "./ContactsBlock.vue?vue&type=template&id=386c7468&scoped=true&"
import script from "./ContactsBlock.vue?vue&type=script&lang=js&"
export * from "./ContactsBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "386c7468",
  null
  
)

export default component.exports