<template>
    <div class="vcard" :class="cardCustomClasses">

        <div class="vcard__decor">
            <img src="@/assets/img/icons/x.svg" alt="" class="vcard__decor-icon">
            <img src="@/assets/img/icons/x.svg" alt="" class="vcard__decor-icon">
        </div>

        <div class="vcard__lines">
            <img src="@/assets/img/icons/three-lines.svg" alt="" class="vcard__lines-icon">
        </div>

        <div class="vcard__image">
            <img :src="data.image" alt="" class="vcard__image-src">
        </div>

        <div class="vcard__content">
            <p class="vcard__position" v-html="data.position"></p>
            <div class="vcard__descr">
                <p class="vcard__descr-text" v-html="desText"></p>
            </div>
            <button class="vcard__more" @click="openModal">
                <p class="vcard__more-text" v-html="$t('messages.vacancies.details')"></p>
            </button>
        </div>

    </div>
</template>

<script>
    export default {
        name:'VacancyCard',
        computed:{
            
            desText(){
                let desText = ''

                desText = this.data.des.slice(0,150)+'...'

                return desText
            },

            cardCustomClasses(){
                let classList = []
                
                if(this.data.position=='Специалист по продаже медиапродуктов'){
                    classList.push('vcard_spec_sale')
                } else if(this.data.position=='Моушен-дизайнер'){
                    classList.push('vcard_spec_designer')
                } else if(this.data.position=='Видеомонтажер'){
                    classList.push('vcard_spec_video')
                } else if(this.data.position=='Сценарист видеопроектов на казахском языке'){
                    classList.push('vcard_spec_script')
                } 

                return classList
            }
        },
        data(){
            return {
            }
        },
        props:{
            data:{
                type:Object,
                required:false,
                default:()=>{}
            },
        },
        methods:{

            openModal(){
                this.$emit('moreButtonHandler',this.data)
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>
