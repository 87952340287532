const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleServices = {
	state: {
		title: "",
		headerTitle: "",
		headerTexts: "",
		images: [],
		services: [],
	},
	mutations: {},
	actions: {
		fetchServices({ state, rootGetters }) {
			let locale = rootGetters.getCurrentLanguageKey;
			state.services = [];

			axios
				.post("https://api.blackandwhite.kz/bw/services/?c=11&lang=" + locale)
				.then((response) => {
					return response.data;
				})
				.then((data) => {
					let {
						info: {
							title,
							mainInfo: { title: headerTitle, texts: headerTexts },
							images,
						},
						services,
					} = data;

					state.title = title;
					state.headerTitle = headerTitle;
					state.headerTexts = headerTexts;
					state.images = images;

					let sortedArr = services.sort((a, b) => {
						return parseInt(a.SORT) - parseInt(b.SORT);
					});

					sortedArr.forEach((item, index) => {
						let {
							ID: id,
							NAME: position,
							SORT: sort,
							PREVIEW_TEXT: des,
						} = item;

						state.services.push({
							index,
							id,
							position,
							des,
							sort,
						});
					});
				});
		},
	},

	getters: {
		getServicesInfo(state) {
			return {
				title: state.title,
				headerTitle: state.headerTitle,
				headerTexts: state.headerTexts,
			};
		},
		getServicesImages(state) {
			return state.images;
		},

		getServicesList(state) {
			return state.services;
		},
	},
};

export default moduleServices;
