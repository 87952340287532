<template>
    <div class="pfilter">
        <div class="tabs">
            <ul>
                <li
                    v-for="tab in getProjectFilters"
                    :class="{ 'is-active': tab.active }"
                    :key="tab.title"
                    @click="setFilter(tab)"
                >
                    <a :href="tab.href">{{ tab.title }} </a>
                </li>
            </ul>
        </div>
        <div class="tabs-details">
            <slot>
                <ul class="tabs-uls">
                    <li
                        v-for="tab in getProjectSubFilters"
                        :class="{ 'is-active': tab.active }"
                        :key="tab.title"
                        @click="setSubFilter(tab)"
                    >
                        <a :href="tab.href">{{ tab.title }}</a>
                    </li>
                </ul>
            </slot>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

import { TweenMax, TimelineMax, Power3 } from "gsap";

export default {
    name: "ProjectsFilter",
    computed: {
        ...mapGetters([
            "getProjectFilters",
            "getProjectSubFilters",
            "getActiveFilter",
        ]),
    },
    mounted() {
        TweenMax.set(this.$refs.dropdown, {
            alpha: 0,
            css: {
                display: "none",
            },
        });

        TweenMax.from(this.$refs.filter, 0.6, {
            yPercent: 100,
            alpha: 0,
            ease: Power3.easeInOut,
            delay: 0.3,
        });
    },
    data() {
        return {};
    },
    methods: {
        ...mapMutations(["setProjectsFilter", "setProjectsSubFilter"]),
        ...mapGetters(["getProjectActiveFilter"]),

        openDrodown() {
            let { dropdown } = this.$refs;

            let timeline = new TimelineMax();

            timeline
                .to(dropdown, 0, {
                    alpha: 1,
                    css: {
                        display: "flex",
                    },
                })
                .from(dropdown, 0.3, {
                    css: {
                        height: 0,
                    },
                    ease: Power3.easeInOut,
                });
        },
        closeDropdown() {
            let { dropdown } = this.$refs;

            let timeline = new TimelineMax();

            timeline
                .to(dropdown, 0.3, {
                    css: {
                        height: 0,
                    },
                    ease: Power3.easeInOut,
                })
                .to(dropdown, 0, {
                    alpha: 0,
                    css: {
                        height: "auto",
                        display: "none",
                    },
                });
        },
        // нажимаю фильтрует и показывает подтэги
        setFilter(tab) {
            let { dropdown } = this.$refs;

            window.scrollTo(0, 0);
            let tag = tab.tag;
            this.$router.replace({
                ...this.$router.currentRoute,
                query: { filter: tag },
            });
            this.$emit("onChangeFilter", () => {
                this.setProjectsFilter({ tag });
            });

            let timeline = new TimelineMax();

            timeline
                .to(dropdown, 0.3, {
                    css: {
                        height: 0,
                    },
                    ease: Power3.easeInOut,
                })
                .to(dropdown, 0, {
                    alpha: 0,
                    css: {
                        height: "auto",
                        display: "none",
                    },
                });
        },
        setSubFilter(tab) {
            let { dropdown } = this.$refs;

            window.scrollTo(0, 0);
            let tag = tab.title;

            this.$router.replace({
                ...this.$router.currentRoute,
                query: {
                    filter: this.getProjectActiveFilter().title,
                    subFilter: tag,
                },
            });
            this.$emit("onChangeFilter", () => {
                this.setProjectsSubFilter({ tag });
            });

            let timeline = new TimelineMax();

            timeline
                .to(dropdown, 0.3, {
                    css: {
                        height: 0,
                    },
                    ease: Power3.easeInOut,
                })
                .to(dropdown, 0, {
                    alpha: 0,
                    css: {
                        height: "auto",
                        display: "none",
                    },
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>
