<template>
    <div class="comview">

        <div class="comview__main">

            <div class="comview__main-title">
                <p class="comview__main-name" v-html="data.name"></p>
                <p class="comview__main-date" v-html="data.date"></p>
            </div>

            <div class="comview__main-comment">
                <p class="comview__main-comment-text" v-html="data.text"></p>
            </div>

        </div>


        <div class="comview__control">

            <div class="comview__share">
                <img src="@/assets/img/pages/special/comments.svg" alt="" class="comview__share-icon">
                <p class="comview__share-text" v-html="data.replies?data.replies.length:0"></p>
            </div>

            <div class="comview__share">
                <img src="@/assets/img/pages/special/share.svg" alt="" class="comview__share-icon">
                <p class="comview__share-text" v-html="data.shareCount?data.shareCount:0"></p>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name:'CommentView',
        props:{
            data:{
                type:Object,
                required:false,
                default:()=>{}
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>