<template>
    <div class="preloadcard"></div>
</template>

<script>
    export default {
        name:'PreloadCard'
    }
</script>

<style lang="scss" scoped>

</style>