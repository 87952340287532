<template>
  <div class="nlist">
    <div class="container">
      <div class="row">
        <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">
          <div class="nlist__titlewrap">
            <h1 class="nlist__title">Новости</h1>
          </div>
        </div>

        <div class="col-12">
          <div class="nlist__content">
            <router-link
              class="nlist__item"
              :to="'/news/' + item.router + '/'"
              v-for="(item, index) in getNewsList"
              :key="index"
            >
              <div class="nlist__item-iconwrap">
                <img
                  src="@/assets/img/icons/calendar.svg"
                  alt=""
                  class="nlist__item-icon"
                />
              </div>

              <div class="nlist__item-posterwrap">
                <img :src="item.poster" alt="" class="nlist__item-poster" />
              </div>

              <div class="nlist__item-contentwrap">
                <div class="nlist__item-datewrap">
                  <p class="nlist__item-date" v-html="item.date"></p>
                </div>

                <div class="nlist__item-titlewrap">
                  <p class="nlist__item-title" v-html="item.title"></p>
                </div>

                <div class="nlist__item-textwrap">
                  <p class="nlist__item-text" v-html="item.text"></p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewsList",
  computed: {
    ...mapGetters(["getNewsList"]),
  },
};
</script>

<style lang="scss" scoped>
</style>