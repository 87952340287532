<template>
    <div class="projects">
        <div class="container container--overvisible">
            <div class="row">
                <div class="col-10 col-start-2 col-xs-start-1 col-xs-12">
                    <div class="app__titlewrap projects__titlewrap">
                        <div>
                            <h1
                                class="app__title"
                                ref="title"
                                v-html="getProjectsTitle"
                            ></h1>
                        </div>
                        <projects-filter
                            @onChangeFilter="setProjectlistLoader"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="projects__content">
            <div class="container" ref="projectContainer">
                <transition name="fade" mode="out-in">
                    <div
                        class=""
                        ref="projectlist"
                        v-if="projectsList.length > 0"
                        key="mainprojects"
                    >
                        <transition-group
                            class="pitem-transition row projects__row"
                            name="list"
                            tag="div"
                        >
                            <div
                                class="col-6 col-md-6 col-xs-12 projects__item"
                                v-for="project in projectsList"
                                :key="project.title"
                            >
                                <projects-item :project="project" />
                            </div>
                        </transition-group>
                        <div class="col-12" v-if="!allProjectsLoaded">
                            <div class="projects__loadmore">
                                <!-- <app-btn @clickHandler="loadMoreProjects" /> -->
                                <!-- <app-btn text="Загрузить еще" @clickHandler="loadMoreProjects" /> -->
                            </div>
                        </div>
                    </div>
                    <div v-else key="preloader">
                        <preloader-list />
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import ProjectsFilter from "./components/ProjectsFilter";
import ProjectsItem from "./components/ProjectsItem";

import PreloaderList from "@/components/common/PreloadList";

import { TimelineMax, Power3, SlowMo } from "gsap";

let projectContainerTimeline = new TimelineMax();

export default {
    name: "ProjectsIndex",
    components: {
        ProjectsFilter,
        ProjectsItem,
        PreloaderList,
    },
    computed: {
        ...mapGetters([
            "getFilteredProjects",
            "getProjectFilters",
            "getProjectSubFilters",
            "getProjectsTitle",
        ]),

        projectsList: function () {
            return this.getFilteredProjects.slice(
                this.listStart,
                this.listCounts
            );
        },
    },
    watch: {
        //todo: тут нужна ошибка если пришел пустой объект с сообщением, что "Ошибка со стороны сервера"
        getFilteredProjects: function (val) {
            let { projectContainer } = this.$refs;

            projectContainerTimeline.to(projectContainer, 0.6, {
                alpha: 1,
                ease: SlowMo.easeInOut,
            });

            if (val.length > this.listStep) {
                this.allProjectsLoaded = false;
            } else {
                this.allProjectsLoaded = true;
            }
        },
    },
    mounted() {
        this.loadProjectScroll();

        let { title, projectContainer } = this.$refs;

        let timeline = new TimelineMax({
            delay: 0.6,
        });

        timeline
            .from(title, 0.6, {
                alpha: 0,
                yPercent: 100,
                ease: Power3.easeInOut,
            })
            .from(
                projectContainer,
                1,
                {
                    alpha: 0,
                    ease: SlowMo.easeInOut,
                },
                "-=.3"
            );
    },
    destroyed() {
        this.setProjectsFilter("");
        this.setProjectsSubFilter("");
    },
    data() {
        return {
            listCounts: 8,
            listStart: 0,
            listStep: 8,
            allProjectsLoaded: false,
        };
    },
    methods: {
        ...mapMutations(["setProjectsFilter", "setProjectsSubFilter"]),

        setProjectlistLoader(callback) {
            this.listCounts = 8;
            let { projectContainer } = this.$refs;

            projectContainerTimeline.to(projectContainer, 0.6, {
                alpha: 0,
                ease: SlowMo.easeInOut,
                onComplete() {
                    callback();
                },
            });
        },

        loadMoreProjects() {
            this.listCounts += this.listStep;

            if (this.listCounts > this.getFilteredProjects.length) {
                this.allProjectsLoaded = true;
            }
        },

        // прогрузка при скролле
        loadProjectScroll() {
            window.addEventListener("scroll", () => {
                if (
                    document.body.scrollHeight -
                        window.innerHeight -
                        window.scrollY <=
                    200
                ) {
                    this.loadMoreProjects();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
