<template>
    <div id="scroller" class="scroller">

        <div class="scroller__button" @click="handleClick" ref="scrollerButton"> 
            <img src="@/assets/img/icons/scroll--dark.svg" alt="" class="scroller__button-icon scroller__button-icon--dark">
            <img src="@/assets/img/icons/scroll.svg" alt="" class="scroller__button-icon scroller__button-icon--light">
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex"

    import {
        TimelineMax,
        Power3,
    } from 'gsap'

    let scrollerTl = new TimelineMax()

    export default {
        name:'SectionScroller',
        
        props:{
            handleClick:{
                type:Function,
                required:false,
            }
        },
        computed:{
            ...mapGetters([
                'getScrolling',
                'isLastSection',
                'getAppTheme',
            ]),
        },
        watch:{
            getScrolling:function(val){

                if(val){
                    scrollerTl.reverse()
                } else if(!val&&!this.isLastSection){
                    scrollerTl.play()
                }
            }
        },
        mounted(){
            let {scrollerButton} = this.$refs

            scrollerTl
                .from(scrollerButton, .6, {
                    scale:0,
                    yPercent:100,
                    alpha:0,
                    ease:Power3.easeInOut,
                })
        },

    }
</script>

<style lang="scss" scoped>
    .scroller{
        position: fixed;
        bottom: 37px;
        left:0;
        right: 0;

        margin-left: auto;
        margin-right: auto;

        width: 100%;
        height: auto;

        display:flex;
        flex-direction:row;
        justify-content:center;
        align-items:center;

        z-index: 98;

        overflow: hidden;

        padding-top: 5px;
        padding-bottom: 5px;

        &__button{
            position: relative;

            width: auto;
            height: auto;

            cursor: pointer;

            &-icon{
                width: 65px;
                height: 65px;
                object-fit: cover;
            }
        }

        &__button:hover, &__button:focus{
            transform: scale(1.1);
        }
    }

    @media (max-width: 1600.98px) {
        .scroller{

            &__button{

                &-icon{
                    width: 40px;
                    height: 40px;
                }
            }
            
        }
    }

     @media (max-width: 414.98px) {
        .scroller{
            bottom: 0px;

            &__button{

                &-icon{
                    width: 30px;
                    height: 30px;
                }
            }
            
        }
    }

</style>