<template>
    <div>
        <transition name="fade" mode="out-in">
            <router-view :key="$route.path"></router-view>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "Projects",
    computed: {
        ...mapGetters([
            "getCurrentLanguage",
            "getProjectFilters",
            "getProjectSubFilters",
        ]),
    },
    async mounted() {
        this.shouldFetch ? await this.fetchProjects() : "";

        if (this.$route.query.filter) {
            await this.setProjectsFilter({
                tag: this.$route.query.filter,
            });
            if (this.$route.query.subFilter) {
                await this.setProjectsSubFilter({
                    tag: this.$route.query.subFilter,
                });
            }
        }

        this.setMetaInfo({
            page: "projectlist",
        });
    },
    watch: {
        getCurrentLanguage: async function () {
            await this.fetchProjects();
        },
        $route(to, from) {
            if (from.name !== "projectsDetailroute1") {
                this.shouldFetch = false;
            }
        },
    },
    data() {
        return {
            shouldFetch: true,
        };
    },
    methods: {
        ...mapActions(["fetchProjects"]),

        ...mapMutations([
            "setMetaInfo",
            "setProjectsFilter",
            "setProjectsSubFilter",
        ]),
    },
};
</script>

<style lang="scss" scoped></style>
