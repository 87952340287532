<template>
    <router-link class="pitem" :to="'/projects/' + project.routeName + '/'">
        <div class="pitem">
            <!-- @mouseover="setVideo" @mouseleave="hideVideo" -->
            <!-- <video
      class="pitem__image"
      webkit-playsinline
      playsinline
      muted
      loop
      ref="video"
    >
      <source v-if="project.video2" :src="project.video2" type="video/webm" />
      <source v-if="project.video1" :src="project.video1" type="video/mp4" />
    </video> -->

            <img :src="project.src" alt="" class="pitem__video" ref="image" />

            <div class="pitem__overlay" ref="overlay"></div>

            <div class="pitem__textwrap" ref="textwrap">
                <p class="pitem__text" v-html="project.title"></p>
                <p class="pitem__tag" v-html="project.tag"></p>
            </div>
            <!-- <div class="tabs-details">
              <ul class="tabs-uls">
                  <li v-for="tab in project.tags" :key="tab.title">
                      <a :href="tab.href">{{ tab.NAME }}</a>
                  </li>
              </ul>
          </div> -->
        </div>
    </router-link>
</template>

<script>
import { TimelineMax, TweenMax, Power3 } from "gsap";

export default {
    name: "ProjectsItem",
    props: {
        project: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    mounted() {
        // let { overlay, textwrap } = this.$refs;
        // TweenMax.set(overlay, {
        //     alpha: 0,
        // });
        // TweenMax.set(textwrap, {
        //     alpha: 0,
        // });
    },
    updated() {
        this.$refs.video.load();
    },
    methods: {
        setVideo() {
            let { image, overlay, textwrap } = this.$refs;

            let timeline = new TimelineMax();

            timeline
                .to(image, 1, {
                    alpha: 1,
                    ease: Power3.easeInOut,
                })
                .to(
                    overlay,
                    1,
                    {
                        alpha: 1,
                        ease: Power3.easeInOut,
                    },
                    "-=1"
                )
                .to(
                    textwrap,
                    1,
                    {
                        alpha: 1,
                        ease: Power3.easeInOut,
                    },
                    "-=1"
                )
                .to(overlay, 1, {
                    delay: 1,
                    alpha: 0,
                    ease: Power3.easeInOut,
                })
                .to(
                    textwrap,
                    1,
                    {
                        alpha: 0,
                        ease: Power3.easeInOut,
                    },
                    "-=1"
                );

            // video.play();
        },

        hideVideo() {
            let { image, overlay, textwrap } = this.$refs;

            let timeline = new TimelineMax();

            timeline.to(image, 1, {
                alpha: 1,
                ease: Power3.easeInOut,
            });

            TweenMax.to(overlay, 1, {
                alpha: 0,
                ease: Power3.easeInOut,
            });

            TweenMax.to(textwrap, 1, {
                alpha: 0,
                ease: Power3.easeInOut,
            });

            // video.pause();
        },
    },
};
</script>

<style lang="scss" scoped></style>
