<template>
    <div class="team">

        <div class="container">
            <div class="row">
                <div class="col-10 col-start-2 col-sm-12">

                    <div class="app__titlewrap">
                        <h1 class="app__title"  v-html="getTeamInfo.title"></h1>
                    </div>

                    <div class="app__pretitlewrap">
                        <p class="app__pretitle"  v-html="getTeamInfo.des"></p>
                    </div>

                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="team__list">
                        <team-person v-for="(person, index) in getTeamMembers" :key="index" :info="person" />                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>   
    import {mapGetters,mapMutations, mapActions} from 'vuex'
    
    import TeamPerson from "./components/TeamPerson"

    export default {
        name:'Team',
        components:{
            TeamPerson,
        },
        computed:{
            ...mapGetters([
                'getTeamMembers',
                'getTeamInfo',
                'getCurrentLanguage',
            ])
        },
        mounted(){
            this.fetchTeam()

            this.setMetaInfo({
                page:'team'
            })
        },
        watch:{
            getCurrentLanguage:function(){
                this.fetchTeam()
            }
        },
        methods:{
            ...mapActions([
                'fetchTeam'
            ]),

            ...mapMutations([
                'setMetaInfo'
            ])
        },
    }
</script>

<style lang="scss" scoped>

</style>