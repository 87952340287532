<template>
    <div class="reportblock" id="reportmain">
        
        <div class="container">
            <div class="row">
                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">

                    <div class="reportblock__header">
                        <Title :text=" $t('messages.mainpage.report1') " class="reportblock__title" />
                    </div>

                    <div class="reportblock__list">
                        <div class="reportblock__item" v-for="(item,index) in data" :key="index">
                            <img v-lazy="item.ICON" alt="" class="reportblock__item-icon">
                            <p class="reportblock__item-icontext" v-html="item.ICON_TEXT"></p>
                            <p class="reportblock__item-count" v-html="item.COUNT"></p>
                            <p class="reportblock__item-text" v-html="item.TEXT"></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Title from './Title'

    export default {
        name:'ReportBlock',
        components:{
            Title,
        },
        props:{
            light:{
                type:Boolean,
                required:false,
                default:false,
            },

            data:{
                type:Array,
                required:false,
                default:()=>[]
            }
        },
    }
</script>

<style lang="scss" scoped>
    

    @media (max-width: 1600.98px) {

    }

     @media (max-width: 1460.98px){

    }

    @media (max-width: 768.98px){

    }

    @media (max-width: 480.98px) {

    }
</style>