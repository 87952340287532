<template>
	<div class="rental">
		<div class="container">
			<div class="row">
				<div class="col-start-2 col-10 col-xs-start-1 col-xs-12">
					<div class="app__titlewrap">
						<h1 class="app__title">Аренда оборудования</h1>
					</div>

					<div class="app__pretitlewrap">
						<p class="app__pretitle">
							Black & White Production — один из лидеров
							видеопроизводства в Республике Казахстан. Мы
							обладаем собственным профессиональным
							кинооборудованием и готовы к сотрудничеству с
							профессионалами, которым необходима техника для
							съемок.
						</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-start-2 col-10">
					<div class="rental__des">
						<p class="rental__text">
							Запрос прайс-листа и особых условий по контактам:
						</p>
						<p
							class="rental__text"
							style="display: flex; align-items: center"
						>
							<img
								src="@/assets/img/pages/rental/telegram.webp"
								alt="Telegram"
								class="footer__social-icon"
							/>
							<img
								src="@/assets/img/pages/rental/whatsapp.svg"
								alt="Telegram"
								class="footer__social-icon"
								style="margin-right: 10px"
							/>
							<!-- Телефон / WhatsApp / Telegram  -->
							+7 (778) 234-12-95 (Тлек Каналин)
						</p>
						<p class="rental__text">E-mail: sales@bw.com.kz</p>
						<p class="rental__text">
							Пишите / звоните, обсудим все детали! Мы на связи
							24/7
						</p>
						<p class="rental__text">
							Вы может оценить «картинку», снятую на наше
							кинооборудование, в шоуриле Black & White
							Production:
						</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="rental__videowrap">
						<div
							style="
								padding: 56.25% 0 0 0;
								position: relative;
								width: 100%;
								height: auto;
							"
						>
							<iframe
								:src="'https://player.vimeo.com/video/668154669?title=0&byline=0&portrait=0'"
								style="
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
								"
								frameborder="0"
								allow="autoplay; fullscreen"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="rental__sliderwrap">
			<slider :dots="true" :elements="slider" />
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">
								Видеокамеры и коптеры:
							</h2>
							<ul>
								<li class="rental__block-text">
									• Камера Canon C100
								</li>
								<li class="rental__block-text">
									• Камера Mark IV
								</li>
								<li class="rental__block-text">
									• Камера RED Epic M-X
								</li>
								<li class="rental__block-text">
									• Камера Blackmagic DP 6K PRO
								</li>
								<li class="rental__block-text">
									• Экшн-камера Go-Pro Hero IV
								</li>
								<li class="rental__block-text">
									• Экшнкамера Go-Pro Hero 7
								</li>
								<li class="rental__block-text">
									• Радиофокус Nucleus M
								</li>
								<li class="rental__block-text">
									• Видеосендер HollyLand Cosmo 600
								</li>
								<li class="rental__block-text">
									• Видеосендер HollyLand MARS 400 S
								</li>
								<li class="rental__block-text">
									• Коптер DJI Mavic 2 Pro
								</li>
								<li class="rental__block-text">
									• Пульт дистанционный для DJI Mavic 2 Pro
								</li>
								<li class="rental__block-text">
									• Коптер DJI Mavic 2 ZOOM
								</li>
								<li class="rental__block-text">
									• Пульт дистанционный для DJI Mavic 2 ZOOM
								</li>
								<li class="rental__block-text">
									• Insta360 one X2 Steady Cam
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="pteam">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="pteam__persons-item-title">
								Объективы, линзы, фильтры:
							</h2>
							<ul>
								<li class="pteam__persons-item-text">
									• Линза SigmaCine 18-35mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза SigmaCIne 85mm
								</li>
								<li class="pteam__persons-item-text">
									• Venus Optics Laowa 12mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза Canon 24-105мм
								</li>
								<li class="pteam__persons-item-text">
									• Линза Canon 16-35мм
								</li>
								<li class="pteam__persons-item-text">
									• Линза Canon 70-200 mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза Canon 50 mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза Rokinon 24mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза Rokinon 35mm
								</li>
								<li class="pteam__persons-item-text">
									• Линза Rokinon 85mm
								</li>
								<li class="pteam__persons-item-text">
									• Rokinon 24mm T1.5
								</li>
								<li class="pteam__persons-item-text">
									• Rokinon 35mm T1.5
								</li>
								<li class="pteam__persons-item-text">
									• Rokinon 50mm T1.5
								</li>
								<li class="pteam__persons-item-text">
									• Rokinon 85mm T1.5
								</li>
								<li class="pteam__persons-item-text">
									• Линза 18-55 mm
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр ND H&Y
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр KSM C-POL MRC nano 77 mm XS-pro
									Digital
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр В +W 82 486 UV IR CUT MRC
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр В +W 77 486 UV IR CUT MRC
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen ND 0.3 (4х5.65)
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen ND 0.6 (4х5.65)
								</li>
								<li class="pteam__persons-item-text">
									• Кольца 72мм, 77мм, 82мм
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen ND 1.2 (4х5.65)
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen IR (4х5.65)
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen POL 1.2 (4х5.65)
								</li>
								<li class="pteam__persons-item-text">
									• Суфлер
								</li>
								<li class="pteam__persons-item-text">
									• Макрокольцо 58 мм
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen Black ProMist ¼ 82mm
								</li>
								<li class="pteam__persons-item-text">
									• Фильтр Tiffen Black ProMist 1/8 82mm
								</li>
								<li class="pteam__persons-item-text">
									• H&Y Filters RevoRing Variable ND3-ND1000
								</li>
								<li class="pteam__persons-item-text">
									• Насадка для фильтров Tiffen Pro100 series
								</li>
								<li class="pteam__persons-item-text">
									• 82mm Adapter Ring for Pro100 Series Camera
								</li>
								<li class="pteam__persons-item-text">
									• 58-82mm Adapter Ring for Pro100 Series
									Camera
								</li>
								<li class="pteam__persons-item-text">
									• 72-82mm Adapter Ring for Pro100 Series
									Camera
								</li>
								<li class="pteam__persons-item-text">
									• 77-82mm Adapter Ring for Pro100 Series
									Camera
								</li>
								<li class="pteam__persons-item-text">
									• Pro 77-82 mm Aluminum Step-up ring
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">
								Световое оборудование:
							</h2>
							<ul>
								<li class="rental__block-text">• KinoFLO</li>
								<li class="rental__block-text">
									• Dedolight 150
								</li>
								<li class="rental__block-text">
									• Dedolight Soft Box
								</li>
								<li class="rental__block-text">• ARRI 650 W</li>
								<li class="rental__block-text">
									• Falconeyes RX-24TDX Rolex Led light
								</li>
								<li class="rental__block-text">
									• Falcon Eyes RX-24TDX II
								</li>
								<li class="rental__block-text">• Nanlite</li>
								<li class="rental__block-text">
									• Aputure MC (комплект)
								</li>
								<li class="rental__block-text">
									• Aputure Nova P300cB50:B80 Kit
								</li>
								<li class="rental__block-text">
									• Aputure 600
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pteam">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="pteam__persons-item-title">Мониторы:</h2>
							<ul>
								<li class="pteam__persons-item-text">
									• Монитор для камеры RED 7.0
								</li>
								<li class="pteam__persons-item-text">
									• FeelWorld
								</li>
								<li class="pteam__persons-item-text">
									• Liliput 28"
								</li>
								<li class="pteam__persons-item-text">
									• Монитор накамерный SmallHD 702 Touch 7
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">
								Штативы и стабилизирующие устройства:
							</h2>
							<ul>
								<li class="rental__block-text">
									• Штатив E-Image
								</li>
								<li class="rental__block-text">
									• Штатив Libec
								</li>
								<li class="rental__block-text">
									• Штатив Benro BV10
								</li>
								<li class="rental__block-text">
									• Штатив Insta360 Bullet time Bundle
								</li>
								<li class="rental__block-text">
									• Штатив Oben TT-100 + магниттый шт
								</li>
								<li class="rental__block-text">
									• Steadicam AIR 25 Monopod
								</li>
								<li class="rental__block-text">
									• Zhiyun Crane 2 + кейс
								</li>
								<li class="rental__block-text">
									• Zhiyun Crane 3s
								</li>
								<li class="rental__block-text">
									• DanaDolly + кейс
								</li>
								<li class="rental__block-text">
									• DanaDolly трубы (звеньев)
								</li>
								<li class="rental__block-text">
									• Easyrig жилет (в сумке)
								</li>
								<li class="rental__block-text">
									• GLIDECAM VISTA TRACK 10-48
								</li>
								<li class="rental__block-text"></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pteam">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="pteam__persons-item-title">
								Звуковое оборудование:
							</h2>
							<ul>
								<li class="pteam__persons-item-text">
									• Zoom Recorder H4h
								</li>
								<li class="pteam__persons-item-text">
									• Zoom H6 All Black 6-Input
								</li>
								<li class="pteam__persons-item-text">
									• Радиопетличный микрофон Sennheiser G3
								</li>
								<li class="pteam__persons-item-text">
									• Наушники Sennheiser HD 280 PRO
								</li>
								<li class="pteam__persons-item-text">
									• Проводной петличный микрофон
								</li>
								<li class="pteam__persons-item-text">
									• Радиопетличный микрофон Sennheiser G4
								</li>
								<li class="pteam__persons-item-text">
									• Поролоновые чехлы для RODE
								</li>
								<li class="pteam__persons-item-text">
									• Рации WLN
								</li>
								<li class="pteam__persons-item-text">
									• Рации HYT
								</li>
								<li class="pteam__persons-item-text">
									• Наушники для раций WLN
								</li>
								<li class="pteam__persons-item-text">
									• Наушники для раций HYT
								</li>
								<li class="pteam__persons-item-text">
									• Удленитель для наушников (мама-папа
									minijack)
								</li>
								<li class="pteam__persons-item-text">
									• Провод от петлички XLR
								</li>
								<li class="pteam__persons-item-text">
									• Провод от петлички minijack
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">Батарейки:</h2>
							<ul>
								<li class="rental__block-text">• Switronix</li>
								<li class="rental__block-text">
									• Батарейка для камеры Canon LP-E6
								</li>
								<li class="rental__block-text">
									• Батарейки аккумуляторные «Eneloop»
								</li>
								<li class="rental__block-text">
									• Батарейки 18650
								</li>
								<li class="rental__block-text">
									• Батарейки для DJI Ronin
								</li>
								<li class="rental__block-text">
									• Батарейки для Canon C-100
								</li>
								<li class="rental__block-text">
									• Батарейка Canon LP-E8
								</li>
								<li class="rental__block-text">
									• Батарейки Insta360 ONE X2
								</li>
								<li class="rental__block-text">
									• Площадка V-mount
								</li>
								<li class="rental__block-text">
									• Батарейки NP-F750-4
								</li>
								<li class="rental__block-text">
									• Батарейки NP-F960 F
								</li>
								<li class="rental__block-text">
									• Батарейки Jupo
								</li>
								<li class="rental__block-text">
									• Батарейки Falcon V-mount
								</li>
								<li class="rental__block-text">
									• Батарейки DJI Mavic
								</li>
								<li class="rental__block-text">
									• IndiPRO Tools Micro-Series V-Mount Li-Ion
									Battery
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pteam">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="pteam__persons-item-title">
								Зарядные устройства:
							</h2>
							<ul>
								<li class="pteam__persons-item-text">
									• Зарядное устройство для батареек Switronix
									Pro-X
								</li>
								<li class="pteam__persons-item-text">
									• Зарядное устройство TILTA Max для больших
									батареек(18650)
								</li>
								<li class="pteam__persons-item-text">
									• Зарядное устройство для квадрокоптера
								</li>
								<li class="pteam__persons-item-text">
									• Зарядное устройство Panasonic для обычных
									батареек
								</li>
								<li class="pteam__persons-item-text">
									• Зарядка для Canon 5D Марк IV
								</li>
								<li class="pteam__persons-item-text">
									• IndiPRO Tools Quad Pro Battery Charger
									(V-Mount)
								</li>
								<li class="pteam__persons-item-text">
									• Зарядка для камеры Canon C100
								</li>
								<li class="pteam__persons-item-text">
									• Зарядка для раций HYT
								</li>
								<li class="pteam__persons-item-text">
									• Зарядка для раций HYT
								</li>
								<li class="pteam__persons-item-text">
									• Зарядное устройство (обманки)
								</li>
								<li class="pteam__persons-item-text">
									• Зарядное устр-во для DJI Ronin
								</li>
								<li class="pteam__persons-item-text">
									• Зарядный хаб для батарей DJI Mavic 2 Pro
								</li>
								<li class="pteam__persons-item-text">
									• Автомобильное зарядное устройство Mavic 2
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">Флеш-карты:</h2>
							<ul>
								<li class="rental__block-text">
									• Флэш-карта SD 64Gb
								</li>
								<li class="rental__block-text">
									• Карта памяти SSD 240 GB – 2 шт. (для
									камеры RED)
								</li>
								<li class="rental__block-text">
									• Флеш-карты microSD 128 GB
								</li>
								<li class="rental__block-text">
									• Флеш-карты microSD 64 GB
								</li>
								<li class="rental__block-text">
									• Card-Reader – Red mini-mag
								</li>
								<li class="rental__block-text">
									• Карта памяти SanDisk Extreme CF 64Gb
								</li>
								<li class="rental__block-text">
									• Карта памяти SanDisk Extreme CF 128Gb
								</li>
								<li class="rental__block-text">
									• Внешний жесткий диск Samsung 1TB T5
									Portable SSD
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="pteam">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="pteam__persons-item-title">
								Обвес для камер:
							</h2>
							<ul>
								<li class="pteam__persons-item-text">
									• Компендиум LanParte
								</li>
								<li class="pteam__persons-item-text">
									• Переходник UMB -80mm
								</li>
								<li class="pteam__persons-item-text">
									• Переходник UMB -87mm
								</li>
								<li class="pteam__persons-item-text">
									• Переходник UMB -95mm
								</li>
								<li class="pteam__persons-item-text">
									• Magic Arm Tilta
								</li>
								<li class="pteam__persons-item-text">
									• Magic Arm Noga
								</li>
								<li class="pteam__persons-item-text">
									• Голова Noga
								</li>
								<li class="pteam__persons-item-text">
									• Плечевой Риг с суппортой 15 мм
								</li>
								<li class="pteam__persons-item-text">
									• Плечевой Риг с суппортой 19 мм
								</li>
								<li class="pteam__persons-item-text">
									• Суппорта 19 мм
								</li>
								<li class="pteam__persons-item-text">
									• LanParte (переходник для Magic Arm) MQR-01
								</li>
								<li class="pteam__persons-item-text">
									• Надплечник LanParte
								</li>
								<li class="pteam__persons-item-text">
									• Шторики для компендиума LanParte (верхняя)
								</li>
								<li class="pteam__persons-item-text">
									• Шторики для компендиума LanParte (боковые)
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="rental__block">
			<div class="container">
				<div class="row">
					<div class="col-start-2 col-10">
						<div class="">
							<h2 class="rental__block-title">
								Дополнительное оборудование:
							</h2>
							<ul>
								<li class="rental__block-text">
									• Extension 25835
								</li>
								<li class="rental__block-text">
									• Grip Head Avenger
								</li>
								<li class="rental__block-text">
									• Стойки Visico
								</li>
								<li class="rental__block-text">
									• Флаги(черные)
								</li>
								<li class="rental__block-text">
									• Крепеж Matthellini
								</li>
								<li class="rental__block-text">
									• Super Clamp Manfrotto
								</li>
								<li class="rental__block-text">
									• Super Clamp Kupo
								</li>
								<li class="rental__block-text">• Катушки</li>
								<li class="rental__block-text">
									• Отражатель Лайтдиск
								</li>
								<li class="rental__block-text">
									• Отражатель пенка
								</li>
								<li class="rental__block-text">
									• Перекладина
								</li>
								<li class="rental__block-text">
									• Стойки для фона
								</li>
								<li class="rental__block-text">• Хлопушка</li>
								<li class="rental__block-text">
									• Color-checker
								</li>
								<li class="rental__block-text">• Syrp</li>
								<li class="rental__block-text">
									• Фон тканевый муслин (белый)
								</li>
								<li class="rental__block-text">
									• Фон тканевый PRO (черный, серый)
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <transition name="fade"> -->
		<transition name="fade">
			<div v-if="initMap">
				<contacts-form />
			</div>
			<div v-else class="mainpage__lazy"></div>
		</transition>
	</div>
</template>

<script>
	import { mapMutations, mapGetters } from "vuex";

	import ContactsForm from "../contacts/components/ContactsForm";
	import Slider from "../../components/common/Slider";

	export default {
		name: "Rental",
		components: {
			Slider,
			ContactsForm,
		},
		computed: {
			...mapGetters(["getCurrentLanguage"]),
		},
		mounted() {
			// this.fetchAboutInformation();
			setTimeout(() => {
				console.log("inittingmap");
				this.initMap = true;
			}, 5000);
			this.setMetaInfo({
				page: "rental",
			});
		},
		watch: {
			getCurrentLanguage: function () {
				this.fetchAboutInformation();
			},
		},
		data() {
			return {
				slider: [
					{ src: require("@/assets/img/pages/rental/red.jpg") },
					{ src: require("@/assets/img/pages/rental/red2.jpg") },
					{ src: require("@/assets/img/pages/rental/red3.jpg") },
					{ src: require("@/assets/img/pages/rental/red4.jpg") },
					{ src: require("@/assets/img/pages/rental/red5.jpg") },
					{ src: require("@/assets/img/pages/rental/red6.jpg") },
					{ src: require("@/assets/img/pages/rental/red7.jpg") },
				],
				initMap: false,
			};
		},
		methods: {
			...mapMutations(["setMetaInfo"]),

			// ...mapActions(["fetchAboutInformation"]),

			connectCompany() {
				this.$router.push("/contacts/");
			},
		},
	};
</script>

<style lang="scss" scoped>
	.about__title {
		margin: 20px 0;
	}
</style>