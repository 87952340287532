const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleAbout = {
  state: {
    info: {},
  },
  mutations: {},
  actions: {
    fetchAboutInformation({ state, rootGetters }) {
      let locale = rootGetters.getCurrentLanguageKey;

      axios
        .post("https://api.blackandwhite.kz/bw/about/?lang=" + locale)
        .then((response) => {
          return response.data.about[0];
        })
        .then((content) => {
          let {
            TITLE: title,
            TEXT: text,
            SHOWREEL: video,
            PHOTO,
            CLIENTS = false,
          } = content;

          let slider = Object.values(PHOTO).map((item) => {
            return { src: item };
          });

          let clients;
          if (CLIENTS) {
            clients = CLIENTS.map((item) => {
              let {
                IMAGE_SRC: src,
                IMAGE_BLACK_SRC: srcDark,
                TITLE: title,
                IMAGE_COLOR_SRC: srcColored,
                LINK: link,
              } = item;

              return {
                src,
                srcDark,
                title,
                srcColored,
                link,
              };
            });
          }

          let obj = {
            title,
            // Тут идет дополнительная обработка текста
            headerText: text.block1.split("<br>/<br>/"),
            mainText: text.block2.split("<br>/<br>/"),

            // Со стороны сервера ответ приходит без приставки https:// , так что необходимо добавлять себе отдельно
            video: video.replace("https://", ""),
            slider,
            clients,
          };

          state.info = obj;
        });
    },
  },
  getters: {
    getAboutInfo(state) {
      return state.info;
    },
  },
};

export default moduleAbout;
