<template>
	<div class="seo">
		<section class="black">
			<div class="container">
				<div class="seo_about seo_section">
					<div class="container_mini">
						<div class="app__titlewrap">
							<h1 class="seo_title_h1">Корпоративный фильм</h1>
						</div>
						<div class="seo_text">
							Корпоративный фильм — это фильм о компании, который
							снимают для укрепления положительного, делового
							имиджа компании и её представления целевой
							аудитории.
						</div>
						<div class="seo_text">
							Компания Black & White Production является одним из
							лидеров в производстве корпоративных фильмов в
							Республике Казахстан. С примерами наших работ можно
							ознакомиться в
							<a
								href="https://bw.com.kz/projects/"
								class="seo_text"
								style="text-decoration: underline"
								target="_blank"
								>портфолио.</a
							>
						</div>
						<div class="seo_text">
							Корпоративный фильм требует большого
							профессионализма сценариста, режиссёра и всей
							съемочной команды, поскольку это «большой жанр» как
							по хронометражу, так и по объему информации,
							количеству героев и мотивационному потенциалу их
							историй.
						</div>
						<div class="seo_text">
							Можно сказать, что корпоративный фильм — это аналог
							документального фильма в корпоративном секторе, где
							главными героями истории выступают компания и её
							сотрудники. Поэтому хорошему корпоративному фильму
							свойственны принципы драматургии хорошего кино. Всё
							это нацелено на то, чтобы удержать внимание и
							интерес аудитории.
						</div>
					</div>
					<div class="app__titlewrap">
						<h2 class="seo_title">
							Лучшие корпоративные фильмы Black & White Production
						</h2>
					</div>
					<div class="seo_about_slider">
						<div class="swiper-wrapper">
							<div
								class="swiper-slide shadow"
								v-for="(item, index) in sliderImages"
								:key="index"
							>
								<div class="seo_about_slider_item">
									<img :src="item.src" alt="" />
									<div class="container_mini">
										<div class="seo_about_slider_content">
											<div
												class="seo_about_slider_number"
											>
												{{ index + 1 }}/{{
													sliderImages.length
												}}
											</div>
											<div class="seo_about_slider_title">
												{{ item.title }}
											</div>
											<div class="seo_text">
												{{ item.text }}
											</div>
											<a
												:href="item.link"
												target="_black"
												class="seo_button"
											>
												Узнать больше
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="swiper-button-next">
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M40 19.9998C40 8.95437 31.0456 0 20.0002 0C8.95524 0 1.48616e-06 8.95424 1.48616e-06 19.9995C1.48616e-06 31.0448 8.95524 40 20.0002 40C31.0456 40 40 31.0454 40 19.9998ZM14.6944 29.0244C13.9655 28.2951 13.9651 27.1127 14.6944 26.3831L21.1303 19.9485L14.6616 13.4789C13.9327 12.75 13.9323 11.5672 14.6616 10.8376C15.0268 10.4734 15.5044 10.2912 15.9822 10.2912C16.4601 10.2912 16.9377 10.4734 17.3018 10.8382L24.808 18.3438C24.9198 18.4157 25.0263 18.4981 25.1237 18.5955C25.4965 18.9683 25.676 19.459 25.6673 19.9483C25.6756 20.4366 25.4965 20.9281 25.1233 21.3005C25.0262 21.3983 24.9196 21.4803 24.808 21.5519L17.3357 29.0243C16.6064 29.7534 15.424 29.7539 14.6944 29.0244Z" fill="white"/>
							</svg>
						</div>
						<div class="swiper-button-prev">
							<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 20.0012C0 31.0467 8.95444 40.001 19.9998 40.001C31.0448 40.001 40 31.0468 40 20.0015C40 8.95621 31.0448 0.00103982 19.9998 0.00103982C8.95444 0.00103982 0 8.95568 0 20.0012ZM25.3056 10.9766C26.0345 11.7059 26.0349 12.8883 25.3056 13.6179L18.8697 20.0526L25.3384 26.5221C26.0673 27.2511 26.0677 28.4339 25.3384 29.1635C24.9732 29.5276 24.4956 29.7099 24.0178 29.7099C23.5399 29.7099 23.0623 29.5276 22.6982 29.1628L15.192 21.6572C15.0802 21.5854 14.9737 21.503 14.8763 21.4055C14.5035 21.0327 14.324 20.542 14.3327 20.0527C14.3244 19.5644 14.5035 19.073 14.8767 18.7006C14.9738 18.6027 15.0804 18.5207 15.192 18.4491L22.6643 10.9767C23.3936 10.2477 24.576 10.2471 25.3056 10.9766Z" fill="white"/>
							</svg>
						</div>
					</div>
				</div>
				<div class="seo_benefits seo_section">
					<div class="container_mini">
						<h2 class="seo_title">
							Особенности драматургии корпоративного фильма
						</h2>
						<div class="seo_text">
							Зачастую компании предпочитают говорить в своих
							корпоративных фильмах только о положительных
							моментах, достижениях и успехах. Однако не менее
							важно рассказать о трудностях, с которыми компания
							столкнулась на своём пути. В этом преодолении
							препятствий на пути к успеху заключается главный
							принцип драматургии корпоративного фильма. Таким
							образом, история компании вызывает эмоциональный
							отклик и чувство сопереживания у зрителя.
						</div>
					</div>
				</div>
				<contacts-block @onformsend="sendContactsForm" />
			</div>
		</section>
		<section class="white">
			<div class="container">
				<div class="seo_benefits seo_section">
					<div class="container_mini">
						<h2 class="seo_title">Особенности формата</h2>
					</div>
					<!-- <div class="seo_benefits_tabs">
						<div class="seo_benefits_tabs_menu">
							<div
								class="seo_benefits_tabs_menu_item"
								v-for="(item, index) in tabsMenu"
								:key="index"
								v-html="item.tabsMenuItem"
								@click="selectService(index)"
								:class="
									index == selectedService ? 'active' : ''
								"
							></div>
						</div>
						<div class="seo_benefits_tabs_content">
							<div
								class="seo_benefits_tabs_item"
								v-for="(item, index) in tabsItem"
								:key="index"
								:class="
									index == selectedService ? 'show' : 'hide'
								"
							>
								<div
									class="seo_text"
									v-for="(itemText, index) in item"
									:key="index"
									v-html="itemText"
								></div>
							</div>
						</div>
					</div> -->
					<div class="seo_benefits_tabs_menu_item bold">Цели корпоративного фильма:</div>
					<div class="seo_benefits_tabs_item">
						<div class="seo_text">Корпоративный фильм снимают для:</div>
						<div class="seo_text">1. Укрепления положительного делового имиджа компании.</div>
						<div class="seo_text">2. Презентации компании внешней аудитории.</div>
						<div class="seo_text">3. Демонстрации коллективу в целях укрепления корпоративной культуры.</div>
						<div class="seo_text">4. Зачастую корпоративные фильмы создают по случаю какой-либо важной даты, например, юбилея.</div>
					</div>
					<div class="seo_benefits_tabs_menu_item bold">Характеристики:</div>
					<div class="seo_benefits_tabs_item">
						<div class="seo_text">1. Хронометраж корпоративного мини-фильма: 5-7 минут.</div>
						<div class="seo_text">2. Хронометраж корпоративного фильма: от 10 минут и выше.</div>
						<div class="seo_text">3. Наличие истории компании и выстроенная драматургия для удержания внимания аудитории.</div>
					</div>
					<div class="seo_benefits_tabs_menu_item bold">Преимущества и выгоды (какие задачи решает):</div>
					<div class="seo_benefits_tabs_item">
						<div class="seo_text">1. Демонстрирует профессионализм, масштаб и опыт компании.</div>
						<div class="seo_text">2. Укрепляет положительную деловую репутацию и имидж компании.</div>
						<div class="seo_text">3. Наглядно и подробно рассказывает о компании, её истории, ценностях, инновациях и преимуществах сотрудничества.</div>
						<div class="seo_text">4. Погружает зрителя в корпоративную культуру компании, вызывая эмпатию.</div>
						<div class="seo_text">5. Рассказывает истории героев, мотивирующие к ответственному труду и профессиональному росту.</div>
						<div class="seo_text">6. Продвигает компанию на рынке и в медиасреде и способствует привлечению новых клиентов.</div>
						<div class="seo_text">7. Вызывает доверие у клиентов, сотрудников, партнеров, инвесторов.</div>
					</div>
					<div class="seo_benefits_tabs_menu_item bold">Кому подходит:</div>
					<div class="seo_benefits_tabs_item">
						<div class="seo_text">1. Компаниям с большой историей и богатым опытом.</div>
						<div class="seo_text">2. Компаниям, которые заботятся о своём имидже и стратегическом (долгосрочном) присутствии на рынке.</div>
						<div class="seo_text">3. Компаниям, которые уделяют большое внимание устойчивому развитию и социальной ответственности.</div>
					</div>
					<div class="seo_benefits_buttons">
						<a href="/contacts" target="_blank" class="seo_button bold">Обсудить проект</a>
						<a href="/contacts" target="_blank" class="seo_button bold">Узнать стоимость</a>
					</div>
				</div>
			</div>
		</section>
		<section class="black">
			<div class="container">
				<div class="seo_experience seo_section">
					<div class="container_mini">
						<h2 class="seo_title">Опыт Black & White Production</h2>
						<div class="seo_text">
							У компании Black & White Production большой опыт
							создания корпоративных фильмов для таких
							казахстанских компаний как АО «НК «КазМунайГаз», АО
							«Казахтелеком», Евразийская Группа (ERG), крупная
							электротехническая компания Alageum Electriс,
							Федерация тенниса Казахстана.
						</div>
					</div>
					<div class="container">
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider11.jpg"
									alt=""
								/>
							</div>
							<div>
								<a href="/projects/gazoprovod-saryarka/"
									><h3 class="seo_films_title">
										«Проект «Сарыарка»
									</h3></a
								>

								<div class="seo_text">
									Корпоративный фильм о строительстве
									магистрального газопровода. В 2019 году в
									центральном и северном регионах Казахстана
									начали строительство магистрального
									газопровода «Сарыарка». Очевидцы этого
									масштабного события рассказывают о каждом
									этапе важного и сложного проекта для
									Казахстана.
								</div>
								<a
									href="/projects/gazoprovod-saryarka/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider21.jpg"
									alt=""
								/>
							</div>
							<div>
								<a href="/projects/privychka-truditsya/"
									><h3 class="seo_films_title">
										«Привычка трудиться»
									</h3></a
								>

								<div class="seo_text">
									Корпоративный фильм к 25-летию Евразийской
									Группы «Привычка трудиться» — это дань
									уважения старшему поколению — ветеранам и
									работникам заводов Казахстана, которые
									преодолевали тяжелые условия труда в 90-е
									годы, работали не покладая рук, добывали
									руду и уголь, плавили металл, вели поезда и
									делали все возможное для развития страны.
								</div>
								<a
									href="/projects/privychka-truditsya/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider31.jpg"
									alt=""
								/>
							</div>
							<div>
								<a href="/projects/bolshaya-igra/"
									><h3 class="seo_films_title">
										«Большая игра»
									</h3></a
								>

								<div class="seo_text">
									«Большая игра» — это фильм-воспоминание о
									том, как зародился большой теннис в
									Казахстане и как удалось сделать его
									популярным видом спорта. Основные съёмки
									проходили в Нур-Султане, были записаны
									интервью в Москве и Кортрейке (Бельгия).
								</div>
								<a
									href="/projects/bolshaya-igra/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider41.jpg"
									alt=""
								/>
							</div>
							<div>
								<a href="/projects/zavodskie-nastroyki/"
									><h3 class="seo_films_title">
										«Заводские настройки»
									</h3></a
								>

								<div class="seo_text">
									Корпоративный фильм «Заводские настройки»
									рассказывает о сотрудниках Кентауского
									трансформаторного завода. Предприятие
									взрастило не одно поколение горожан,
									воспитало в работниках профессионалов и
									патриотов своего дела. Заводские настройки —
									это совокупность необходимых качеств и
									действий для правильной работы устройства.
								</div>
								<a
									href="/projects/zavodskie-nastroyki/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider51.jpg"
									alt=""
								/>
							</div>
							<div>
								<a href="/projects/erg-vmeste-k-vershinam/"
									><h3 class="seo_films_title">
										«ERG: вместе к вершинам»
									</h3></a
								>

								<div class="seo_text">
									Корпоративный фильм «ERG: вместе к вершинам»
									о крупнейших предприятиях Евразийской
									Группы, на которых трудятся десятки тысяч
									людей. Надежные, профессиональные, и, самое
									важное, активные сотрудники — главное
									богатство и достояние ERG. Люди, которые
									вместе создают будущее Казахстана
									эффективной и слаженной работой.
								</div>
								<a
									href="/projects/erg-vmeste-k-vershinam/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
						<div class="seo_films">
							<div class="seo_films_img">
								<img
									src="@/assets/img/pages/films/slider61.jpg"
									alt=""
								/>
							</div>
							<div>
								<a
									href="/projects/kazakhtelekom-vmeste-v-novyy-den/"
									><h3 class="seo_films_title">
										«Казахтелеком: вместе в новый день»
									</h3></a
								>

								<div class="seo_text">
									Корпоративный фильм «Казахтелеком: вместе в
									новый день» рассказывает об истории
									крупнейшей телекоммуникационной компании
									Республики Казахстан. История АО
									«Казахтелеком» — это история развития
									Казахстана. Были удачи и небольшие промахи,
									но компании всегда удавалось идти в ногу со
									временем и учитывать интересы своих
									клиентов.
								</div>
								<a
									href="/projects/kazakhtelekom-vmeste-v-novyy-den/"
									target="_black"
									class="seo_button"
									style="float: right; margin-right: 10px"
								>
									Узнать больше
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="white">
			<div class="container">
				<div class="seo_create seo_section">
					<div class="container_mini">
						<h3 class="seo_title">
							Как создаются корпоративные фильмы?
						</h3>
						<!-- <div class="seo_text">
							Для создания корпоративного фильма необходим
							профессиональный подход, фантазия, креатив. Идея
							должна быть сильной, при этом необходимо
							демонстрировать решение проблемы, выход из ситуации:
							корпоративный фильм должен отвечать на вопрос «Что
							делать, чтобы решить проблему?».
						</div> -->
					</div>

					<div class="seo_create_section">
						<div class="container_mini">
							<div class="seo_mini_title">1. Pre-Production</div>
						</div>
						<div class="seo_create_slider">
							<div class="swiper-wrapper">
								<div
									class="swiper-slide"
									v-for="(item, index) in preProduction"
									:key="index"
								>
									<img :src="item.src" alt="" />
								</div>
							</div>
						</div>
						<div class="container_mini">
							<div class="seo_text">
								Первый этап — сбор информации о компании. Клиент
								заполняет бриф и предоставляет информацию о
								компании, её истории, производстве. Мы проводим
								глубинные интервью с руководителями и
								ответственными лицами. Определяются цели
								корпоративного фильма, его тематические линии,
								истории действующих лиц.
							</div>
							<div class="seo_text">
								На этапе предпродакшена создается концепция,
								разрабатывается сценарий, утверждается смета
								проекта. После создания сценария определяется
								стоимость производства корпоративного фильма.
							</div>
						</div>
					</div>

					<div class="seo_create_section">
						<div class="container_mini">
							<div class="seo_mini_title">2. Production</div>
						</div>
						<div class="seo_create_slider">
							<div class="swiper-wrapper">
								<div
									class="swiper-slide"
									v-for="(item, index) in production"
									:key="index"
								>
									<img :src="item.src" alt="" />
								</div>
							</div>
						</div>
						<div class="container_mini">
							<div class="seo_text">
								Этап, на котором осуществляется съемка фильма.
								Может занимать от нескольких дней для небольших
								компаний до нескольких месяцев, если необходимо
								снять длительный производственный цикл,
								например, строительство завода.
							</div>
						</div>
					</div>

					<div class="seo_create_section">
						<div class="container_mini">
							<div class="seo_mini_title">3. Post-Production</div>
						</div>
						<div class="seo_create_slider">
							<div class="swiper-wrapper">
								<div
									class="swiper-slide"
									v-for="(item, index) in postProduction"
									:key="index"
								>
									<img :src="item.src" alt="" />
								</div>
							</div>
						</div>
						<div class="container_mini">
							<div class="seo_text">
								Постпродакшн — завершающий этап создания
								корпоративного фильма. Проводится видеомонтаж,
								моушн-дизайн, создается инфографика,
								осуществляется озвучивание и цветокоррекция.
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="black">
			<div class="container">
				<div class="seo_section">
					<div class="container_mini">
						<h4 class="seo_title">Как подготовиться к съемке?</h4>
						<div class="seo_text">
							На этапе подготовки к съемкам продюсер и режиссёр
							объяснят ответственным лицам со стороны заказчика,
							какие локации будут снимать, нужно ли подготовить
							что-то из оборудования, кто из персонала будет
							участвовать в съемке. Важно заранее подготовить
							список участников, согласовать их действия с
							режиссёром, до начала съемочного процесса рассказать
							им о нюансах.
						</div>
						<div class="seo_text">Даже самые
							незначительные недоработки могут привести к задержке
							съёмок, что сказывается на бюджете проекта, поэтому
							мы уделяем большое внимание подготовительному
							периоду, а наш богатый опыт работы в корпоративном
							секторе позволяет предусмотреть различные
							«непредвиденные» обстоятельства и приготовиться к
							ним заранее.
						</div>
					</div>
				</div>

				<div class="seo_price seo_section">
					<div class="container_mini">
						<h4 class="seo_title">
							Стоимость создания корпоративного фильма
						</h4>
						<div class="seo_text">
							Стоимость создания корпоративного фильма зависит от
							нескольких переменных, которые напрямую влияют на
							трудозатраты:
						</div>
						<div class="seo_text">
							<ul>
								<li>
									Сложность съемочного процесса (логистика,
									количество локаций в разных городах).
								</li>
								<li>
									Количество занятых актеров и персонала в
									съемках.
								</li>
								<li>
									Сложность и объем графики и визуальных
									эффектов.
								</li>
								<li>Хронометраж.</li>
								<li>Сроки производства.</li>
							</ul>
						</div>
						<div class="seo_text">
							Главным образом, цена создания корпоративного фильма
							напрямую зависит от концепции и сценария, поэтому
							более точную оценку по Вашему проекту возможно
							получить после консультации со специалистами Black &
							White Production.
						</div>
						<div>
							<a
								href="/contacts"
								target="_blank"
								class="seo_button bold"
								>Узнать</a
							>
						</div>
					</div>
				</div>

				<div class="seo_section">
					<div class="container_mini">
						<h4 class="seo_title">
							Почему важно заказать корпоративный фильм у Black &
							White Production?
						</h4>
					</div>
					<div class="seo_privilege">
						<div class="seo_privilege_item">
							<div class="seo_mini_title">1. Опыт</div>
							<div class="seo_text">
								Компания Black & White Production является
								лидером корпоративного видеопроизводства в
								Казахстане. В этой сфере компания успешно
								работает с 2013 года.
							</div>
						</div>
						<div class="seo_privilege_item">
							<div class="seo_mini_title">2. Профессионализм</div>
							<div class="seo_text">
								Мы дорожим своей репутацией и работаем только с
								лучшими специалистами — настоящими
								профессионалами, многие из которых работают в
								кинопроизводстве.
							</div>
						</div>
						<div class="seo_privilege_item">
							<div class="seo_mini_title">
								3. Погружение в тему
							</div>
							<div class="seo_text">
								Мы убеждены, что фундамент хорошего
								корпоративного фильма – это полное погружение в
								деятельность компании для того, чтобы создать
								интересную вдохновляющую историю.
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<form-block @onformsend="sendContactsForm" />
		<!-- <form-block :url="socialnyj-videorolik" :page="Социальный_видеоролик" @onformsend="sendContactsForm" /> -->

		<div class="seo_modal">
			<div class="seo_modal-wrap" v-if="modalOpened">
				<iframe
					:src="
						'https://player.vimeo.com/video/396916739' +
						'?title=0&byline=0&portrait=0&autoplay=1'
					"
					style="
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					"
					class="seo_modal-video"
					frameborder="0"
					allow="autoplay;fullscreen"
					allowfullscreen
				>
				</iframe>
				<img
					src="@/assets/img/icons/close.svg"
					alt=""
					class="seo_modal-close"
					@click="closeModal"
				/>
			</div>
		</div>
	</div>
</template>


<script>
	import { mapActions, mapMutations } from "vuex";
	import Swiper, { Navigation } from "swiper";
	import "swiper/swiper-bundle.css";
	import FormBlock from "../seopage/components/FormBlock";
	import ContactsBlock from "../seopage/components/ContactsBlock";
	// import ContactsBlock from "../mainpage/components/ContactsBlock";

	Swiper.use([Navigation]);

	export default {
		name: "films",
		components: {
			FormBlock,
			ContactsBlock,
		},
		mounted() {
			this.setMetaInfo({
				page: "films",
			});

			new Swiper(".seo_about_slider", {
				loop: true,
				spaceBetween: 30,
				slidesPerView: 1,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				breakpoints: {
					320: {
						slidesPerView: 1,
					},
					600: {
						slidesPerView: 1,
					},
				},
			});
			new Swiper(".seo_create_slider", {
				loop: false,
				spaceBetween: 30,
				slidesPerView: 3,
				breakpoints: {
					320: {
						slidesPerView: "auto",
						spaceBetween: 10,
						centeredSlides: true,
					},
					600: {
						slidesPerView: 3,
					},
				},
			});
		},
		data() {
			return {
				modalOpened: false,
				selectedService: 0,
				isactive: false,
				swiper: "",
				sliderImages: [
					{
						title: "Газопровод «Сарыарка»",
						text: "Корпоративный фильм о строительстве магистрального газопровода в Казахстане",
						link: "/projects/gazoprovod-saryarka/",
						src: require("@/assets/img/pages/films/slider11.jpg"),
						alt: "",
					},
					{
						title: "Привычка трудиться",
						text: "Корпоративный фильм к 25-летию Евразийской группы",
						link: "/projects/privychka-truditsya/",
						src: require("@/assets/img/pages/films/slider21.jpg"),
						alt: "",
					},
					{
						title: "Большая игра",
						text: "Фильм для Федерации тенниса Казахстана о том, как зародился большой теннис в Казахстане",
						link: "/projects/bolshaya-igra/",
						src: require("@/assets/img/pages/films/slider31.jpg"),
						alt: "",
					},
					{
						title: "Заводские настройки",
						text: "Корпоративный фильм о сотрудниках Кентауского трансформаторного завода",
						link: "/projects/zavodskie-nastroyki/",
						src: require("@/assets/img/pages/films/slider41.jpg"),
						alt: "",
					},
					{
						title: "ERG: вместе к вершинам",
						text: "Корпоративный фильм о крупнейших предприятиях Евразийской группы",
						link: "/projects/erg-vmeste-k-vershinam/",
						src: require("@/assets/img/pages/films/slider51.jpg"),
						alt: "",
					},
					{
						title: "Қазақтелеком: Вместе в новый день",
						text: "Корпоративный фильм об истории АО «Казахтелеком»",
						link: "/projects/kazakhtelekom-vmeste-v-novyy-den/",
						src: require("@/assets/img/pages/films/slider61.jpg"),
						alt: "",
					},
				],
				preProduction: [
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_1.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_2.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_3.png"),
					},
				],
				production: [
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_4.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_5.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_6.png"),
					},
				],
				postProduction: [
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_7.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_8.png"),
					},
					{
						src: require("@/assets/img/pages/seopages/socialpage/social_create_9.png"),
					},
				],
				tabsMenu: [
					{
						tabsMenuItem: "Цели корпоративного фильма:",
					},
					{
						tabsMenuItem: "Характеристики:",
					},
					{
						tabsMenuItem:
							"Преимущества и выгоды (какие задачи решает):",
					},
					{
						tabsMenuItem: "Кому подходит:",
					},
				],
				tabsItem: [
					{
						tabsItemText_1: "Корпоративный фильм снимают для:\n",
						tabsItemText_2:
							"1. Укрепления положительного делового имиджа компании.\n",
						tabsItemText_3:
							"2.	Презентации компании внешней аудитории.\n",
						tabsItemText_4:
							"3.	Демонстрации коллективу в целях укрепления корпоративной культуры.",
						tabsItemText_5:
							"4. Зачастую корпоративные фильмы создают по случаю какой-либо важной даты, например, юбилея.",
					},
					{
						tabsItemText_1:
							"1. Хронометраж корпоративного мини-фильма: 5-7 минут.",
						tabsItemText_2:
							"2. Хронометраж корпоративного фильма: от 10 минут и выше.",
						tabsItemText_3:
							"3. Наличие истории компании и выстроенная драматургия для удержания внимания аудитории.",
					},
					{
						tabsItemText_1:
							"1. Демонстрирует профессионализм, масштаб и опыт компании.",
						tabsItemText_2:
							"2.Укрепляет положительную деловую репутацию и имидж компании.",
						tabsItemText_3:
							"3. Наглядно и подробно рассказывает о компании, её истории, ценностях, инновациях и преимуществах сотрудничества.",
						tabsItemText_4:
							"4. Погружает зрителя в корпоративную культуру компании, вызывая эмпатию.",
						tabsItemText_5:
							"5. Рассказывает истории героев, мотивирующие к ответственному труду и профессиональному росту.",
						tabsItemText_6:
							"6. Продвигает компанию на рынке и в медиасреде и способствует привлечению новых клиентов.",
						tabsItemText_7:
							"7. Вызывает доверие у клиентов, сотрудников, партнеров, инвесторов.",
					},
					{
						tabsItemText_1:
							"1. Компаниям с большой историей и богатым опытом.",
						tabsItemText_2:
							"2. Компаниям, которые заботятся о своём имидже и стратегическом (долгосрочном) присутствии на рынке.",
						tabsItemText_3:
							"3. Компаниям, которые уделяют большое внимание устойчивому развитию и социальной ответственности.",
					},
				],
			};
		},
		methods: {
			openModal() {
				this.modalOpened = true;
			},
			closeModal() {
				this.modalOpened = false;
			},
			selectService(index) {
				this.selectedService = index;
			},
			...mapMutations(["setMetaInfo"]),

			// Форма заявки, обязательно добавлять страницу и url
			...mapActions(["sendContactsMainpage"]),
			sendContactsForm(data) {
				Object.assign(data, { pageUrl: "/korporativnyj-film" });
				Object.assign(data, { page: "Корпоравтивный фильм" });
				this.sendContactsMainpage(data);
			},
		},
	};
</script>

<style lang="scss" scoped></style>