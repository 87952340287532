var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('script',{attrs:{"type":"application/ld+json"},domProps:{"innerHTML":_vm._s(_vm.jsonld)}}),_c('div',{staticClass:"project__back",on:{"click":function($event){return _vm.$router.push({ path: '/projects/', params: { fetch: false } })}}},[_vm._m(0),_c('div',{staticClass:"project__back-textwrap"},[_c('p',{staticClass:"project__back-text"},[_vm._v(" "+_vm._s(_vm.$t("messages.projectdetail.back"))+" ")])])]),_c('div',{staticClass:"container container--mobilenopad"},[_c('div',{staticClass:"row row--mobilepad"},[_c('div',{staticClass:"col-start-2 col-10"},[_c('div',{staticClass:"project__titlewrap project__titlewrap--mb"},[_c('h1',{staticClass:"project__title",domProps:{"innerHTML":_vm._s(_vm.getProjectInfo.title)}})]),_c('div',{staticClass:"project__textwrap",domProps:{"innerHTML":_vm._s(_vm.getProjectInfo.headerText)}})])]),(_vm.getProjectInfo.video)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"project__imagewrap"},[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative","width":"100%","height":"auto"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":'https://player.vimeo.com/video/' +
                                _vm.getProjectInfo.video +
                                '?title=0&byline=0&portrait=0',"frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])])])]):_vm._e(),_c('div',{staticClass:"row row--mobilepad"},[_c('div',{staticClass:"col-start-2 col-10"},[_c('div',{staticClass:"project__textwrap",domProps:{"innerHTML":_vm._s(_vm.getProjectInfo.mainText)}})])]),_vm._m(1)]),(_vm.getProjectInfo.siteInfo)?_c('div',{staticClass:"project__sitewrap"},[(_vm.getProjectInfo.siteInfo.hasSite)?_c('project-site-block',{attrs:{"info":_vm.getProjectInfo.siteInfo}}):_vm._e()],1):_vm._e(),(_vm.getProjectInfo.slider)?_c('div',{staticClass:"project__slider"},[_c('slider',{attrs:{"elements":_vm.getProjectInfo.slider,"dots":true}})],1):_vm._e(),_c('div',{staticClass:"container container--mobilenopad project__content"},[_c('div',{staticClass:"row row--mobilepad"},[_c('div',{staticClass:"col-start-2 col-10"},[_c('div',{staticClass:"project__textwrap",domProps:{"innerHTML":_vm._s(_vm.getProjectInfo.footerText)}})])]),(_vm.getProjectInfo.backstage)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"project__imagewrap"},[_c('div',{staticStyle:{"padding":"56.25% 0 0 0","position":"relative","width":"100%","height":"auto"}},[_c('iframe',{staticStyle:{"position":"absolute","top":"0","left":"0","width":"100%","height":"100%"},attrs:{"src":'https://player.vimeo.com/video/' +
                                _vm.getProjectInfo.backstage +
                                '?title=0&byline=0&portrait=0',"frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":""}})])])])]):_vm._e(),(_vm.getProjectInfo.rewards)?_c('div',{staticClass:"row row--mobilepad"},[(_vm.getProjectInfo.rewards.length > 0)?_c('div',{staticClass:"col-start-2 col-10"},[_c('div',{staticClass:"project__titlewrap"},[_c('h2',{staticClass:"project__title",domProps:{"innerHTML":_vm._s(_vm.$t('messages.projectdetail.awards'))}})]),_c('div',{staticClass:"project__awards"},_vm._l((_vm.getProjectInfo.rewards),function(item,index){return _c('project-award',{key:index,attrs:{"info":item}})}),1)]):_vm._e()]):_vm._e()]),(_vm.getProjectInfo.infolist)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.getProjectInfo.infolist.length > 0)?_c('div',{staticClass:"project__info"},_vm._l((_vm.getProjectInfo.infolist),function(item,index){return _c('div',{key:index,class:'project__info-item ' +
                            (!item.image
                                ? 'project__info-item--noimage'
                                : '')},[(item.image)?_c('div',{staticClass:"project__info-item-imagewrap"},[_c('img',{staticClass:"project__info-item-image",attrs:{"src":item.image,"alt":""}})]):_vm._e(),(item.image)?_c('div',{staticClass:"project__info-item-textwrap"},[_c('p',{staticClass:"project__info-item-text"},[_c('span',[_vm._v(_vm._s(item.info.value))]),_vm._v(_vm._s(item.info.text)+" ")])]):_c('div',{staticClass:"project__info-item-textwrap"},[_c('p',{staticClass:"project__info-item-text"},[_c('span',[_vm._v(_vm._s(item.info.value))])]),_c('p',{staticClass:"project__info-item-text"},[_vm._v(" "+_vm._s(item.info.text)+" ")])])])}),0):_vm._e()])])]):_vm._e(),(_vm.getProjectInfo.persons)?_c('div',{staticClass:"project__team"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-start-2 col-10"},[_c('project-team',{attrs:{"persons":_vm.getProjectInfo.persons,"info":_vm.getProjectInfo.info}})],1)])])]):_vm._e(),(_vm.getProjectInfo.hasSimilar)?_c('div',{staticClass:"project__similar"},[_c('div',{staticClass:"container container--overvisible"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-start-2 col-10",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"project__titlewrap project__titlewrap--mb"},[_c('h2',{staticClass:"project__title",domProps:{"innerHTML":_vm._s(_vm.$t('messages.projectdetail.similar'))}})]),_c('div',{staticClass:"project__similar-list"},[_c('project-similar',{attrs:{"elements":_vm.getProjectInfo.similar}})],1)])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project__back-iconwrap"},[_c('img',{staticClass:"project__back-icon project__back-icon--light",attrs:{"src":require("@/assets/img/icons/arrow-double.svg"),"alt":""}}),_c('img',{staticClass:"project__back-icon project__back-icon--dark",attrs:{"src":require("@/assets/img/icons/arrow-double--dark.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 col-start-2"})])}]

export { render, staticRenderFns }