<template>
    <div :class="'themeswitcher '+ (getAppTheme?'themeswitcher--light':'themeswitcher--dark')"
         @click="this.toggleLang"
         >

        <div class="themeswitcher__button">
            <p class="themeswitcher__button-text">{{lang}}</p>
        </div>
        
    </div>
</template>

<script>
    import {
        mapGetters, 
        mapMutations
    } from 'vuex'

    export default {
        name:'AppThemeSwitcher',
        mounted(){
            // проверка на время дня, и в зависимости от этого выставления темы сайта
            let timeDay = new Date().getHours()
            
            if (!localStorage.isLight) {
                if (timeDay >= 18 ) {
                    this.isLight = false
                } else if (timeDay < 6) {
                    this.isLight = false
                } else {
                    this.isLight = true
                }
            }
            this.setAppTheme(this.isLight)

            this.lang = this.getAppTheme?'W':'B'
            this.isLight = this.getAppTheme?true:false
        },
        computed:{
            ...mapGetters([
                'getAppTheme',
                'getFirstCome',
            ]),
        },
        data(){
            return{
                isLight:false,
                lang:'',
            }
        },
        methods:{
            ...mapMutations(['setAppTheme','setFirstCome',]),

            toggleLang(){
                this.isLight =!this.isLight;
                this.setAppTheme(this.isLight)

                this.lang = this.getAppTheme?'W':'B'

                localStorage.isLight = true
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>