const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleMenu = {
    state: {
        menuItems: [],
        currentPath: "",
        currentName: "",
        menuOpened: false,
        headerMenu: [
            {
                route: "/",
                class: "",
                textLink: "messages.header.menu0",
            },
            {
                route: "/services/",
                class: "",
                textLink: "messages.header.menu1",
            },
            {
                route: "/projects/",
                class: "",
                textLink: "messages.header.menu2",
            },
            {
                route: "/vacancies/",
                class: "",
                textLink: "messages.header.menu3",
            },
            {
                route: "/castings/",
                class: "",
                textLink: "messages.header.menu5",
            },
            {
                route: "/specialprojects/about-us/",
                class: "header__menu-item",
                textLink: "messages.header.menu4",
            },
        ],
    },
    mutations: {
        setCurrentPage(state, info) {
            state.currentPath = info.path;
            state.currentName = info.name;

            state.menuItems.forEach((item) => {
                if (item.name == info.name) {
                    item.isactive = true;
                } else {
                    item.isactive = false;
                }
            });

            state.menuOpened = false;
        },

        toggleMenu(state) {
            state.menuOpened = !state.menuOpened;
        },
    },
    actions: {
        async fetchMenu({ state, rootGetters }) {
            let locale = rootGetters.getCurrentLanguageKey;

            await axios
                .post("https://api.blackandwhite.kz/bw/menu.php?lang=" + locale)
                .then((response) => {
                    return response.data.menuItems;
                })
                .then((menuItems) => {
                    let arr = menuItems.map((item) => {
                        let { title, name, public: actived, path } = item;

                        return {
                            title,
                            name,
                            public: actived,
                            path,
                            isactive: false,
                        };
                    });

                    let sortedArr = arr.sort((a, b) => {
                        return parseInt(a.SORT) - parseInt(b.SORT);
                    });

                    state.menuItems = sortedArr;
                });
        },
    },
    getters: {
        getMenuItems(state) {
            return state.menuItems.filter((item) => item.public);
        },

        menuStatus(state) {
            return state.menuOpened;
        },

        getHeaderMenu(state) {
            return state.headerMenu;
        },
    },
};

export default moduleMenu;
