<template>
    <div class="cookiepage">

        <div class="container">
            <div class="row">
                <div class="col-start-2 col-10 col-xs-start-1 col-xs-12">

                    <div class="cookiepage__title">
                        <h1 class="cookiepage__title-text">{{$t('messages.cookie.title1')}}</h1>
                    </div>

                    <div class="cookiepage__des">
                        <p class="cookiepage__des-text">{{$t('messages.cookie.text1')}}</p>
                    </div>

                    <div class="cookiepage__title">
                        <h1 class="cookiepage__title-text">{{$t('messages.cookie.title2')}}</h1>
                    </div>

                    <div class="cookiepage__des">
                        <p class="cookiepage__des-text">{{$t('messages.cookie.text2')}}</p>
                    </div>

                    <div class="cookiepage__title">
                        <h1 class="cookiepage__title-text">{{$t('messages.cookie.title3')}}</h1>
                    </div>

                    <div class="cookiepage__des">
                        <p class="cookiepage__des-text">{{$t('messages.cookie.text3')}}</p>
                    </div>

                    <div class="cookiepage__title">
                        <h1 class="cookiepage__title-text">{{$t('messages.cookie.title4')}}</h1>
                    </div>

                    <div class="cookiepage__des">
                        <p class="cookiepage__des-text">{{$t('messages.cookie.text4')}}</p>
                    </div>

                    <div class="cookiepage__des">
                        <p class="cookiepage__des-text">{{$t('messages.cookie.text5')}}</p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'CookiePage'
    }
</script>

<style lang="scss" scoped>

</style>