<template>
    <div class="contactsblock" id="contactsmain">
        <div class="container">
            <div class="row">
                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">
                    <div class="contactsblock__header">
                        <p class="contactsblock__title">
                            Вы может получить готовое техническое задание на
                            производство корпоративного фильма,
                            <span
                                class="
                                    contactsblock__title
                                    contactsblock__title_bold
                                "
                                >сделав нам запрос</span
                            >
                        </p>
                        <!-- <p class="contactsblock__title contactsblock__title_bold"></p> -->
                    </div>

                    <div class="contactsblock__success" v-if="sended">
                        <p
                            class="
                                contactsblock__title contactsblock__title_bold
                            "
                        >
                            {{ $t("messages.mainpage.contacts3") }}
                        </p>
                    </div>

                    <div class="contactsblock__content" v-else>
                        <input
                            type="text"
                            :placeholder="$t('messages.mainpage.contacts6')"
                            class="contactsblock__form"
                            v-model="name"
                        />
                        <input
                            type="text"
                            :placeholder="$t('messages.mainpage.contacts7')"
                            class="contactsblock__form"
                            v-model="email"
                        />
                        <input
                            type="text"
                            :placeholder="$t('messages.mainpage.contacts8')"
                            class="contactsblock__form"
                            v-model="phone"
                            v-mask="'+# ### ###-##-##'"
                        />
                        <button class="contactsblock__submit" @click="sendForm">
                            {{ $t("messages.mainpage.contacts4") }}
                        </button>
                    </div>

                    <p
                        class="contactsblock__error"
                        v-if="error.status"
                        v-html="error.text"
                    ></p>

                    <p class="contactsblock__tip" v-if="!sended">
                        {{ $t("messages.mainpage.contacts5") }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
    name: "ContactsBlock",
    mixins: [validationMixin],
    validations: {
        name: {
            required,
        },
        phone: {
            required,
            minLength: minLength(6),
        },
        email: {
            required,
            email,
        },
    },
    data() {
        return {
            sended: false,

            error: {
                status: false,
                text: "",
            },

            name: "",
            email: "",
            phone: "",
        };
    },
    methods: {
        sendForm() {
            if (
                !this.$v.name.required ||
                !this.$v.phone.required ||
                !this.$v.email.required
            ) {
                this.error.status = true;
                this.error.text = this.$t("messages.contacts.error0");
            } else if (!this.$v.phone.minLength) {
                this.error.status = true;
                this.error.text = this.$t("messages.contacts.error3");
            } else if (!this.$v.email.email) {
                this.error.status = true;
                this.error.text = this.$t("messages.contacts.error4");
            } else {
                this.error = {
                    status: false,
                    text: "",
                };

                this.$emit("onformsend", {
                    name: this.name,
                    email: this.email,
                    phone: this.phone,
                });
                this.sended = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
