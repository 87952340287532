<template>
    <div class="psite">

        <div class="psite__content">

            <a :href="info.url" class="psite__link" rel="nofollow" target="_blank" v-html="info.url"></a>

            <div class="psite__imagewrap">
                <a :href="info.url" target="_blank" rel="nofollow" class="psite__imagewrap-link">
                    <img :src="info.image" alt="" class="psite__image">
                </a>
            </div>

            <div class="psite__textwrap">
                <p class="psite__text" v-html="info.text"></p>
            </div>

        </div>
        
    </div>
</template>

<script>
    export default {
        name:'ProjectSiteBlock',
        props:{
            info:{
                type:Object,
                required:false,
                default:()=>{}
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>