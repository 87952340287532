<template>
    <div
        id="app"
        :class="$route.name + ' ' + (getAppTheme ? 'light-app' : 'dark-app')"
    >
        <head>
            <script v-html="jsonld1" type="application/ld+json"></script>
            <script v-html="jsonld" type="application/ld+json"></script>

            <!-- Facebook Pixel Code -->
            <noscript
                ><img
                    height="1"
                    width="1"
                    style="display: none"
                    src="https://www.facebook.com/tr?id=607380539882192&ev=PageView&noscript=1"
            /></noscript>
            <!-- End Facebook Pixel Code -->
        </head>

        <app-header />

        <app-menu />

        <div class="app__container">
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>

        <app-footer />

        <app-cookie />
        <app-top-scroller />
        <app-loader />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import AppHeader from "./components/layout/AppHeader";
import AppFooter from "./components/layout/AppFooter";
import AppMenu from "./components/layout/AppMenu";
import AppTopScroller from "./components/layout/AppTopScroller";
import AppLoader from "./components/layout/AppLoader";
import AppCookie from "./components/layout/AppCookie";

import utils from "./helpers/utils/utils";

export default {
    name: "App",
    components: {
        AppHeader,
        AppFooter,
        AppMenu,
        AppTopScroller,
        AppLoader,
        AppCookie,
    },
    computed: {
        ...mapGetters([
            "getAppTheme",
            "getCurrentLanguage",
            "getCurrentLanguageKey",

            // getter-ы для мета
            "getMetaTitle",
            "getMetaArray",
            "getMetaLink",
        ]),
    },
    data() {
        const jsonld = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            url: "https://bw.com.kz",
            priceRange: "$$$$",
            name: "Black & Whitе — это профессиональное производство видеороликов и фильмов.Мы любим и умеем делать документальные и корпоративные фильмы, имиджевые, социальные, инфографические и анимационные видеоролики.",
            logo: "https://bw.com.kz/social.jpg",
            image: [
                "https://bw.com.kz/img/9.a1e6edcf.jpg",
                "https://bw.com.kz/img/9.a1e6edcf.jpg",
            ],
            sameAs: [
                "https://www.facebook.com/BlackWhiteGroup/",
                "https://www.instagram.com/bw_videoproduction",
                "https://vimeo.com/blackandwhiteproduction",
            ],
            currenciesAccepted: "KZT",
            paymentAccepted:
                "Cash, Credit Card, Local Exchange Tradings System",
            hasMap: "https://www.google.com/maps/place/Black+%26+White/@51.1384334,71.4583602,15z/data=!4m5!3m4!1s0x0:0x2c47e05491b8af54!8m2!3d51.1384334!4d71.4583602?hl=ru",
            "@id": "https://bw.com.kz/contacts/",
            addressLocality: "Нур-Султан",
            postalCode: "01000",
            address: "Нур-Султан ул. Токпанова, 10, блок 3, этаж 2",
            streetAddress: "ул. Токпанова, 10, блок 3, этаж 2",
            telephone: "+7(7172) 79-30-88",
            email: "sales@bw.com.kz",
            openingHoursSpecification: [
                {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                    ],
                    opens: "09:00",
                    closes: "18:00",
                },
                {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: ["Saturday", "Sunday"],
                    opens: "09:00",
                    closes: "18:00",
                },
            ],
        };
        const jsonld1 = {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
                {
                    "@type": "ListItem",
                    position: 1,
                    item: {
                        "@id": "https://bw.com.kz/",
                        name: "Главная",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 2,
                    item: {
                        "@id": "https://bw.com.kz/projects/",
                        name: "Проекты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 3,
                    item: {
                        "@id": "https://bw.com.kz/specialprojects/",
                        name: "Спецпроекты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 4,
                    item: {
                        "@id": "https://bw.com.kz/specialprojects/about-us/",
                        name: "О нас",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 5,
                    item: {
                        "@id": "https://bw.com.kz/projects/",
                        name: "Проекты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 6,
                    item: {
                        "@id": "https://bw.com.kz/news/",
                        name: "Новости",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 7,
                    item: {
                        "@id": "https://bw.com.kz/about/",
                        name: "Команда проекта",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 8,
                    item: {
                        "@id": "https://bw.com.kz/contacts/",
                        name: "Контакты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 9,
                    item: {
                        "@id": "https://bw.com.kz/clients/",
                        name: " Клиенты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 10,
                    item: {
                        "@id": "https://bw.com.kz/services/",
                        name: "Сервисы",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 11,
                    item: {
                        "@id": "https://bw.com.kz/vacancies/",
                        name: "Вакансии",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 12,
                    item: {
                        "@id": "https://bw.com.kz/awards/",
                        name: "Награды",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 13,
                    item: {
                        "@id": "https://bw.com.kz/conceptions/",
                        name: "Концепты",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 14,
                    item: {
                        "@id": "https://bw.com.kz/cookies/",
                        name: "Куки",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 15,
                    item: {
                        "@id": "https://bw.com.kz/404/",
                        name: "Ошибка 404",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 16,
                    item: {
                        "@id": "https://bw.com.kz/castings/",
                        name: "Заявка на кастинг",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 17,
                    item: {
                        "@id": "https://bw.com.kz/korporativnyj-film/",
                        name: "Корпоративные фильмы",
                    },
                },
                {
                    "@type": "ListItem",
                    position: 18,
                    item: {
                        "@id": "https://bw.com.kz/imidzhevyj-videorolik/",
                        name: "Имиджевый видеоролик",
                    },
                },
            ],
        };
        return {
            jsonld,
            jsonld1,
        };
    },

    watch: {
        $route(to) {
            // Данные ухищрения делаются, так как на главной странице используется fullpage  и на других страницах необходимо обратно включать скорлл
            // Так же в зависимости от этого шапка и подвал обычно становятся фиксированными либо нормальными
            // TODO: стереть или не стереть? Вообще кажется, что функция какая то бесполезная после всех изменений
            this.setFooterParams({
                fixed: false,
                show: true,
            });
            this.setTotopParams(false);
            this.switchOffIsmain(false);

            this.setCurrentPage({
                path: to.path,
                name: to.name,
            });
        },

        getCurrentLanguage: function () {
            document
                .querySelector("html")
                .setAttribute("lang", this.getCurrentLanguageKey);
            this.fetchMenu();
        },
    },
    mounted() {
        this.fetchMenu();
        this.fetchContacts();
        this.fetchVacanciesLength();

        document
            .querySelector("html")
            .setAttribute("lang", this.getCurrentLanguageKey);

        if (this.getCurrentLanguageKey) {
            this.$i18n.locale = this.getCurrentLanguageKey;
        } else {
            this.setSelectedLang(this.$i18n.locale);
        }

        // TODO: настроить Facebook Pixel
        /* this.$analytics.fbq.event('track', {
			content_name: 'PageView'
        }) */

        // Определение того, является ли это мобильным устройством и дальнешая запись в state менеджер
        let isMobile = utils.mobileCheck();
        this.setMobileStatus(isMobile);

        /* if(window.innerWidth>600){
			// Какой то модуль для добавления чата в приложение
			this.setLocalTracker(window,document,'https://cdn-ru.bitrix24.kz/b4550717/crm/tag/call.tracker.js')
			this.setLocalVidget(window,document,'https://cdn-ru.bitrix24.kz/b4550717/crm/site_button/loader_4_yozw5j.js')
		} */
        let pixelScript = document.createElement("script");
        pixelScript.innerHTML = `!(function (f, b, e, v, n, t, s) {
                    if (f.fbq) return;
                    n = f.fbq = function () {
                        n.callMethod
                            ? n.callMethod.apply(n, arguments)
                            : n.queue.push(arguments);
                    };
                    if (!f._fbq) f._fbq = n;
                    n.push = n;
                    n.loaded = !0;
                    n.version = "2.0";
                    n.queue = [];
                    t = b.createElement(e);
                    t.async = !0;
                    t.src = v;
                    s = b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t, s);
                })(
                    window,
                    document,
                    "script",
                    "https://connect.facebook.net/en_US/fbevents.js"
                );
                fbq("init", "607380539882192");
                fbq("track", "PageView");`;
        document.head.appendChild(pixelScript);
    },
    methods: {
        ...mapMutations([
            "setCurrentPage",
            "setSelectedLang",

            "setFooterParams",
            "setTotopParams",

            "switchOffIsmain",

            "setMobileStatus",
        ]),

        ...mapActions(["fetchMenu", "fetchContacts", "fetchVacanciesLength"]),

        setLocalTracker(w, document, url) {
            let script = document.createElement("script");
            script.async = true;
            script.src = url + "?" + ((Date.now() / 60000) | 0);
            let h = document.getElementsByTagName("script")[0];
            h.parentNode.insertBefore(script, h);
        },

        setLocalVidget(w, d, url) {
            let script = document.createElement("script");
            script.async = true;
            script.src = url + "?" + ((Date.now() / 60000) | 0);
            let h = document.getElementsByTagName("script")[0];
            h.parentNode.insertBefore(script, h);
        },
    },
    // используется модуль для мета, который должен быть использована как функция, для асинхронных изменений данных
    metaInfo() {
        return {
            title: this.getMetaTitle,
            meta: this.getMetaArray,
            link: this.getMetaLink,
        };
    },
};
</script>

<style lang="scss">
@import "./scss/main";
</style>
