<template>
    <div>
        <div class="contacts__form contacts__form_mobile" v-show="!sended">
            <p
                class="contacts__form-address"
                v-html="$t('messages.contacts.address')"
            ></p>

            <a href="tel:+77172793088" class="contacts__form-link"
                >+7 (717) 279-30-88</a
            >
            <a href="mailto:sales@bw.com.kz" class="contacts__form-link"
                >sales@bw.com.kz</a
            >

            <input
                :class="
                    'contacts__form-input' +
                    ' ' +
                    (getEmailSendStatus.errorID == 1
                        ? 'contacts__form-input_error'
                        : '')
                "
                type="text"
                :placeholder="
                    getEmailSendStatus.error && getEmailSendStatus.errorID == 1
                        ? getEmailSendStatus.errorMessage
                        : $t('messages.contacts.name')
                "
                v-model="name"
            />

            <input
                :class="
                    'contacts__form-input' +
                    ' ' +
                    (getEmailSendStatus.errorID == 2 ||
                    getEmailSendStatus.errorID == 3
                        ? 'contacts__form-input_error'
                        : '')
                "
                type="phone"
                :placeholder="
                    getEmailSendStatus.error && getEmailSendStatus.errorID == 2
                        ? getEmailSendStatus.errorMessage
                        : $t('messages.contacts.phone')
                "
                v-model="phone"
                v-mask="'+# ### ###-##-##'"
            />

            <button
                class="contacts__form-submit"
                @click="submitForm"
                v-html="$t('messages.contacts.callback')"
            ></button>
        </div>
        <transition name="fade">
            <div
                class="contacts__overlay contacts__overlay_mobile"
                v-show="success"
            ></div>
        </transition>

        <transition name="fade">
            <div
                class="contacts__notification contacts__notification_mobile"
                v-show="success"
            >
                <img
                    src="@/assets/img/icons/close-dark.svg"
                    alt=""
                    class="contacts__notification-close"
                    @click="closeNotification"
                />

                <p
                    class="contacts__notification-message"
                    v-html="$t('messages.contacts.notification')"
                ></p>
            </div>
        </transition>

        <div class="contacts__mapwrap">
            <yandex-map
                :coords="[51.138398, 71.454411]"
                :zoom="mapZoom"
                style="width: 100%; height: 100%"
                :cluster-options="{
                    1: { clusterDisableClickZoom: true },
                }"
                :behaviors="['drag']"
                :controls="['fullscreenControl', 'zoomControl']"
                map-type="map"
            >
                <ymap-marker
                    marker-id="123"
                    :coords="getMarkerData.coords"
                    :icon="getMarkerData.markerIcon"
                    @click="markerHandler"
                />
            </yandex-map>

            <div class="contacts__form contacts__form_desktop" v-show="!sended">
                <p
                    class="contacts__form-address"
                    v-html="$t('messages.contacts.address')"
                ></p>

                <a href="tel:+77172793088" class="contacts__form-link"
                    >+7 (717) 279-30-88</a
                >

                <a href="mailto:sales@bw.com.kz" class="contacts__form-link"
                    >sales@bw.com.kz</a
                >

                <input
                    :class="
                        'contacts__form-input' +
                        ' ' +
                        (getEmailSendStatus.errorID == 1
                            ? 'contacts__form-input_error'
                            : '')
                    "
                    type="text"
                    :placeholder="
                        getEmailSendStatus.error &&
                        getEmailSendStatus.errorID == 1
                            ? getEmailSendStatus.errorMessage
                            : $t('messages.contacts.name')
                    "
                    v-model="name"
                />

                <input
                    :class="
                        'contacts__form-input' +
                        ' ' +
                        (getEmailSendStatus.errorID == 2 ||
                        getEmailSendStatus.errorID == 3
                            ? 'contacts__form-input_error'
                            : '')
                    "
                    type="phone"
                    :placeholder="
                        getEmailSendStatus.error &&
                        getEmailSendStatus.errorID == 2
                            ? getEmailSendStatus.errorMessage
                            : $t('messages.contacts.phone')
                    "
                    v-model="phone"
                    v-mask="'+# ### ###-##-##'"
                />

                <button
                    class="contacts__form-submit"
                    @click="submitForm"
                    v-html="$t('messages.contacts.callback')"
                ></button>
            </div>

            <transition name="fade">
                <div
                    class="contacts__overlay contacts__overlay_desktop"
                    v-show="success"
                ></div>
            </transition>

            <transition name="fade">
                <div
                    class="
                        contacts__notification contacts__notification_desktop
                    "
                    v-show="success"
                >
                    <img
                        src="@/assets/img/icons/close-dark.svg"
                        alt=""
                        class="contacts__notification-close"
                        @click="closeNotification"
                    />

                    <p class="contacts__notification-message">
                        {{ getCallbackMessageByDate }}
                    </p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

import { yandexMap, ymapMarker } from "vue-yandex-maps";

import { validationMixin } from "vuelidate";
import {
    required,
    // email,
    minLength,
} from "vuelidate/lib/validators";

export default {
    name: "ContactsForm",
    components: {
        yandexMap,
        ymapMarker,
    },
    mixins: [validationMixin],
    validations: {
        name: {
            required,
        },
        phone: {
            required,
            minLength: minLength(6),
        },
    },
    computed: {
        ...mapGetters(["getEmailSendStatus", "getMarkerData"]),

        mapZoom() {
            let zoom = 17;

            if (window.innerWidth < 600) {
                zoom = 15;
            }

            return zoom;
        },

        getCallbackMessageByDate() {
            let notification1 = this.$t("messages.contacts.notification");
            let notification2 = this.$t("messages.contacts.notification1");
            let notification3 = this.$t("messages.contacts.notification2");

            let message = notification1;

            var d = new Date();
            let day = d.getDay();
            let hours = d.getHours();

            if (day > 0 && day < 5) {
                if (hours < 9 || hours > 18) {
                    message = notification2;
                }
            } else if (day == 5) {
                if (hours < 9) {
                    message = notification2;
                } else if (hours > 17) {
                    message = notification3;
                } else {
                    message = notification1;
                }
            } else {
                message = notification3;
            }

            return message;
        },
    },
    data() {
        return {
            name: "",
            phone: "",

            success: false,
            sended: false,
        };
    },
    methods: {
        ...mapMutations(["setFormError"]),

        ...mapActions(["sendContactsEmail"]),

        submitForm() {
            if (!this.$v.name.required) {
                this.setFormError({
                    error: true,
                    errorID: 1,
                    message: this.$t("messages.contacts.error1"),
                });
            } else if (!this.$v.phone.required) {
                this.setFormError({
                    error: true,
                    errorID: 2,
                    message: this.$t("messages.contacts.error2"),
                });
            } else if (!this.$v.phone.minLength) {
                this.setFormError({
                    error: true,
                    errorID: 3,
                    message: this.$t("messages.contacts.error3"),
                });
            } else {
                this.setFormError({
                    error: false,
                    errorID: 0,
                });

                this.sendContactsEmail({
                    name: this.name,
                    phone: this.phone,
                    subject: this.$t("messages.contacts.subject"),
                });

                this.success = true;
            }
        },

        closeNotification() {
            this.success = false;
            this.sended = true;
        },

        markerHandler() {
            window.open("https://yandex.com/maps/-/CCUMnWUnCA");
        },
    },
};
</script>

<style lang="scss" scoped></style>
