import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en/index'
import ru from './ru/index'
import kz from './kz/index'

Vue.use(VueI18n)

const messages = {
    en,
    ru,
    kz
}

const i18n = new VueI18n({
    locale: 'ru',
    messages
})

export default i18n