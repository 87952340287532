const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleTeam = {
    state:{
        title:'',
        des:'',
        teamlist:[]
    },
    mutations:{
        
    },
    actions:{
        fetchTeam({state, rootGetters}){
            let locale = rootGetters.getCurrentLanguageKey;
            axios
                // .post('https://blackandwhite.kz/api/bw/team/?k=offset&o=0&c=30&lang='+locale)
                .post('https://api.blackandwhite.kz/bw/team/?c=60&lang='+locale)
                .then((response)=>{
                    return response.data
                })
                
                .then((data)=>{
                    let {team, header} = data;
                    let {
                        TITLE:title,
                        DESCRIPTION:des,
                    } = header

                    state.title = title;
                    state.des = des;
                    
                    let arr = []

                    team.forEach((person,index) => {
                        let {
                            NAME:name,
                            SORT:sort,
                            IMG_SRC:src,
                            IMG_SRC_HOVER:srcActive,
                            TEXT:text='Ullamco non ex culpa sunt amet consequat nostrud',
                            PUBLIC:ispublic=true,
                            POSITION:position,
                            INSTAGRAM:instagram,
                            FACEBOOK:facebook,
                            LINKEDIN:linkedin,
                        } = person

                        name = name.replace(' ',' <br>')

                        let obj = {
                            index,
                            sort,
                            name,
                            text,
                            src,
                            srcActive,
                            public:ispublic,
                            position,
                            instagram,
                            facebook,
                            linkedin,
                        }

                        arr.push(obj)
                    })

                    let sortedArr = arr.sort((a,b)=>{
                        return parseInt(a.SORT) - parseInt(b.SORT)
                    })
                    state.teamlist = sortedArr.reverse();
                })
        }
    },
    getters:{
        getTeamMembers(state){
            return state.teamlist
        },
        getTeamInfo(state){
            return {
                title:state.title,
                des:state.des
            }
        }
    },
}

export default moduleTeam