<template>
    <div class="comform">
        <div class="comform__form">
            <textarea name="review"  v-model="review" placeholder="Что вы думаете об этом проекте?" class="comform__form-input"></textarea>
        </div>
        <div class="comform__btn" @click="sendReview">
            <p class="comform__btn-text">опубликовать</p>
        </div>
    </div>
</template>

<script>
    export default {
        name:'CommentForm',
        data(){
            return {
                review:''
            }
        },
        methods:{
            sendReview(){
                console.log(this.review)
            }
        }

    }
</script>

<style lang="scss" scoped>

</style>