const moduleCommon = {
    state:{
        ismain:true,

        ismobile:false,

        footer:{
            fixed:false,
            show:false,
        },

        totop:false,
        
        loaded:false,
        
        lightTheme:true,
        themeswitcher:true,
        firstCome: true,
        
        orderbtn:true,

        langmodule:true,
        selectedLangKey:'ru',
        selectedLang:'рус',
        languageToChange:'en',

        languageloader:false,
    },
    mutations:{

        switchOffIsmain(state, val){
            state.ismain = val
        },

        setFooterParams(state,payload){
            state.footer.fixed = payload.fixed
            state.footer.show = payload.show
        },

        setTotopParams(state,value){
            state.totop=value
        },
        
        setLoaded(state, val){
            state.loaded = val
        },

        setAppTheme(state, val){
            state.lightTheme = val
        },

        setFirstCome(state, val){
            state.firstCome = val
        },

        setSelectedLang(state,val){
            if(val=='en'){
                state.selectedLangKey = 'en'
                state.languageToChange='рус'
            } else if(val=='рус'){
                state.selectedLangKey = 'ru'
                state.languageToChange='en'
            }

            state.selectedLang = val
        },

        setSiteLoader(state,payload){
            state.languageloader= payload
        },

        setMobileStatus(state,payload){
            state.ismobile = payload
        }
    },
    actions:{
        
    },
    getters:{
        
        getIsMain(state){
            return state.ismain
        },

        getFooterParams(state){
            return state.footer
        },

        getTotopParams(state){
            return state.totop
        },

        getLoadedStatus(state){
            return state.loaded
        },

        getHeaderInfo(state){
            return {
                lang:state.langmodule,
                theme:state.themeswitcher,
                order:state.orderbtn
            }
        },

        getAppTheme(state){
            return state.lightTheme
        },
        
        getFirstCome(state){
            return state.firstCome
        },

        getCurrentLanguage(state){
            return state.selectedLang
        },

        getCurrentLanguageKey(state){
            return state.selectedLangKey
        },

        getLanguageToChange(state){
            return state.languageToChange
        },

        getSiteLoader(state){
            return state.languageloader;
        },

        getIsMobile(state){
            return state.ismobile
        },

    },
}

export default moduleCommon