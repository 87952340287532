<template>
  <div>
    <conceptions-desktop v-if="!isMobile" />
    <conceptions-mobile v-else />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import ConceptionsDesktop from "./Conceptions";
import ConceptionsMobile from "./ConceptionsMobile";

export default {
  name: "Conceptions",
  components: {
    ConceptionsDesktop,
    ConceptionsMobile,
  },
  mounted() {
    if (window.screen.width < 900) {
      this.isMobile = true;
    }

    this.setMetaInfo({
      page: "conceptions",
    });
  },
  destroyed() {
    // this.clearConceptions();
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    ...mapMutations(["clearConceptions", "setMetaInfo"]),
  },
};
</script>

<style lang="scss" scoped>
</style>