const axios = require("axios");
const emailjs = require("@emailjs/browser");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.get["X-Requested-With"] = "XMLHttpRequest";

const moduleContacts = {
    state: {
        title: "",
        info: [],

        emailSended: false,
        emailMessage: "",
        emailError: false,
        errorMessage: "",
        errorID: 0,

        marker: {
            coords: [51.138498, 71.458011],
            markerIcon: {
                layout: "default#imageWithContent",
                imageHref: require("@/assets/img/pages/contacts/location.png"),
                imageSize: [45, 65],
                imageOffset: [20, -60],
                content: "",
                contentOffset: [0, 15],
                contentLayout: "",
            },
        },

        persons: {
            ru: [
                {
                    src: require("@/assets/img/pages/contacts/contact-1.jpg"),
                    alt: "",
                    name: "Тлек Каналин",
                    position: "Коммерческий директор",
                    phone: "+7 778 234 12 95",
                    socials: [
                        {
                            key: "telegram",
                            link: "knlntlk",
                        },
                        {
                            key: "instagram",
                            link: "tlek_kanalin",
                        },
                    ],
                },
                // {
                //   src: require("@/assets/img/pages/contacts/contact-3.jpg"),
                //   alt: "",
                //   name: "алтынбек баянов",
                //   position:
                //     "Заместитель коммерческого директора по международным продажам",
                //   phone: "+7 747 139 36 50",
                //   socials: [
                //     {
                //       key: "telegram",
                //       link: "altynymsoll",
                //     },
                //     {
                //       key: "instagram",
                //       link: "altynymsol",
                //     },
                //   ],
                // },
            ],
            en: [
                {
                    src: require("@/assets/img/pages/contacts/contact-1.jpg"),
                    alt: "",
                    name: "Kanalin Tlek",
                    position: "Commercial Director",
                    phone: "+7 778 234 12 95",
                    socials: [
                        {
                            key: "telegram",
                            link: "knlntlk",
                        },
                        {
                            key: "instagram",
                            link: "knlntlk",
                        },
                    ],
                },
                // {
                //   src: require("@/assets/img/pages/contacts/contact-3.jpg"),
                //   alt: "",
                //   name: "Altynbek Bayanov",
                //   position: "Deputy Commercial Director for International Sales",
                //   phone: "+7 747 139 36 50",
                //   socials: [
                //     {
                //       key: "telegram",
                //       link: "altynymsolll",
                //     },
                //     {
                //       key: "instagram",
                //       link: "altynymsol",
                //     },
                //   ],
                // },
            ],
        },
    },
    mutations: {
        setFormError(state, info) {
            if (info.error) {
                state.error = true;
                state.errorID = info.errorID;
                state.errorMessage = info.message;
            } else {
                state.error = false;
                state.errorMessage = "";
            }
        },
    },
    actions: {
        async fetchContacts({ state, rootGetters }) {
            let locale = rootGetters.getCurrentLanguageKey;

            await axios
                .post(
                    "https://api.blackandwhite.kz/bw/contacts/?lang=" + locale
                )
                .then((response) => {
                    return response.data.contacts[0];
                })
                .then((content) => {
                    state.title = content.TITLE;

                    let arr = [];

                    let addressArr = content.ADDRESS.split(";");

                    arr.push([
                        {
                            type: "text",
                            value: addressArr[0],
                        },
                        {
                            type: "text",
                            value: addressArr[1],
                        },
                    ]);

                    arr.push([
                        {
                            type: "tel",
                            value: content.PHONE[0],
                        },
                        {
                            type: "tel",
                            value: content.PHONE[1],
                        },
                    ]);

                    arr.push([
                        {
                            type: "mailto",
                            value: content.MAIL,
                        },
                    ]);

                    state.info = arr;
                });
        },

        _sendContactsEmail({ state }, info) {
            // let locale = rootGetters.getCurrentLanguageKey

            let { name, phone } = info;

            axios
                .get(
                    `https://blackandwhite.bitrix24.kz/rest/106/2plxw4p38plg96sc/crm.lead.add.json?FIELDS[NAME]=${name}&FIELDS[PHONE][0][VALUE]=${phone}`
                )
                .then((response) => response.data)
                .then((data) => {
                    console.log(data);
                })
                .then((data) => {
                    state.emailSended = true;
                    state.emailMessage = data.data;
                })
                .catch((error) => {
                    state.emailError = true;
                    state.errorMessage = error;
                });
        },

        __sendContactsEmail({ state }, info) {
            // let locale = rootGetters.getCurrentLanguageKey

            // запрос генерируется в зависимости от самого скрипта, который был прописан
            // скрипт для обрабоки данной формы находится в public/sendmail.php
            let bodyFormData = new FormData();

            bodyFormData.set("full_name", info.name);
            bodyFormData.set("phone", info.phone);

            axios
                .post(
                    "https://api.blackandwhite.kz/bw/contacts/add_lead/index.php"
                )
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    state.emailSended = true;
                    state.emailMessage = data.data;
                })
                .catch((error) => {
                    state.emailError = true;
                    state.errorMessage = error;
                });
        },

        sendContactsEmail({ state }, info) {
            let { name, phone, email = "email@email.email" } = info;

            let url = `https://api.blackandwhite.kz/feedback/?full_name=${name}&phone=${phone}&message=123123&email=${email}&lang=ru`;

            axios
                .post(url, {
                    "X-Requested-With": "XMLHttpRequest",
                })
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    state.emailSended = true;
                    state.emailMessage = data.data;
                })
                .catch((error) => {
                    state.emailError = true;
                    state.errorMessage = error;
                });
        },

        sendContactsMainpage(obj = {}, info) {
            let {
                name = "",
                email = "email@email.email",
                phone = "",
            } = Object.assign(info, obj);
            // console.log("mainpage");
            // https://api.blackandwhite.kz/feedback/?full_name=Arman Test&email=karzhaubayev@mail.ru
            let url = `https://api.blackandwhite.kz/feedback/?full_name=${name}&phone=${phone}&message=123123&email=${email}&lang=ru`;
            let templateParams = {
                name,
                email,
                phone,
            };
            emailjs.init("Dyj3QrTdvm3lSAPV0");
            emailjs
                .send("service_5q2hzc9", "template_dnichqm", templateParams)
                .then(
                    function (response) {
                        console.log("SUCCESS!", response.status, response.text);
                    },
                    function (error) {
                        console.log("FAILED...", error);
                    }
                );
            axios
                .post(url, {
                    "X-Requested-With": "XMLHttpRequest",
                })
                .then((response) => {
                    return response.data.status;
                })
                .then((status) => {
                    console.log(status);
                });
        },

        sendContactsEmailTest({ state }) {
            setTimeout(() => {
                state.emailSended = true;
            }, 1000);
        },
    },
    getters: {
        getContactsTitle(state) {
            return state.title;
        },

        getContactsInfo(state) {
            return state.info;
        },

        getEmailSendStatus(state) {
            return {
                success: state.emailSended,
                error: state.error,
                errorID: state.errorID,
                errorMessage: state.errorMessage,
            };
        },

        getMarkerData(state) {
            return state.marker;
        },

        getEmailMessage(state) {
            return state.emailMessage;
        },

        getContactsPersons(state) {
            return state.persons;
        },
    },
};

export default moduleContacts;
