<template>
    <div :class="['clist', customclass]" >

        <div class="clist__wrapper" v-if=" mode=='normal' ">
        
            <a :href="partner.link" 
                target="_blank" 
                class="clist__item" 
                v-for="(partner, index) in elements" 
                :key="index"
                @mouseenter="()=>hoveredIndex=index"
                @mouseleave="()=>hoveredIndex=null"
                rel="nofollow"
                >
                <img v-if="hoveredIndex===index" :src="partner.srcColored" alt="" class="clist__item-icon">
                <img v-else :src="getAppTheme?partner.srcDark:partner.srcWhite" alt="" class="clist__item-icon">
            </a>

        </div>

        <div class="clist__wrapper" v-else-if="mode=='ref'">

            <a :href=" partner.link?partner.link:'#' " 
                target="_blank" 
                class="clist__item clist__item--moded" 
                v-for="(partner, index) in elements" 
                :key="index"
                @mouseenter="()=>hoveredIndex=index"
                @mouseleave="()=>hoveredIndex=null"
                rel="nofollow"
                >
                
                <img :src="getAppTheme?partner.srcDark:partner.srcWhite" :alt="partner.title" class="clist__item-icon">
            </a>

        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name:'Clients',
        props:{
            elements:{
                type:Array,
                required:false,
                default:()=>[]
            },
            customclass:{
                type:String,
                required:false,
                default:'',
            },
            mode:{
                type:String,
                required:false,
                default:'normal'
            }
        },
        computed:{
            ...mapGetters([
                'getAppTheme',
            ])
        },
        data(){
            return{
                hoveredIndex:null,
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>