<template>
    <div class="paward">

        <div v-if="info.hasImage" class="paward__imagewrap">
            <img :src="info.src" alt="" class="paward__image">
        </div>

        <div class="paward__textwrap">
            <p class="paward__text" v-html="info.text"></p>
        </div>

        <div class="paward__list">

            <div class="paward__nomination"
                    v-for="(nomination,index) in info.nominations"
                    :key="index"
                    >

                <div class="paward__nomination-titlewrap">
                    <div class="paward__nomination-title" v-html="nomination.title"></div>
                </div>

                <div class="paward__nomination-textwrap">
                    <div class="paward__nomination-text" v-html="nomination.text"></div>

                </div>


            </div>

        </div>


    </div>
</template>

<script>
    export default {
        name:'ProjectAward',
        props:{
            info:{
                type:Object,
                required:false,
                default:()=>{}
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>