<template>
    <div class="showreelblock" id="showreelmain">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="showreelblock__content">
                        <div class="showreelblock__descr">
                            <div class="showreelblock__logowrap">
                                <svg
                                    width="815"
                                    height="84"
                                    viewBox="0 0 815 84"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="showreelblock__logo"
                                >
                                    <g clip-path="url(#clip0)">
                                        <path
                                            d="M75.015 57.5564C75.015 48.9921 70.2618 41.6793 61.3944 39.5126C66.5928 36.3465 72.043 30.3972 72.043 22.3279C72.043 6.67493 57.406 2.47217 38.9741 2.47217H0V81.3441H39.855C61.0459 81.3441 75.015 75.1707 75.015 57.5564ZM26.7959 20.955H36.1764C41.888 20.955 47.0864 21.0577 47.0864 27.1658C47.0864 31.7421 43.2142 34.0863 37.9286 34.0863H26.7959V20.955ZM26.7959 62.9827V48.3384H35.2664C43.8144 48.3384 50.0293 48.8334 50.0293 55.7072C50.0293 61.6004 45.0535 62.908 38.4997 62.908L26.7959 62.9827Z"
                                        />
                                        <path
                                            d="M140.93 81.3347H160.601L163.651 70.9212H190.282L193.505 81.3347H220.127L190.901 2.48145H163.651L140.93 63.6177V62.9079H104.144V2.48145H77.3867V81.3347H140.93ZM177.048 26.2318L185.645 54.493H168.743L177.048 26.2318Z"
                                        />
                                        <path
                                            d="M269.198 50.8412C268.153 58.3128 264.116 64.1126 255.452 64.1126C241.841 64.1126 239.343 53.7364 239.343 42.6785C239.343 27.4551 245.006 19.7127 254.445 19.7127C262.809 19.7127 268.182 24.3824 268.966 32.5825H294.358C292.209 12.3438 278.24 0.407959 253.312 0.407959C230.466 0.407959 212.547 16.2851 212.547 41.8006C212.547 67.316 228.888 83.408 255.916 83.408C277.456 83.408 292.538 71.1452 294.919 50.7945L269.198 50.8412Z"
                                        />
                                        <path
                                            d="M322.277 56.3609L328.666 49.87L348.279 81.344H380.389L347.379 32.1436L377.224 2.48145H345.791L322.277 28.0063V2.48145H295.539V81.344H322.277V56.3609Z"
                                        />
                                        <path
                                            d="M435.792 56.5755H424.601V45.723H435.792V34.9639H424.601V15.9487H379.654V67.3719H435.801L435.792 56.5755ZM413.352 56.5755H390.903V45.723H413.362L413.352 56.5755ZM413.362 34.9639H390.903V26.7919H413.362V34.9639Z"
                                        />
                                        <path
                                            d="M474.011 81.3346L493.904 20.7493L514.233 81.3346H522.433L552.075 3.12573H542.181C538.309 13.5486 534.437 24.0742 530.477 34.5998C526.518 45.1254 522.568 55.707 518.609 66.1206L498.202 3.12573H489.945C486.779 12.7828 484.136 20.9641 481.978 27.5111C479.819 34.0581 477.96 39.7272 476.431 44.4156C474.901 49.104 473.614 53.0359 472.558 56.3608C471.503 59.6857 470.477 62.7957 469.509 65.7843L445.453 3.12573H435.569L465.579 81.3346H474.011Z"
                                        />
                                        <path
                                            d="M569.548 46.601H628.668V81.3438H637.719V3.13501H628.668V37.8685H569.548V3.13501H560.332V81.3438H569.548V46.601Z"
                                        />
                                        <path
                                            d="M655.193 3.12573H645.977V81.3346H655.193V3.12573Z"
                                        />
                                        <path
                                            d="M694.08 81.3439H703.46V11.8582H735.125V9.40187V3.12573H663.44V9.40187V11.8582H694.08V81.3439Z"
                                        />
                                        <path
                                            d="M815 75.0677V72.5554H752.589V46.601H809.569V45.3495V39.1761V37.8686H752.589V11.8581H815V9.4018V3.13501H743.373V11.8581V37.8686V46.601V72.5554V81.3345H815V75.0677Z"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0">
                                            <rect
                                                width="815"
                                                height="83"
                                                transform="translate(0 0.407959)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <h1
                                class="showreelblock__text"
                                v-html="data.text"
                            ></h1>

                            <router-link :to="{ path: '/contacts/' }">
                                <button
                                    class="showreelblock__callback"
                                    v-html="$t('messages.mainpage.main1')"
                                ></button>
                            </router-link>
                        </div>

                        <div class="showreelblock__main">
                            <video
                                class="showreelblock__main-video"
                                v-if="data.preview"
                                autoplay
                                playsinline
                                muted
                                loop
                                :poster="data.poster"
                            >
                                <source :src="data.preview" type="video/mp4" />
                            </video>
                            <img
                                v-else
                                :src="data.poster"
                                alt=""
                                class="showreelblock__main-image"
                            />

                            <div
                                class="showreelblock__btn"
                                @click="setVideo(data.video)"
                            >
                                <img
                                    src="@/assets/img/pages/mainpage/mplay.svg"
                                    alt=""
                                    class="showreelblock__btn-icon"
                                />
                                <p
                                    class="showreelblock__btn-text"
                                    v-html="$t('messages.mainpage.main2')"
                                ></p>
                            </div>
                        </div>

                        <div class="showreelblock__mobile">
                            <p
                                class="showreelblock__mobile-text"
                                v-html="data.text"
                            ></p>
                            <router-link :to="{ path: '/contacts/' }">
                                <button
                                    class="showreelblock__mobile-callback"
                                    v-html="$t('messages.mainpage.main1')"
                                ></button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowreelBlock",
    props: {
        data: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    methods: {
        setVideo(id) {
            this.$emit("clickHandler", id);
        },
    },
};
</script>

<style lang="scss" scoped>
.showreelblock {
    position: relative;

    &__mobile {
        display: none;
    }

    &__content {
        padding: 240px 75px 205px;
    }

    &__descr {
        position: relative;
        z-index: 1;

        overflow: hidden;
    }

    &__logo {
        height: 83px;
        width: auto;
        object-fit: cover;

        fill: #fff;

        transition: all 0.2s ease-in;

        margin-bottom: 97.18px;
    }

    &__text {
        font-size: 18px;
        line-height: 24px;

        margin-bottom: 40px;

        max-width: 470px;
    }

    &__callback {
        background: #ffffff;
        border-radius: 10px;

        padding: 7.72px 18px;

        text-align: center;

        font-size: 18px;
        line-height: 24px;

        color: #1b1d1e;

        cursor: pointer;
    }

    &__main {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        margin-top: auto;
        margin-bottom: auto;

        height: 100%;
        width: 65%;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        overflow: hidden;

        &-image {
            width: 100%;
            height: auto;
            object-fit: cover;
        }

        &-video {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }

    &__btn {
        z-index: 2;

        cursor: pointer;

        position: absolute;
        left: 82.44px;
        bottom: 188.76px;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-icon {
            height: 60.18px;
            width: auto;
            object-fit: cover;
        }

        &-text {
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;

            text-transform: uppercase;

            margin-left: 30px;
        }
    }
}

@media (max-width: 1600.98px) {
    .showreelblock {
        &__logo {
            height: 70px;

            margin-bottom: 64px;
        }
    }
}

@media (max-width: 1460.98px) {
    .showreelblock {
        &__content {
            padding: 200px 75px 145px;
        }

        &__logo {
            height: 64px;
        }

        &__main {
            width: 60%;
        }

        &__btn {
            left: 41.44px;
            bottom: 139.76px;

            &-icon {
                height: 48px;
            }

            &-text {
                margin-left: 14px;
            }
        }
    }
}

@media (max-width: 1366.98px) {
    .showreelblock {
        &__content {
            padding: 130px 75px 155px;
        }
    }
}

@media (max-width: 1024px) {
    .showreelblock {
        &__mobile {
            display: block;
            &-text {
                margin: 37.61px 0px 24px;
                font-size: 18px;
                line-height: 22px;
            }
            &-callback {
                background: #ffffff;
                border-radius: 10px;

                padding: 7.72px 18px;

                text-align: center;

                font-size: 18px;
                line-height: 24px;

                color: #1b1d1e;

                cursor: pointer;
            }
        }

        &__content {
            padding: 90px 20px 55px;
        }

        &__logo {
            margin-bottom: 31px;
        }

        &__text {
            display: none;
        }

        &__callback {
            display: none;
        }

        &__btn {
            left: unset;
            bottom: 45px;
            right: 30px;
        }

        &__main {
            position: relative;
            right: 0px;
            width: calc(100% + 20px);
        }
    }
}

@media (max-width: 768.98px) {
    /* можно удалить
        .showreelblock{

            &__mobile{
                display: block;

                &-text{
                    margin:37.61px 0px 24px;
                    font-size: 18px;
                    line-height: 22px;
                }

                &-callback{
                    
                    background: #FFFFFF;
                    border-radius: 10px;

                    padding:7.72px 18px;

                    text-align: center;

                    font-size: 18px;
                    line-height: 24px;

                    color:#1B1D1E;

                    cursor: pointer;
                }
            }

            &__content{
                padding:90px 20px 55px;
            }

            &__logo{
                margin-bottom: 31px;
            }

            &__text{
                display: none;
            }

            &__callback{
                display: none;
            }

            &__btn{
                left: unset;
                bottom: 45px;
                right: 30px;
            }

            &__main{
                position: relative;
                right: 0px;
                width: calc( 100% + 20px );
            }
        }
        */
}

@media (max-width: 480.98px) {
    .showreelblock {
        &__content {
            padding: 30px 20px 25px;
        }

        &__logo {
            height: 35px;
        }

        &__main {
            &-video {
                height: 280px;
            }
        }

        &__btn {
            bottom: 35px;

            &-icon {
                height: 42px;
            }

            &-text {
                font-size: 12px;

                margin-left: 8px;
            }
        }

        &__mobile {
            &-text {
                line-height: 28px;
            }
        }

        &__main {
            width: 100%;
        }
    }
}

@media (max-width: 360.98px) {
    .showreelblock {
        &__logo {
            height: 28px;
        }
    }
}
</style>