<template>
    <router-link :to="route" class="link">

        <div class="link__icon">
            <svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg" class="link__icon-src">
                <path d="M13.8375 4.88981C14.0328 4.69455 14.0328 4.37796 13.8375 4.1827L10.6555 1.00072C10.4603 0.805459 10.1437 0.805459 9.94842 1.00072C9.75315 1.19598 9.75315 1.51257 9.94842 1.70783L12.7768 4.53625L9.94842 7.36468C9.75315 7.55994 9.75315 7.87653 9.94842 8.07179C10.1437 8.26705 10.4603 8.26705 10.6555 8.07179L13.8375 4.88981ZM0.036377 5.03625H13.484V4.03625H0.036377V5.03625Z"/>
            </svg>
        </div>

        <p class="link__text">{{text}}</p>

    </router-link>
</template>

<script>
    export default {
        name:'Link',
        props:{
            text:{
                type:String,
                required:false,
                default:'',
            },
            route:{
                type:String,
                required:false,
                default:'',
            },
        }
    }
</script>

<style lang="scss" scoped>
    .link{
        position: relative;

        display:flex;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;

        cursor: pointer;

        &__icon{
            width: 30.31px;
            height: 30.31px;

            border-radius: 20px;

            background-color: #fff;

            display:flex;
            flex-direction:column;
            justify-content:center;
            align-items:center;

            margin-right: 10.12px;

            &-src{
                fill:#1B1D1E;
                width: 18px;
                height: 14px;
            }
        }

        &__text{
            font-size: 18px;
        }
    }
</style>