const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleProjects = {
    state: {
        title: "",
        filter: "",
        subFilter: "",
        project: {},
        currentId: null,
        currentRoutename: null,
        tags: ["special", "promo", "info", "image", "corporate", "online"],
        filters: [],
        subFilters: [],
        activeMain: {},
        projects: [],
        isMainFilter: false,
    },
    mutations: {
        setProjectsFilter(state, info) {
            state.filter = info.tag;

            state.filters.forEach((item) => {
                if (item.tag === info.tag) {
                    item.active = true;

                    state.subFilters = item.tags?.map((item1) =>
                        Object.assign({}, item1, {
                            active: false,
                        })
                    );

                    if (state.subFilters) state.subFilters[0].active = true;
                    state.activeMain = item;
                    state.isMainFilter = true;
                } else {
                    item.active = false;
                }
            });
        },
        setProjectsSubFilter(state, info) {
            state.subFilter = info.tag;

            state.subFilters.forEach((item) => {
                if (item.title === info.tag) {
                    item.active = true;
                    state.isMainFilter = false;
                } else {
                    item.active = false;
                }
            });
        },
        setProjectInfo(state, info) {
            state.project = info;
        },

        resetProjectInfo(state) {
            state.project = {};
        },
    },
    actions: {
        async fetchProjects({ state, rootGetters }) {
            let locale = rootGetters.getCurrentLanguageKey;
            await axios
                .post(
                    "https://api.blackandwhite.kz/bw/projects/?c=100&lang=" +
                        locale
                )
                .then((response) => {
                    state.title = response.data.header.TITLE;
                    return response.data.projects;
                })
                .then((projects) => {
                    let arr = [];

                    state.filters = [];
                    if (locale == "ru") {
                        state.filters.push({
                            title: "Все",
                            active: true,
                        });
                    } else if (locale == "en") {
                        state.filters.push({
                            title: "All",
                            active: true,
                        });
                    }
                    let sortedArr = projects.sort((a, b) => {
                        return parseInt(a.SORT) - parseInt(b.SORT);
                    });
                    // новый фильтр внутри section_parent
                    sortedArr.forEach((project) => {
                        let hasImage = false;
                        let hasVideo = false;

                        let {
                            ID: id,
                            ID_RU: id_ru = null,
                            NAME: title,
                            PREVIEW_PICTURE: src,
                            PREVIEW_PICTURE_GIF: srcGif,
                            PREVIEW_VIDEO_MP4: video1,
                            PREVIEW_VIDEO_WEBM: video2,
                            TYPE: tag,
                            SORT: sort,
                            CODE: routeName,
                            SECTION_PARENT: tags,
                        } = project;
                        if (video1 || video2) {
                            hasVideo = true;
                        }

                        if (src) {
                            hasImage = true;
                        }

                        //!!!фильтрует сами субфильтры от пустых !
                        // let filteredTags = tags.filter((tag) => {
                        //     // console.log("внутри тэга проекта", tag);
                        //     return tag.title === undefined;
                        // });

                        //здесь ищет совпадения с уже которые есть в фильтре основные
                        let flag = state.filters.some((item) => {
                            // console.log(tags[0].NAME, item.title);
                            return item.title === tags[0].NAME;
                        });

                        // здесь доабвляет каждый фильтр тэг
                        let childTag = [
                            {
                                NAME: "Все",
                                title: "Все",
                                active: false,
                            },
                        ];
                        if (tags.length > 1) {
                            childTag[1] = tags[1];
                            childTag[1]["title"] = childTag[0]["NAME"];
                        }
                        if (!flag) {
                            state.filters.push({
                                title: tags[0].NAME,
                                active: false,
                                tag: tags[0].NAME,
                                tags: childTag,
                            });
                        } else {
                            let filter = state.filters.find(
                                (e) => e.title === tags[0].NAME
                            );

                            // выходит смотрим если этот тэг уже в существующем потом смотрим у него существующие подтэги
                            // есть два массива с которого надо уникальные подтэши засунуть

                            //короче просто сразу все соединяю и фильтрую после на уникальные подтэги
                            //! по tags[0] надо tags[1]
                            let allTags = [...filter.tags, ...childTag].map(
                                (item) => {
                                    item.active = false;
                                    item["title"] = item["NAME"];
                                    return item;
                                }
                            );
                            let filtered = [
                                ...new Map(
                                    allTags.map((item) => [item["ID"], item])
                                ).values(),
                            ];
                            filter.tags = filtered;
                            // добавилось теперь на клики надо чтобы искало и показывало подтэги
                            allTags = [];
                        }
                        // нужно проверять есть ли уже у этого фильтра подфильтры

                        let obj = {
                            id,
                            id_ru,
                            title,
                            src,
                            srcGif,
                            video1,
                            video2,
                            tag,
                            sort,
                            routeName,
                            hasImage,
                            hasVideo,
                            tags,
                        };

                        arr.push(obj);
                    });

                    state.projects = [];
                    state.projects = arr;
                });
        },

        fetchProjectDetail({ state, rootGetters }, info) {
            let locale = rootGetters.getCurrentLanguageKey;

            state.projects.forEach((item) => {
                if (item.routeName === info.routename) {
                    axios
                        .post(
                            "https://api.blackandwhite.kz/bw/projects/detail.php?lang=" +
                                locale +
                                "&id=" +
                                item.id
                        )
                        .then((response) => {
                            return response.data.project[0];
                        })
                        .then((project) => {
                            let {
                                ID,
                                CODE,
                                NAME,
                                LANG,
                                B_TEXT: {
                                    t_1: { TEXT: t_1 = "" },
                                    t_2: { TEXT: t_2 = "" },
                                    t_3: { TEXT: t_3 = "" },
                                },
                                B_VIDEO: { video = "", backstage = "" },
                                B_PHOTO: slider,
                                B_TEAM: {
                                    team: { TEXT: teamText },
                                    client,
                                    year,
                                    type,
                                },
                                B_REWARDS: rewards,
                                S_PROJECTS: similar,
                                PREVIEW_PICTURE: preview,
                                INFO_NUMBERS: info_numbers,
                                PAGE_PROJECT = undefined,
                                SEO_TITLE: seo_title,
                                SEO_DESC: { TEXT: seo_text = undefined },
                            } = project;

                            let persons = [];
                            let hasSimilar = false;
                            let siteInfo = {
                                hasSite: false,
                            };

                            if (teamText) {
                                let arr = [];

                                arr = teamText.split(";");

                                arr.forEach((item) => {
                                    let obj = {};

                                    let splitterArr = item.trim().split(":");

                                    if (splitterArr[0] !== "") {
                                        obj.status = splitterArr[0];
                                        obj.list = splitterArr[1].split(",");

                                        persons.push(obj);
                                    }
                                });
                            }

                            if (project.BLOCK_TEAM_CLIENT) {
                                client = project.BLOCK_TEAM_CLIENT;
                            }

                            if (project.BLOCK_TEAM_YEAR) {
                                year = project.BLOCK_TEAM_YEAR;
                            }

                            if (project.TYPE) {
                                type = project.TYPE;
                            }

                            if (slider) {
                                slider = slider.map((item) => {
                                    return {
                                        src: item,
                                    };
                                });
                            }

                            if (rewards) {
                                rewards = rewards.map((item) => {
                                    let obj = {
                                        hasImage: false,
                                        nominations: [],
                                    };

                                    if (item.PROP_IMAGE) {
                                        obj.hasImage = true;
                                    }

                                    if (item.PROP_N_NAME) {
                                        item.PROP_N_VALUE.forEach(
                                            (nomination) => {
                                                obj.nominations.push({
                                                    title: item.PROP_N_NAME,
                                                    text: nomination,
                                                });
                                            }
                                        );
                                    }

                                    if (item.PROP_R_NAME) {
                                        item.PROP_R_VALUE.forEach((award) => {
                                            obj.nominations.push({
                                                title: item.PROP_R_NAME,
                                                text: award,
                                            });
                                        });
                                    }

                                    return obj;
                                });
                            }

                            if (similar.length > 0) {
                                hasSimilar = true;

                                similar = similar.map((item) => {
                                    return {
                                        id: item.ID,
                                        src: item.PREVIEW_PICTURE,
                                        name: item.NAME,
                                        code: item.CODE,
                                    };
                                });
                            }

                            let infolist = [];
                            if (info_numbers) {
                                infolist =
                                    info_numbers.length > 0
                                        ? info_numbers.map((each, index) => {
                                              return {
                                                  index,
                                                  // image:null,
                                                  image: each.img,
                                                  info: {
                                                      value: each.number,
                                                      text: each.text,
                                                  },
                                              };
                                          })
                                        : [];
                            }

                            if (PAGE_PROJECT) {
                                siteInfo.hasSite = true;
                                (siteInfo.url = PAGE_PROJECT.link),
                                    (siteInfo.image = PAGE_PROJECT.img),
                                    (siteInfo.text = PAGE_PROJECT.description);
                            }

                            if (!seo_title) {
                                seo_title = NAME;
                            }

                            if (!seo_text) {
                                seo_text = t_2
                                    .split("<p>")
                                    .join("")
                                    .split("</p>")
                                    .join("")
                                    .split("&nbsp;")
                                    .join(" ")
                                    .replace(/(\r\n|\n|\r)/gm, "");
                            }

                            let obj = {
                                id: ID,
                                routeName: CODE,
                                title: NAME,
                                lang: LANG,
                                headerText: t_1,
                                mainText: t_2,
                                footerText: t_3,
                                video,
                                backstage,
                                hasSimilar,
                                slider,
                                persons,
                                rewards,
                                infolist,
                                siteInfo,
                                seo: {
                                    title: seo_title,
                                    text: seo_text,
                                },
                                info: [
                                    {
                                        status:
                                            locale == "ru"
                                                ? "Заказчик"
                                                : "Client",
                                        list: [client],
                                    },
                                    {
                                        status: locale == "ru" ? "Год" : "Year",
                                        list: [year],
                                    },
                                    {
                                        status:
                                            locale == "ru"
                                                ? "Формат"
                                                : "Format",
                                        list: [type],
                                    },
                                ],
                                similar,
                                preview,
                            };

                            state.project = obj;
                        });
                }
            });
        },
    },
    getters: {
        getProjectsTitle(state) {
            return state.title;
        },

        getProjects(state) {
            return state.projects;
        },
        //включить когда субтэги используются
        getFilteredProjects(state) {
            if (state.isMainFilter) {
                if (state.filter) {
                    return state.projects.filter((item) => {
                        return item.tags[0]?.NAME === state.filter;
                    });
                } else {
                    return state.projects;
                }
            } else {
                if (state.subFilter) {
                    if (state.subFilter == "Все") {
                        return state.projects.filter((item) => {
                            return item.tags[0]?.NAME === state.filter;
                        });
                    }
                    return state.projects.filter((item) => {
                        return item.tags[1]
                            ? item.tags[1].NAME === state.subFilter
                            : null;
                    });
                } else {
                    return state.projects;
                }
            }
        },
        getProjectActiveFilter(state) {
            return state.activeMain;
        },
        getProjectFilters(state) {
            return state.filters;
        },
        getProjectSubFilters(state) {
            return state.subFilters;
        },
        getActiveFilter(state) {
            return state.filter;
        },

        getProjectAwards(state) {
            return state.awards;
        },

        getProjectTeam(state) {
            return state.team;
        },

        getProjectSimilar(state) {
            return state.similarProjects;
        },

        getProjectSliderItems(state) {
            return state.sliderItems;
        },

        getActiveProjectId(state) {
            return state.currentId;
        },

        getActiveProjectCode(state) {
            return state.currentCode;
        },

        getProjectInfo(state) {
            return state.project;
        },
    },
};

export default moduleProjects;
