const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleMain = {
    state:{
        data:{
            showreel:{},
            projects:[],
            clients:[],
            reports:[],
        }
    },
    mutations:{
        
    },
    actions:{
        async fetchMainpage({state,rootGetters}){
            let locale = rootGetters.getCurrentLanguageKey

            await axios
            .post('https://api.blackandwhite.kz/bw/index_new.php?c=5&lang='+locale)
            .then((response)=>{
                return response.data.portfolio
            })
            .then((portfolio)=>{
                let {
                    SHOWREEL:{
                        SHOWREEL_TEXT:showreelText,
                        SHOWREEL_VIDEO:{
                            NAME:showreelName,
                            PREVIEW_PICTURE:showreelPoster,
                            PREVIEW_VIDEO_MP4:showreelPreview,
                            VIDEO:showreelVideo,
                        }
                    },

                    PORTFOLIO:projects,
                    CLIENTS:clients,
                    
                    FILM:film,
                    
                    REPORT:reports,
                } = portfolio


                state.data.showreel = {
                    text:showreelText,
                    name:showreelName,
                    poster:showreelPoster,
                    preview:showreelPreview,
                    video:showreelVideo,
                }

                state.data.projects = projects
                state.data.clients  = clients
                state.data.film = film
                state.data.reports = reports
            })



        }
    },
    getters:{
        getMainpageData(state){
            return state.data
        }
    },
}

export default moduleMain