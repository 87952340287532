<template>
  <div class="contacts">
    <div class="container">
      <div class="row">
        <div class="col-start-2 col-10">
          <div class="app__titlewrap app__titlewrap--mbsm">
            <h1 class="app__title" v-html="getContactsTitle"></h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-start-2 col-10">
          <div class="contacts__list">
            <div
              class="contacts__person"
              v-for="(item, index) in getContactsPersons[$i18n.locale]"
              :key="index"
            >
              <img :src="item.src" alt="" class="contacts__person-image" />
              <p class="contacts__person-name" v-html="item.name"></p>
              <p class="contacts__person-position" v-html="item.position"></p>

              <a :href="'tel:' + item.phone" class="contacts__person-phone">
                <img
                  :src="
                    isLight
                      ? require('@/assets/img/icons/phone-light.svg')
                      : require('@/assets/img/icons/phone.svg')
                  "
                  alt=""
                  class="contacts__person-phone-icon"
                />
                <a
                  :href="'tel:' + item.phone"
                  class="contacts__person-phone-link"
                  v-html="item.phone"
                ></a>
              </a>

              <div class="contacts__person-socials">
                <a
                  v-for="(item, index) in item.socials"
                  :key="index"
                  target="_blank"
                  :href="getSocialLink(item.key, item.link)"
                  class="contacts__person-socials-item"
                >
                  <img
                    :src="getSocialIcon(item.key)"
                    alt=""
                    class="contacts__person-socials-item-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <contacts-form />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";

import ContactsForm from "./components/ContactsForm.vue";

export default {
  name: "Contacts",
  components: {
    ContactsForm,
  },
  computed: {
    ...mapGetters([
      "getAppTheme",

      "getContactsTitle",
      "getContactsInfo",

      "getEmailSendStatus",
      "getMarkerData",

      "getCurrentLanguage",

      "getEmailMessage",
      "getContactsPersons",
    ]),
  },
  mounted() {
    this.isLight = this.getAppTheme ? true : false;

    this.setMetaInfo({
      page: "contacts",
    });

    document.querySelector(".orderbtn").classList.add("notvisible");
  },
  beforeDestroy() {
    document.querySelector(".orderbtn").classList.remove("notvisible");
  },
  watch: {
    getCurrentLanguage: function () {
      this.fetchContacts();
    },
    getAppTheme: function (val) {
      this.isLight = val;
    },
  },
  data() {
    return {
      isLight: false,
    };
  },
  methods: {
    ...mapMutations(["setFormError", "setMetaInfo"]),

    ...mapActions(["fetchContacts", "sendContactsEmail"]),

    getSocialLink(key, value) {
      let link = "";

      if (key == "telegram") {
        link = "https://t.me/" + value;
      } else if (key == "instagram") {
        link = "https://www.instagram.com/" + value;
      }

      return link;
    },

    getSocialIcon(key) {
      let icon;

      if (key == "telegram") {
        icon = this.isLight
          ? require("@/assets/img/icons/telegram-light.svg")
          : require("@/assets/img/icons/telegram.svg");
      } else if (key == "instagram") {
        icon = this.isLight
          ? require("@/assets/img/icons/instagram-logo-light.svg")
          : require("@/assets/img/icons/instagram-logo.svg");
      }

      return icon;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>