<template>
    <div class="castings" @scroll="handleScroll">
        <div class="container container--overvisible">
            <div class="row">
                <div class="col-12">
                    <h1 class="castings__heading">
                        Анкета для заявки на кастинг
                    </h1>
                </div>

                <div class="col-4 col-md-12">
                    <div class="castings__nav">
                        <div
                            class="castings__nav-item"
                            v-scroll-to="'#personal'"
                            @click="activeSection = 'personal'"
                            :class="{ active: activeSection == 'personal' }"
                        >
                            Личные данные
                        </div>

                        <div
                            class="castings__nav-item"
                            v-scroll-to="'#prof'"
                            @click="activeSection = 'prof'"
                            :class="{ active: activeSection == 'prof' }"
                        >
                            Профессиональные данные
                        </div>

                        <div
                            class="castings__nav-item"
                            v-scroll-to="'#bio'"
                            @click="activeSection = 'bio'"
                            :class="{ active: activeSection == 'bio' }"
                        >
                            Биоданные
                        </div>
                    </div>
                </div>

                <div class="col-8 col-md-12">
                    <div class="castings__form">
                        <section
                            id="personal"
                            class="castings__form-section"
                            :class="{ active: activeSection == 'personal' }"
                        >
                            <h2 class="castings__form-heading">
                                Личные данные
                            </h2>
                            <div class="row">
                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Фамилия
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.surname"
                                                name="surname"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="Ахметова"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.surname && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Пол
                                        </div>
                                        <div class="castings__question-field">
                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="formData.sex"
                                                    name="sex"
                                                    value="мужской"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                мужской
                                            </label>

                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="formData.sex"
                                                    name="sex"
                                                    value="женский"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                женский
                                            </label>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.sex && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Имя
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.name"
                                                name="name"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="Арайлым"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.name && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Отчество
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.middleName"
                                                name="middleName"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="Рахатовна"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Номер телефона
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.phone"
                                                v-mask="'+# ### ###-##-##'"
                                                name="phone"
                                                type="phone"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="+ 7-(___)-_______"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.phone && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Запасной номер для связи
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.phoneReserve"
                                                v-mask="'+# ### ###-##-##'"
                                                name="phonereserve"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="+ 7-(___)-_______"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Дата рождения
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.birthday"
                                                name="birthday"
                                                v-mask="'##-##-####'"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="03-01-1995"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.birthday && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Электронная почта:
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.email"
                                                name="email"
                                                type="email"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="info@mail.com"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.email && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Адрес проживания
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.address"
                                                name="address"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="город, улица, дом, квартира"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.address && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Ссылка на Instagram
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.instagram"
                                                name="instagram"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="www.instagram.com/"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.instagram && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section
                            id="prof"
                            class="castings__form-section"
                            :class="{ active: activeSection == 'prof' }"
                        >
                            <h2 class="castings__form-heading">
                                Профессиональные данные
                            </h2>

                            <div class="row">
                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Образование
                                        </div>
                                        <div class="castings__question-field">
                                            <textarea
                                                name="education"
                                                v-model="formData.education"
                                                class="textarea"
                                                placeholder="ВУЗ, Факультет, Специальность"
                                            ></textarea>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.education && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Место работы и специальность
                                        </div>
                                        <div class="castings__question-field">
                                            <textarea
                                                name="work"
                                                v-model="formData.work"
                                                class="textarea"
                                                placeholder="Место работы, Специальность"
                                            ></textarea>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.work && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section
                            id="bio"
                            class="castings__form-section"
                            :class="{ active: activeSection == 'bio' }"
                        >
                            <h2 class="castings__form-heading">Биоданные</h2>

                            <div class="row">
                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Рост , см
                                        </div>
                                        <div class="castings__question-field">
                                            <div class="range">
                                                <range-slider
                                                    v-model="formData.height"
                                                    :tooltip="'always'"
                                                    :min="145"
                                                    :max="210"
                                                    :marks="{
                                                        '145': '145',
                                                        '210': '210',
                                                    }"
                                                ></range-slider>
                                            </div>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.height && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Вес, кг
                                        </div>
                                        <div class="castings__question-field">
                                            <div class="range">
                                                <range-slider
                                                    v-model="formData.weight"
                                                    :tooltip="'always'"
                                                    :min="40"
                                                    :max="100"
                                                    :marks="{
                                                        '40': '40',
                                                        '100': '100',
                                                    }"
                                                ></range-slider>
                                            </div>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="!formData.weight && checkForm"
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Цвет глаз, цвет волос, длина волос
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.eyeHair"
                                                name="eyehair"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="черный, черный, до плеч"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.eyeHair && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Навыки, спорт, хореография
                                        </div>
                                        <div class="castings__question-field">
                                            <textarea
                                                name="skillSport"
                                                v-model="formData.skillSport"
                                                class="textarea"
                                                placeholder="текстовый блок минимум несколько строк"
                                            ></textarea>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.skillSport &&
                                                checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Вокал, музыкальные инструменты
                                        </div>
                                        <div class="castings__question-field">
                                            <textarea
                                                name="skillmusic"
                                                v-model="formData.skillMusic"
                                                class="textarea"
                                                placeholder="текстовый блок минимум несколько строк"
                                            ></textarea>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.skillMusic &&
                                                checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Хотели бы участвовать в массовых
                                            сценах
                                        </div>
                                        <div
                                            class="
                                                castings__question-field
                                                castings__question-field_0
                                            "
                                        >
                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        formData.massScenes
                                                    "
                                                    name="massscenes"
                                                    value="да"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                да
                                            </label>

                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        formData.massScenes
                                                    "
                                                    name="massscenes"
                                                    value="нет"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                нет
                                            </label>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.massScenes &&
                                                checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Опыт съёмок
                                        </div>
                                        <div class="castings__question-field">
                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        formData.experience
                                                    "
                                                    name="experience"
                                                    value="да"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                да
                                            </label>

                                            <label class="radio">
                                                <input
                                                    type="radio"
                                                    v-model="
                                                        formData.experience
                                                    "
                                                    name="experience"
                                                    value="нет"
                                                />
                                                <span
                                                    class="radio__mark"
                                                ></span>
                                                нет
                                            </label>
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.experience &&
                                                checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Откуда узнали о нас
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.aboutUs"
                                                name="aboutus"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="соц. сети, знакомые, сайт, другое "
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.aboutUs && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="castings__question">
                                        <div class="castings__question-title">
                                            Ваши фотографии, видео
                                            <span
                                                >(полный рост, крупный
                                                план)</span
                                            >
                                        </div>
                                        <div class="castings__question-field">
                                            <input
                                                v-model="formData.linkFile"
                                                name="linkfile"
                                                type="text"
                                                class="
                                                    input
                                                    input--rounded
                                                    input--big
                                                "
                                                placeholder="ссылка на видео"
                                            />
                                        </div>
                                        <div
                                            class="castings__question-error"
                                            v-if="
                                                !formData.linkFile && checkForm
                                            "
                                        >
                                            Заполните поле
                                        </div>
                                        <div class="castings__question-file">
                                            <!-- Компонент, "загрузить файл" -->
                                            <!-- <upload-file></upload-file> -->

                                            <div class="upload-file">
                                                <label
                                                    class="upload-file__input"
                                                >
                                                    <img
                                                        src="@/assets/img/icons/add-file.svg"
                                                        alt=""
                                                    />

                                                    <input
                                                        type="file"
                                                        id="file"
                                                        ref="myFiles"
                                                        @change="onFileChange"
                                                    />
                                                    загрузить файлы (rar, jpeg)
                                                </label>
                                                <div
                                                    class="upload-file__info"
                                                    v-if="
                                                        fileSize <= 25 &&
                                                        fileName
                                                    "
                                                >
                                                    <img
                                                        src="@/assets/img/icons/check-file.svg"
                                                        alt=""
                                                    />
                                                    <div
                                                        class="
                                                            upload-file__name
                                                        "
                                                    >
                                                        {{ fileName }}
                                                        <span
                                                            >{{
                                                                fileSize
                                                            }}Mb</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        upload-file__info
                                                        red-error
                                                    "
                                                    v-if="fileSize > 25"
                                                >
                                                    Файл не должен превышать 25
                                                    мб
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-md-12">
                                    <div class="castings__question">
                                        <button
                                            @click="sendMail"
                                            class="
                                                btn
                                                btn--big
                                                btn--rounded
                                                btn--dark
                                            "
                                        >
                                            Отправить!
                                        </button>
                                    </div>
                                </div>
                                <div class="col-6 col-md-12">
                                    <div class="castings__question-text">
                                        Есть вопросы по форме или не получилось
                                        отправить запрос? Просьба написать на
                                        почту
                                        <a href="mailto:casting@bw.com.kz"
                                            >casting@bw.com.kz</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div class="castings__modal" v-if="formSuccess">
                    <div class="castings__message">
                        <div
                            class="castings__close"
                            @click="formSuccess = false"
                        >
                            <img
                                src="@/assets/img/icons/x.svg"
                                alt=""
                                class="vcard__decor-icon"
                            />
                        </div>
                        <div class="castings__heading">
                            <span> Спасибо! </span>
                            Ваша заявка успешно отправлена

                            <div class="col-12">
                                <button
                                    class="castings__close-button"
                                    @click="formSuccess = false"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="castings__modal" v-if="formNotSuccess">
                    <div class="castings__message">
                        <div
                            class="castings__close"
                            @click="formNotSuccess = false"
                        >
                            <img
                                src="@/assets/img/icons/x.svg"
                                alt=""
                                class="vcard__decor-icon"
                            />
                        </div>
                        <div class="castings__heading">
                            <span> Ошибка </span>
                            Заполните все поля

                            <div class="col-12">
                                <button
                                    class="castings__close-button"
                                    @click="formNotSuccess = false"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
	
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
    name: "Castings",
    data() {
        return {
            activeSection: "personal",
            checkForm: false,
            formSuccess: false,
            formNotSuccess: false,
            fileName: "",
            fileSize: 0,
            normalFileSize: false,
            formData: {
                name: "",
                surname: "",
                middleName: "",
                sex: "",
                phone: "",
                phoneReserve: "",
                birthday: "",
                email: "",
                address: "",
                instagram: "",
                education: "",
                work: "",
                height: 150,
                weight: 60,
                eyeHair: "",
                skillSport: "",
                skillMusic: "",
                massScenes: "",
                experience: "",
                aboutUs: "",
                linkFile: "",
                files: [],
            },
        };
    },

    mounted() {
        this.setMetaInfo({
            page: "castings",
        });
    },

    methods: {
        ...mapMutations(["setMetaInfo, setAppTheme"]),
        handleScroll() {
            let personal = document
                .getElementById("personal")
                .getBoundingClientRect();

            let prof = document.getElementById("prof")
                ? document.getElementById("prof").getBoundingClientRect()
                : null;

            let bio = document.getElementById("bio")
                ? document.getElementById("bio").getBoundingClientRect()
                : null;

            if (personal.top < 150 && personal.top > -150) {
                this.activeSection = "personal";
            } else if (prof.top < 150 && prof.top > -150) {
                this.activeSection = "prof";
            } else if (bio.top < 150 && bio.top > 0) {
                this.activeSection = "bio";
            }
        },

        onFileChange(event) {
            let fileData = event.target.files[0];
            this.fileName = fileData.name;
            this.fileSize = (fileData.size / 1000000).toFixed(2);

            this.formData.files = this.$refs.myFiles.files;
        },

        sendMail() {
            // Проверка формы на заполненность
            if (
                this.formData.name &&
                this.formData.surname &&
                this.formData.sex &&
                this.formData.phone &&
                this.formData.birthday &&
                this.formData.email &&
                this.formData.address &&
                this.formData.instagram &&
                this.formData.education &&
                this.formData.work &&
                this.formData.eyeHair &&
                this.formData.skillSport &&
                this.formData.skillMusic &&
                this.formData.massScenes &&
                this.formData.experience &&
                this.formData.aboutUs &&
                this.formData.linkFile &&
                this.fileSize < 25
            ) {
                this.checkForm = false;
            } else {
                this.checkForm = true;
            }

            if (!this.checkForm) {
                // const headers = {
                //   "X-Requested-With": "XMLHttpRequest",
                //   "Content-Type": "application/json",
                //   "Access-Control-Allow-Origin": "*",
                // };

                let bodyFormData = new FormData();

                bodyFormData.set("name", this.formData.name);
                bodyFormData.set("surname", this.formData.surname);
                bodyFormData.set("middleName", this.formData.middleName);
                bodyFormData.set("sex", this.formData.sex);
                bodyFormData.set("phone", this.formData.phone);
                bodyFormData.set("phoneReserve", this.formData.phoneReserve);
                bodyFormData.set("birthday", this.formData.birthday);
                bodyFormData.set("email", this.formData.email);
                bodyFormData.set("address", this.formData.address);
                bodyFormData.set("instagram", this.formData.instagram);
                bodyFormData.set("education", this.formData.education);
                bodyFormData.set("work", this.formData.work);
                bodyFormData.set("height", this.formData.height);
                bodyFormData.set("weight", this.formData.weight);
                bodyFormData.set("eyeHair", this.formData.eyeHair);
                bodyFormData.set("skillSport", this.formData.skillSport);
                bodyFormData.set("skillMusic", this.formData.skillMusic);
                bodyFormData.set("massScenes", this.formData.massScenes);
                bodyFormData.set("experience", this.formData.experience);
                bodyFormData.set("aboutUs", this.formData.aboutUs);
                bodyFormData.set("linkFile", this.formData.linkFile);
                bodyFormData.set("file", this.formData.files[0]);

                // Подтверждение отправки формы
                this.formSuccess = true;

                axios
                    .post(
                        "https://api.blackandwhite.kz/casting_form.php",
                        bodyFormData
                    )
                    .then(() => {})
                    .catch(() => {
                        console.log(" why error");
                    });
            } else {
                this.formNotSuccess = true;
            }
        },
    },

    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
};
</script>