const en = {
    messages: {
        mainpage: {
            clients1: "clients",
            clients2: "Show all clients",

            about1: "film about us ",

            contacts1: "Do you like it?",
            contacts2: "Let's discuss",
            contacts3: "Your application has been sent successfully",
            contacts4: "Waiting on a call",
            contacts5:
                'Pushing the button "Waiting on a call" I contest to my personal data',

            contacts6: "Full name",
            contacts7: "E-mail",
            contacts8: "Phone number",

            projects1: "portfolio",
            projects2: "Show all projects",

            report1: "Black & White in numbers ",

            main1: "Contact us",
            main2: "watch showreel",

            footer: "Republic of Kazakhstan, Astana, st. Tokpanova, 10, block 3, floor 2",
        },

        header: {
            contacts: "Order video",

            menu0: "Mainpage",
            menu1: "Services",
            menu2: "Projects",
            menu3: "Vacancies",
            menu4: "Premiere",
            menu5: "Сasting",
        },

        vacancies: {
            btn: "To contact us",
            details: "Specific Details",
        },

        contacts: {
            success: "Your application has been sent successfully",
            name: "full name",
            phone: "phone number",
            message: "message",
            send: "send",

            error0: "Fill in all the fields",
            error1: "Fill in all the fields",
            error2: "Write correct phone number",
            error3: "Write the correct phone number",
            error4: "Write the correct email",
            subject: "Message from the form on the website bw.com.kz",

            address:
                "Republic of Kazakhstan, Astana, st. Tokpanov, 10, block 3, floor 2",
            callback: "Request a call",
            notification: "A manager will contact you within 10 minutes",
            notification1: "Our manager will contact you during the week",
            notification2:
                "Manager will contact you during the week (from Monday to Friday from 09:00 till 18:00)",
        },

        projectslist: {
            all: "All projects",
            download: "Download more",
        },

        projectdetail: {
            awards: "awards:",
            team: "The project team",
            similar: "Similar projects:",

            client: "Client",
            year: "Year",
            format: "Format",

            back: "Back to projects",
        },

        notfound: {
            title: "404 Not Found",
            message: "have you lost?",
            btntext: "back to the main page",
        },

        services: {
            details: "Details",
        },

        specialprojects: {
            film: "Film about us",
            play: "Watch the video",
            slogan: "We invite you to join us on a walk through Black & White office.",
            who: "Who are we?",
            clients: "WHO ARE OUR CLIENTS?",
            principles: "WHAT ARE OUR CORE PRINCIPLES?",
            people: "Listen to a few of our people.",
            analog: "SIMILAR PROJECTS",

            persons: [
                {
                    status: "PROJECT TEAM WRITER AND DIRECTOR",
                    list: ["Ksenia Pridatchenko"],
                },
                {
                    status: "SUPPLIER OPERATOR ASSISTANT",
                    list: ["Kuanysh Sansyzbaev"],
                },
                {
                    status: "PRODUCER",
                    list: ["Aigerim Tokamanova"],
                },
                {
                    status: "COLOR DIRECTOR",
                    list: ["Marlene Nurashev"],
                },
                {
                    status: "SUPPLIER OPERATORS",
                    list: ["Aivar Kydyrbaev", "Zhanibek Mashrapov"],
                },
                {
                    status: "SOUND OPERATOR",
                    list: ["Kazbek Esmurzaev"],
                },
                {
                    status: "FILM EDITOR",
                    list: ["Ulan Kamzin"],
                },
                {
                    status: "SOUND DIRECTOR",
                    list: ["Diaz Lesteguy"],
                },
                {
                    status: "DESIGNER AND MOTION DESIGNER",
                    list: ["Ayan Kizabekova"],
                },
                {
                    status: "MAKEUP",
                    list: ["Tolganay Abutalip"],
                },
                {
                    status: "BREAKER AUTHOR",
                    list: ["Togzhan Kemelova"],
                },
                {
                    status: "DRIVER",
                    list: ["Mukhagali Baibolganov"],
                },
                {
                    status: "EDITOR",
                    list: ["Zhanel Zhazetova"],
                },
            ],
        },
    },
};

export default en;
