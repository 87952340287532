<template>
  <div class="seo">
    <section class="black">
      <div class="container">
        <div class="seo_about seo_section">
          <div class="container_mini">
            <div class="app__titlewrap">
              <h1 class="app__title">Имиджевый видеоролик</h1>
            </div>
            <div class="seo_text">
              Компания Black & White входит в число лучших продакшенов — лидеров
              видеопроизводства в Казахстане. У нашей команды большой опыт
              съемок имиджевых роликов, в том числе для крупных национальных
              компаний.
            </div>
            <div class="seo_text">
              Имиджевый ролик — один из наиболее сложных форматов, требующий
              художественно-постановочных съемок и профессионального подхода.
            </div>
          </div>

          <div class="seo_about_slider">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide shadow"
                v-for="(item, index) in sliderImages"
                :key="index"
              >
                <div class="seo_about_slider_item">
                  <img :src="item.src" alt="" />
                  <div class="container_mini">
                    <div class="seo_about_slider_content">
                      <div class="seo_about_slider_number">
                        {{ index + 1 }}/{{ sliderImages.length }}
                      </div>
                      <div class="seo_about_slider_title">
                        {{ item.title }}
                      </div>
                      <div class="seo_text">
                        {{ item.text }}
                      </div>
                      <a :href="item.link" target="_black" class="seo_button">
                        Узнать больше
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
        </div>

        <div class="seo_benefits seo_section">
          <div class="container_mini">
            <h2 class="seo_title">Особенности формата</h2>
          </div>
          <div class="seo_benefits_tabs">
            <div class="seo_benefits_tabs_menu">
              <div
                class="seo_benefits_tabs_menu_item"
                v-for="(item, index) in tabsMenu"
                :key="index"
                v-html="item.tabsMenuItem"
                @click="selectService(index)"
                :class="index == selectedService ? 'active' : ''"
              ></div>
            </div>
            <div class="seo_benefits_tabs_content">
              <div
                class="seo_benefits_tabs_item"
                v-for="(item, index) in tabsItem"
                :key="index"
                :class="index == selectedService ? 'show' : 'hide'"
              >
                <div
                  class="seo_text"
                  v-for="(itemText, index) in item"
                  :key="index"
                  v-html="itemText"
                ></div>
              </div>
            </div>
          </div>
        </div>

<!--
        <div class="seo_experience seo_section">
          <div class="container_mini">
            <h2 class="seo_title">Опыт Black & White</h2>
            <div class="seo_text">
              Начиная с 2013 года, компания Black & White снимала серию
              социальных роликов для АО «Самрук-Қазына» (<a
                class="seo_link"
                href="/projects/malenkimi-shagami-k-bolshoy-tseli/"
                target="_blank"
                >«Маленькими шагами к большой цели»</a
              >,
              <a
                class="seo_link"
                href="/projects/bolshe-chem-kollegi-bolshe-chem-semya/"
                target="_blank"
                >«Трудовые династии»</a
              >,
              <a
                class="seo_link"
                href="/projects/davayte-pomnit-o-pobedakh-chashche/"
                target="_blank"
                >«Давайте помнить о победах чаще!»</a
              >
              и другие). Эти видеоролики широко транслировались на казахстанском
              телевидении и получили признание на Центрально-азиатском фестивале
              коммуникаций Red Jolbors.
            </div>
            <div class="seo_text">
              Социальный ролик «Трудовые династии» взял «серебро», а ролик
              «Давайте помнить о победах чаще!» получил сразу две награды:
              «серебро» в номинации «Социальные ролики» и «бронзу» в номинации
              «FILM Мастерство исполнения». На Киевском международном фестивале
              рекламы социальный ролик о ветеране завоевал «бронзу» за лучший
              сценарий.
            </div>
          </div>
          <div class="seo_experience_videowrap">
            <video
              class="seo_experience_videowrap-video"
              playsinline=""
              muted="muted"
              loop="loop"
              poster="https://api.blackandwhite.kz/upload/resize_cache/iblock/6d3/706_401_2/6d3fafa4e29eeab329c33fb96eef68c1.jpg"
            >
              <source
                src="https://api.blackandwhite.kz/upload/iblock/c3b/c3b07e039fa809b9073b1fb0446b6124.m4v"
                type="video/mp4"
              />
            </video>
            <div class="seo_experience_btn" @click="openModal">
              <img
                src="@/assets/img/pages/mainpage/mplay.svg"
                alt=""
                class="seo_experience_btn-icon"
              />
              <p class="seo_experience_btn-text" v-html="'Смотреть ролик'"></p>
            </div>
          </div>
        </div>
-->
      </div>
    </section>

    <section class="white">
      <div class="container">
        <div class="seo_projects seo_section">
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/imagepage/imagepage_1.jpg" alt="" />
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold"><a class='seo_link' href="/projects/v-ozhidanii-expo/" target="_blank">В ожидании EXPO</a></div>
              <div class="seo_text">
                Международная выставка EXPO-2017 — один из самых масштабных
                проектов независимого Казахстана. В серии видеороликов мы
                поставили себе задачу — показать масштаб мероприятия и
                познакомить со всеми составляющими выставки: концертной
                программой, павильонами, цирком Дю Солей.
              </div>
            </div>
          </div>
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/imagepage/imagepage_2.jpg" alt=""/>
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold"><a class='seo_link' href="/projects/na-blago-strany/" target="_blank">На благо страны</a></div>
              <div class="seo_text">
                АО «Самрук-Қазына» — это крупнейший инвестиционный холдинг
                Казахстана, миссия которого — повышать национальное
                благосостояние страны. Имиджевый ролик продемонстрировал работу
                команду холдинга в разных городах и климатических условиях, днём
                и ночью — всё ради одной большой цели: на благо страны. Съёмки
                ролика проходили в Нур-Султане и Атырау на производственных
                объектах и в павильонах. Героями ролика выступили сотрудники
                холдинга и известные казахстанские актеры.
              </div>
            </div>
          </div>
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/imagepage/imagepage_3.jpg" alt=""/>
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold"><a class='seo_link' href="/projects/seriya-videorolikov-smart-aqkol/" target="_blank">Smart Aqkol</a></div>
              <div class="seo_text">
                Акколь — город в 100 километрах от Нур-Султана, в котором был
                реализован пилотный проект государственной программы по
                внедрению современных технологий «Цифровой Казахстан». Имиджевый
                ролик демонстрирует важность проекта, он был продемонстрирован
                главе государства Н.А. Назарбаеву на открытии Smart Aqkol.
              </div>
            </div>
          </div>
          <div class="seo_projects_item">
            <div class="seo_projects_image">
              <img src="@/assets/img/pages/seopages/imagepage/imagepage_4.jpg" alt=""/>
            </div>
            <div class="seo_projects_text">
              <div class="seo_text bold">
                <a class='seo_link' href="/projects/tvorim-istoriyu-vmeste-c-fk-astana/" target="_blank">Творим историю вместе c ФК «Астана»</a>
              </div>
              <div class="seo_text">
                В 2019 году ФК «Астана» стал единственным клубом, представляющим
                Казахстан в групповом этапе Лиги Европы. Ролик призывал прийти и
                поддержать родную команду во время домашних матчей.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
<!--
    <section class="black">
      <div class="container">
        <div class="seo_nominations seo_section">
          <div class="container_mini">
            <div class="seo_text">
              Специальный приз премии «Ак Мерген» в 2021 году получил проект
              <a
                class="seo_link"
                href="/projects/shyn-jurekten/"
                target="_blank"
                >«Шын Жүректен»</a
              >
              («От чистого сердца») в номинации «Социальные коммуникации и
              благотворительность». Проект был приурочен ко Дню благодарности 1
              марта в Казахстане и предоставлял возможность сказать «спасибо»
              людям, которые этого достойны.
            </div>
          </div>
          <div class="seo_nominations_video">
            <iframe
              :src="
                'https://player.vimeo.com/video/517966183' +
                '?title=0&byline=0&portrait=0&autoplay=0'
              "
              style="width: 100%; height: 100%"
              frameborder="0"
              allow="autoplay;fullscreen"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>
-->
<!--
    <section class="white">
      <div class="container">
        <div class="seo_corporate seo_section">
          <div class="container_mini">
            <h3 class="seo_title">Социальные ролики в корпоративном секторе</h3>
            <div class="seo_text">
              Социальные видеоролики — отличный инструмент коммуникаций в
              корпоративном секторе. И это не только истории сотрудников,
              которые вдохновляют коллектив и ведут за собой (серия социальных
              роликов <a class="seo_link" href="/projects/videoroliki-o-sotrudnikakh-kap-akmaral-/" target="_blank">«Наши истории»</a>
              о сотрудниках АО «НАК «Казатомпром»), но более масштабные проекты.
            </div>
          </div>
          <div class="seo_corporate_block">
            <div class="seo_corporate_img">
              <img src="@/assets/img/pages/seopages/socialpage/social6.jpg" alt=""/>
            </div>
            <div class="seo_text">
              Смелым экспериментом стал <a class="seo_link" href="/projects/sotsialnyy-eksperiment-erg/" target="_blank">социальный проект</a> 
              с «Евразийской группой» (ERG), в съемках которого приняли участие
              100 сотрудников. Люди совершенно разных профессий собрались в
              одном зале и до последнего момента не знали, что их ждёт. Однако
              результат превзошел все ожидания. Сегодня ролик активно используют
              на внутрикорпоративных встречах и тренингах ERG. В 2020 году
              социальный эксперимент победил в номинации «Внутрикорпоративные
              коммуникации» премии в области PR «Ак Мерген».
            </div>
          </div>
          <div class="container_mini">
            <div class="seo_corporate_button">
              <a href="/projects" target="_blank" class="seo_button">
                Еще больше социальных видеороликов и проектов Black & White Вы можете найти в портфолио
                <svg width="58" height="15" viewBox="0 0 58 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M57.7067 8.34236C58.0972 7.95184 58.0972 7.31867 57.7067 6.92815L51.3427 0.564186C50.9522 0.173662 50.319 0.173662 49.9285 0.564186C49.538 0.95471 49.538 1.58788 49.9285 1.9784L55.5853 7.63525L49.9285 13.2921C49.538 13.6826 49.538 14.3158 49.9285 14.7063C50.319 15.0968 50.9522 15.0968 51.3427 14.7063L57.7067 8.34236ZM0.583984 8.63525H56.9996V6.63525H0.583984V8.63525Z" fill="#ECECEC"/>
                </svg>
                <div class="seo_corporate_arrow"></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
-->
    <section class="black">
      <div class="container">
        <div class="seo_needs seo_section">
          <div class="container_mini">
            <h3 class="seo_title">Кому необходимы имиджевый ролик?</h3>
            <div class="seo_text">
              Имиджевые ролики нужны всем, кто заинтересован в узнаваемости
              бренда и создании положительного впечатления о компании, повышении
              лояльности аудитории.
            </div>
            <div class="seo_text">
              В числе основных заказчиков социальных роликов Black & White —
              национальные компании и корпоративный сектор (АО «Самрук-Қазына»,
              международная выставка «EXPO-2017», ФК «Астана» и другие).
            </div>
          </div>
        </div>

        <div class="seo_create seo_section">
          <div class="container_mini">
            <h3 class="seo_title">Как создаются имиджевые ролики</h3>
            <div class="seo_text">
              Для создания имиджевых роликов необходим профессиональный подход,
              креативность, чувство стиля.
            </div>
            <div class="seo_text">
              Одно из главных преимуществ имиджевых роликов — свободная манера
              съемки и рассказа, что позволяет получить эмоциональный отклик у
              зрителя. При съемке такого ролика главная задача — создать стиль,
              атмосферу.
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">1. Pre-production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div 
                  class="swiper-slide"
                  v-for="(item, index) in preProduction"
                  :key="index"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                Мы разрабатываем сценарий, собираем съемочную группу, подбираем
                локации, реквизиты и всё, что необходимо. На этом этапе
                подключаются продюсер, сценарист и режиссёр.
              </div>
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">2. Production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in production"
                  :key="index"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                На втором этапе стартуют съемки, и съемочный процесс не
                огранивается ни городом, ни временем суток, ведь главное для нас
                — это конечный результат.
              </div>
            </div>
          </div>

          <div class="seo_create_section">
            <div class="container_mini">
              <div class="seo_mini_title">3. Post-production</div>
            </div>
            <div class="seo_create_slider">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in postProduction"
                  :key="index"
                >
                  <img :src="item.src" alt="" />
                </div>
              </div>
            </div>
            <div class="container_mini">
              <div class="seo_text">
                Монтаж отснятого материала, цветокоррекция, графика,
                саунддизайн.
              </div>
            </div>
          </div>
        </div>

        <div class="seo_section">
          <div class="container_mini">
            <h4 class="seo_title">
              Зачем Вашей организации имиджевый видеоролики?
            </h4>
            <div class="seo_text">
              Имиджевый ролик отличается тем, что в нем нет сухой статистики о
              деятельности компании, и это привлекает большее количество
              аудитории.
            </div>
            <div class="seo_text">
              Использование нестандартных подходов, таких как неожиданные
              сюжетные повороты, твисты, повышает вероятность сделать ролик
              вирусным.
            </div>
            <div class="seo_text">
              Сжатый хронометраж позволяет быстро распространить ролик в сети,
              так как чем ролик короче, тем больше аудитории досмотрит его до
              конца.
            </div>
            <div class="seo_text">
              Чтобы заинтересовать аудиторию, нужно сделать ролик оригинальным и
              отличным от работ конкурентов, этого можно достичь благодаря
              интересному и захватывающему сюжету.
            </div>
          </div>
        </div>

        <div class="seo_price seo_section">
          <div class="container_mini">
            <h4 class="seo_title">Стоимость создания имиджевого ролика</h4>
            <div class="seo_text">
              Стоимость создания имиджевого ролика зависит от нескольких
              переменных:
            </div>
            <div class="seo_text">
              <ul>
                <li>Сложность съемочного процесса (количество локаций и др.);</li>
                <li>Количество занятых актеров и персонала (чем больше, тем выше цена);</li>
                <li>Наполненность графикой и визуальными эффектами;</li>
                <li>Хронометраж.</li>
              </ul>
            </div>
            <div class="seo_text">
              Стоимость создания имиджевого ролика напрямую зависит от концепции
              и сценария, поэтому более точную оценку возможно получить после
              консультации со специалистами Black & White.
            </div>
            <div>
              <a href="/contacts" target="_blank" class="seo_button bold">Узнать</a>
            </div>
          </div>
        </div>

        <div class="seo_section">
          <div class="container_mini">
            <h4 class="seo_title">
              Почему важно заказать имиджевый ролик у Black & White?
            </h4>
          </div>
          <div class="seo_privilege">
            <div class="seo_privilege_item">
              <div class="seo_mini_title">1. Опыт</div>
              <div class="seo_text">
                Компания Black&White известна в Казахстане и за его пределами
                своими имиджевыми роликами и проектами, многие из которых
                удостоены <a class='seo_link' href="/awards/" target="_blank">наград</a> профессионального сообщества.
              </div>
            </div>
            <div class="seo_privilege_item">
              <div class="seo_mini_title">2. Качество</div>
              <div class="seo_text">
                Компания Black&White успешно работает с 2013 года и дорожит
                своей репутацией. Мы работаем только с лучшими специалистами —
                настоящими профессионалами, многие из работают в
                кинопроизводстве.
              </div>
            </div>
            <div class="seo_privilege_item">
              <div class="seo_mini_title">3. Креативность</div>
              <div class="seo_text">
                Мы убеждены, что фундамент хорошего имиджевого ролика — это его
                идея, сценарий и стилистика. Мы хотим создать именно тот имидж
                компании, о котором вы всегда мечтали, и который соответствует
                Вашему статусу на рынке.
              </div>
            </div>
          </div>
          <div class="container_mini">
            <div class="seo_text">
                Остались вопросы? Напишите нам, и мы обязательно ответим!
            </div>
          </div>
        </div>
      </div>
    </section>

    <form-block @onformsend="sendContactsForm" />

    <div class="seo_modal">
      <div class="seo_modal-wrap" v-if="modalOpened">
        <iframe
          :src="'https://player.vimeo.com/video/396916739' + '?title=0&byline=0&portrait=0&autoplay=1'"
          style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
          class="seo_modal-video"
          frameborder="0"
          allow="autoplay;fullscreen"
          allowfullscreen
        >
        </iframe>
        <img
          src="@/assets/img/icons/close.svg"
          alt=""
          class="seo_modal-close"
          @click="closeModal"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import FormBlock from "../components/FormBlock";

Swiper.use([Navigation]);

export default {
  name: "imagepage",
  components: {
    FormBlock,
  },
  mounted() {
    this.setMetaInfo({
      page: "imagepage",
    });

    new Swiper(".seo_about_slider", {
      loop: true,
      spaceBetween: 30,
      slidesPerView: 1,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 1,
        },
      },
    });
    new Swiper(".seo_create_slider", {
      loop: false,
      spaceBetween: 30,
      slidesPerView: 3,
      breakpoints: {
        320: {
          slidesPerView: "auto",
          spaceBetween: 10,
          centeredSlides: true,
        },
        600: {
          slidesPerView: 3,
        },
      },
    });
  },
  data() {
    return {
      modalOpened: false,
      selectedService: 0,
      isactive: false,
      swiper: "",
      sliderImages: [
        {
          title: "В ожидании ЭКСПО",
          text: "Имиджевый ролик о самом ярком событии 2017 года в Казахстане.",
          link: "/projects/v-ozhidanii-expo/",
          src: require("@/assets/img/pages/seopages/imagepage/imagepage_1.jpg"),
          alt: "",
        },
        {
          title: "На благо страны",
          text: "В любое время года, время суток и в любую погоду — они работают на благо страны.",
          link: "/projects/na-blago-strany/",
          src: require("@/assets/img/pages/seopages/imagepage/imagepage_2.jpg"),
          alt: "",
        },
        {
          title: "Smart Aqkol",
          text: "Всего в 100 километрах от Нур-Султана появился первый в Казахстане умный город — Smart Aqkol.",
          link: "/projects/seriya-videorolikov-smart-aqkol/",
          src: require("@/assets/img/pages/seopages/imagepage/imagepage_3.jpg"),
          alt: "",
        },
        {
          title: "Творим историю вместе",
          text: "Приходи поддержать родную команду — Футбольный клуб «Астана»!",
          link: "/projects/tvorim-istoriyu-vmeste-c-fk-astana/",
          src: require("@/assets/img/pages/seopages/imagepage/imagepage_4.jpg"),
          alt: "",
        },
      ],
      preProduction: [
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_1.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_2.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_3.png"),
        },
      ],
      production: [
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_4.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_5.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_6.png"),
        },
      ],
      postProduction: [
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_7.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_8.png"),
        },
        {
          src: require("@/assets/img/pages/seopages/socialpage/social_create_9.png"),
        },
      ],
      tabsMenu: [
        {
          tabsMenuItem: "Цель имиджевого ролика",
        },
        {
          tabsMenuItem: "Преимущества имиджевого ролика",
        },
        {
          tabsMenuItem: "Кому подходит",
        },
        {
          tabsMenuItem: "Какие задачи решает",
        },
      ],
      tabsItem: [
        {
          tabsItemText_1: "ПРОНИКНУТЬСЯ КУЛЬТУРОЙ КОМПАНИИ.",
          tabsItemText_2:
            "Повысить имидж компании и доверие аудитории. Имиджевый ролик не столько рассказывает о компании, сколько передаёт её корпоративный дух, стиль, атмосферу.",
        },
        {
          tabsItemText_1:
            "1. Художественность: использование визуальных образов, кинематографический стиль",
          tabsItemText_2:
            "2. Эмоциональность: создание ярких эмоций, отклик зрителя",
          tabsItemText_3:
            "3. Сценарная гибкость: оригинальные идеи, которые работают намного лучше шаблонов",
        },
        {
          tabsItemText_1:
            "Имиджевый ролик необходим тем, кто хочет повысить имидж компании и доверие аудитории. Особенно это важно для крупных брендов и компаний, кто планирует стратегическое устойчивое развитие.",
        },
        {
          tabsItemText_1: "1. Повышение имиджа компании и доверия аудитории",
          tabsItemText_2:
            "2. Выпуск новой продукции или расширение списка услуг",
          tabsItemText_3: "3. Подготовка к мероприятию, выставке",
          tabsItemText_4: "4. Поиск новых сотрудников (работа на HR-бренд)",
          tabsItemText_5: "5. Создание информационного повода",
          tabsItemText_6: "6. Ребрендинг",
          tabsItemText_7: "7. Новый этап развития бизнеса",
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.modalOpened = true;
    },
    closeModal() {
      this.modalOpened = false;
    },
    selectService(index) {
      this.selectedService = index;
    },
    ...mapMutations(["setMetaInfo"]),

    // Форма заявки, обязательно добавлять страницу и url
    ...mapActions(["sendContactsMainpage"]),
    sendContactsForm(data) {
      Object.assign(data, { pageUrl: "/imidzhevyj-videorolik" });
      Object.assign(data, { page: "Имиджевый видеоролик" });
      this.sendContactsMainpage(data);
    },
  },
};
</script>

<style lang="scss" scoped></style>