<template>
    <div class="error">
        <div class="container">
            <div class="row">
                
                <div class="col-12">
                    <div class="error__imagewrap">
                        <img src="@/assets/img/pages/error/error-picture.jpg" alt="" class="error__image">
                        <p class="error__number" v-html="$t('messages.notfound.title')"></p>
                    </div>
                </div>

                <div class="col-10 col-start-2">
                    <div class="error__titlewrap">
                        <h1 class="error__title" v-html="$t('messages.notfound.message')"></h1>
                    </div>
                    <div class="error__btnwrap">
                        <appbtn  :text="$t('messages.notfound.btntext')" @clickHandler="()=>this.$router.push('/')" />
                    </div>
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    import Appbtn from '../../components/ui/AppBtn'

    export default {
        name:'Error pages',
        components:{
            Appbtn
        },
        created(){
            this.switchOffIsmain(false)
        },
        mounted(){
            this.setMetaInfo({
                page:'errorpage'
            })
        },
        methods:{
            ...mapMutations([
                'switchOffIsmain',
                'setMetaInfo',
            ])
        },
    }
</script>

<style lang="scss" scoped>

</style>