<template>
    <div class="clientsblock" id="clientsmain">
        <div class="container">
            <div class="row">
                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">

                    <div class="clientsblock__header">
                        <Title :text=" $t('messages.mainpage.clients1') " class="clientsblock__title"/>
                        <Link route="/clients/" class="clientsblock__link" :text=" $t('messages.mainpage.clients2') "/>
                    </div>

                    <div class="clientsblock__row">
                        <a :href="item.LINK" :title="item.NAME" target="_blank" v-for="(item , index) in data" :key="index" rel="nofollow">
                            <img v-if="light" :src="item.LOGO_BLACK" alt="" class="clientsblock__row-item">
                            <img v-else :src="item.LOGO_WHITE" alt="" class="clientsblock__row-item">
                        </a>

                    </div>

                    <div class="clientsblock__mobile">
                        <Link route="/clients/" :text=" $t('messages.mainpage.clients2') "/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Title from './Title'
    import Link from './Link'

    export default {
        name:'ClientsBlock',
        components:{
            Title,
            Link,
        },
        props:{
            light:{
                type:Boolean,
                required:false,
                default:false,
            },
            data:{
                type:Array,
                required:false,
                default:()=>[]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .clientsblock{
        position: relative;

        width: 100%;
        height: auto;

        margin-bottom: 142px;

        &__mobile{
            display: none;
        }

        &__header{
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:flex-end;

            margin-bottom: 92.27px;

            overflow: hidden;
        }

        &__row{
           display: grid;
           grid-template: auto / repeat(6, 1fr);
           gap: 60px 30px;

            margin-bottom: 76px;

            a{
                position: relative;

                display:flex;
                flex-direction:row;
                justify-content:center;
                align-items:center;

                overflow: hidden;
            }

            &-item{
                height: 76px;

                max-width: 173px;
                width: auto;
                object-fit: contain;
            }

            &:last-child{
                margin-bottom: unset;
            }
        }
    }


    @media (max-width: 1600.98px){
        .clientsblock{

            &__row{
                grid-template: auto / repeat(4, 1fr);
            }
        }
    }

    @media (max-width: 1366.98px){
        .clientsblock{
            margin-bottom: 112px;

            &__header{
                margin-bottom: 38px;
            }

            &__row{

                &-item{
                    height: 66px;
                    max-width: 153px;
                }
            }
        }
    }

    @media (max-width: 768.98px){
        .clientsblock{
            margin-bottom: 129px;

            &__row{
                grid-template: auto / repeat(3, 1fr) ;
                gap: 20px 20px;
            }
        }
    }

    @media (max-width: 480.98px){
        .clientsblock{

            &__mobile{
                display: block;

                padding: 37.61px 20px 0px;
            }

            &__header{
                margin-bottom: 75.38px;

                .link{
                    display: none;
                }
            }

            &__row{
                grid-template: auto / repeat(2, auto);
                gap:83px 30px;

                &-item{
                    max-width: 123px;
                }
            }
        }
    }
</style>