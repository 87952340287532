<template>
    <transition name="fade">
        <div class="vimeomodal" v-if="opened">
            <iframe :src="'https://player.vimeo.com/video/'+video+'?title=0&byline=0&portrait=0&autoplay=1'" 
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                    class="vimeomodal__video"
                    frameborder="0" 
                    allow="autoplay;fullscreen" 
                    allowfullscreen></iframe>
            <img src="@/assets/img/icons/close.svg" alt="" class="vimeomodal__close" @click="closeModal">
        </div>
    </transition>
</template>

<script>
    export default {
        name:'VideoModal',
        props:{
            opened:{
                type:Boolean,
                required:false,
                default:false,
            },
            video:{
                type:String,
                required:false,
                default:'',
            }
        },
        methods:{
            closeModal(){
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .vimeomodal{
        position: fixed;
        left:0;
        top:0;

        width: 100%;
        height: 100%;

        z-index: 102;

        &::before{
            content:'';
            position: absolute;
            left:0;
            top:0;

            width: 100%;
            height: 100%;

            background-color: #000;
        }

        &__close{
            position: absolute;
            right: 20px;
            top:20px;

            height: 30px;
            width: auto;

            cursor: pointer;
        }
    }
</style>