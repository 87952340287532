<template>
    <div class="tperson" @mouseenter="onHover(info.index)" @mouseleave="onLeave(info.index)">
        <div class="tperson__social" :class="info.index===hoveredIndex?'tperson__social_active':''">
            <div class="tperson__social-item" v-if="info.instagram">
                <a :href="info.instagram" target="_blank"><img src="@/assets/img/icons/instagram-team.svg" alt=""></a>
            </div>
            <div class="tperson__social-item" v-if="info.facebook">
                <a :href="info.facebook" target="_blank"><img src="@/assets/img/icons/facebook-team.svg" alt=""></a>
            </div>
            <div class="tperson__social-item" v-if="info.linkedin">
                <a :href="info.linkedin" target="_blank"><img src="@/assets/img/icons/linkedin-team.svg" alt=""></a>
            </div>
        </div>

        <img :src="info.srcActive" 
             alt="" 
             class="tperson__image" 
             :class="info.index===hoveredIndex?'tperson__image--hide':''"
             >
       <!--  <img :src="info.srcActive" 
             alt="" 
             class="tperson__image  tperson__image--secondary" 
             :class="info.index===hoveredIndex?'tperson__image--active':''"
             > -->

        <div class="tperson__overlay" :class="info.index===hoveredIndex?'tperson__overlay--active':''"></div>
        
        <div class="tperson__des"  :class="info.index===hoveredIndex?'tperson__des--active':''">
            <p class="tperson__name" v-html="info.name"></p>
            <p class="tperson__position" v-html="info.position"></p>
            <!-- <p class="tperson__text" v-html="info.text"></p> -->
        </div>

    </div>
</template>

<script>   
    import {
        mapGetters,
    } from 'vuex'

    export default {
        name:'TeamPerson',
        props:{
            info:{
                type:Object,
                required:false,
                default:()=>{}
            },
        },
        computed:{
            ...mapGetters(['getIsMobile'])
        },
        data(){
            return{
                hoveredIndex:null,
            }
        },
        methods:{
            onHover(index){
                if(!this.getIsMobile){
                    this.hoveredIndex = index
                }
            },
            onLeave(){
                if(!this.getIsMobile){
                    this.hoveredIndex = null
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>