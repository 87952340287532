<template>
    <div class="pteam" :class=" 'pteam_theme_'+theme ">

        <div class="pteam__titlewrap">
            <h2 class="pteam__title" v-html="$t('messages.projectdetail.team')" ></h2>
        </div>

        <div class="pteam__persons">

            <div class="pteam__persons-item"
                 v-for="(person,index) in persons"
                 :key="index"
                 >

                 <p class="pteam__persons-item-title" v-html="person.status"></p>
                 <p class="pteam__persons-item-text" 
                    v-for="(item,index) in person.list" 
                    :key="index"
                    v-html="item"></p>

            </div>

            
        </div>

        <div class="pteam__line"></div>

        <div class="pteam__info">

            <div class="pteam__info-item" v-for="(item,index) in info" :key="index">
                <p class="pteam__info-item-title" v-html="item.status"></p>
                <p class="pteam__info-item-text" v-for="(item,index) in item.list" :key="index" v-html="item"></p>
            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name:'ProjectTeam',
        props:{
            persons:{
                type:Array,
                required:false,
                default:()=>[]
            },
            info:{
                type:Array,
                required:false,
                default:()=>[]
            },
            theme:{
                type:String,
                required:false,
                default:'regular',
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>