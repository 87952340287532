<template>
    <div class="clients">

        <div class="container">
            <div class="row">

                <div class="col-10 col-start-2">
                    <div class="app__titlewrap">
                        <h1 class="app__title" v-html="getClientsTitle"></h1>
                    </div>

                    <div class="app__pretitlewrap">
                        <p class="app__pretitle" v-html="getClientsDescription"></p>
                    </div>

                </div>

                <div class="col-10 col-start-2">
                    <clients-list :elements="getClientsInfo" mode="ref" />
                </div>

            </div>
        </div>
        
    </div>
</template>

<script>   
    import {mapMutations, mapActions, mapGetters} from 'vuex'

    import ClientsList from "./components/ClientsList"
    
    export default {
        name:'Clients',
        components:{
            ClientsList
        },
        computed:{
            ...mapGetters([
                'getClientsTitle',
                'getClientsDescription',
                'getClientsInfo',
                'getCurrentLanguage',
            ])
        },
        watch:{
            getCurrentLanguage:function(){
                this.fetchClients()
            }
        },
        mounted(){
            this.fetchClients()
            this.setMetaInfo({
                page:'clients',
            })
        },
        methods:{
            ...mapActions([
                'fetchClients',
            ]),
            ...mapMutations([
                'setMetaInfo',
            ]),


        }
    }
</script>

<style lang="scss" scoped>

</style>