const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleVacancies = {
    state:{
        info:{},
        vacancies:{},
        vacanciesCount:0,
    },
    mutations:{

    },
    actions:{
        fetchVacancies({state, rootGetters}){
            let locale = rootGetters.getCurrentLanguageKey

            axios
                .post('https://api.blackandwhite.kz/bw/vacancies/?c=5&lang='+locale)
                .then((response)=>{
                    return response.data
                })
                .then((data)=>{
                    let {
                        info,
                        vacancies
                    }  = data;
                    
                    let {title, list} = vacancies

                    let listvacancies = []

                    state.info = info

                    state.vacancies.title = title;

                    if(list){
                        if(list.length>0){
    
                            list.forEach((item) => {
                                
                                let obj = {}
        
                                let {
                                    NAME:position,
                                    SORT:sort,
                                    LANG:lang,
                                    responsibilities,
                                    requirements,
                                    desirable,
                                    conditions,
                                    desc,
                                    image,
                                } = item
    
                                obj = {
                                    position,
                                    sort,
                                    lang,
                                    des:desc,
                                    responsibilities: parsetext(responsibilities, locale =='ru'?'Обязанности':'Responsibilities'),
                                    requirements:parsetext(requirements, locale =='ru'?'Требования':'Requirements'),
                                    desirable:parsetext(desirable, locale =='ru'?'Ключевые навыки':'Desirable'),
                                    conditions:parsetext(conditions, locale =='ru'?'Условия':'Conditions'),
                                    image,
                                }

                                listvacancies.push(obj)
                            })
    
                        }

                    }

                    let sortedArr = listvacancies.sort((a,b)=>{
                        return parseInt(a.SORT) - parseInt(b.SORT)
                    })

                    state.vacancies.list = sortedArr
                })
        },


        async fetchVacanciesLength({state, rootGetters}){
            let locale = rootGetters.getCurrentLanguageKey

            await axios
                .post('https://api.blackandwhite.kz/bw/vacancies/?c=5&lang='+locale)
                .then((response)=>{
                    return response.data.vacancies.list.length
                })
                .then((count)=>{
                    state.vacanciesCount = count
                })
            
        }
    },
    getters:{

        getVacanciesInfo(state){
            return state.info
        },
        
        getVacanciesList(state){
            return state.vacancies
        },

        getVacanciesCount(state){
            return state.vacanciesCount
        }
    }

}

function parsetext(obj,title){
    if(obj){
        let list = obj.split(';')

        return {
            title,
            list
        }
    } else{
        return null
    }

    
}

export default moduleVacancies