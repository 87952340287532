<template>
    <div class="ndetail">
        <!-- <script v-html="jsonld" type="application/ld+json"></script> -->
        <div class="container">
            <div class="row">
                <div class="col-11 col-start-2 col-sm-12 col-sm-start-1">
                    <div class="ndetail__titlewrap">
                        <h1
                            class="ndetail__title"
                            v-html="getBlogDetail.title"
                        ></h1>

                        <div class="ndetail__pbdate">
                            <p
                                class="ndetail__pbdate-date"
                                v-html="getBlogDetail.date"
                            ></p>
                            <p
                                class="ndetail__pbdate-time"
                                v-html="getBlogDetail.dateTime"
                            ></p>
                        </div>
                    </div>
                </div>

                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">
                    <div class="ndetail__announcewrap">
                        <p
                            class="ndetail__announce"
                            v-html="getBlogDetail.slogan"
                        ></p>
                    </div>
                </div>

                <div class="col-12 col-sm-12" v-if="getBlogDetail.image">
                    <div class="ndetail__imagewrap">
                        <img
                            :src="getBlogDetail.image"
                            alt=""
                            class="ndetail__image"
                        />
                    </div>
                </div>

                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">
                    <div
                        class="ndetail__content"
                        v-html="getBlogDetail.text"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "BlogDetail",
    data() {
        const jsonld = {
            "@context": "http://schema.org",
            "@type": "Article/BlogArticle/BlogPost/TechArticle",
            // headline: this.info.ELEMENT_META_TITLE
            //     ? this.info.ELEMENT_META_TITLE
            //     : "",
            // description: this.info.ELEMENT_META_DESCRIPTION,
            // datePublished: this.info.date ? this.info.split(".").join("-") : "",
            // articleBody: this.info.text,
            // wordcount: this.info.length,
        };
        return {
            jsonld,
            slider: [
                {
                    src: require("@/assets/img/pages/news/slider-1.jpg"),
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["getAboutInfo", "getBlogList", "getBlogDetail"]),
    },
    watch: {
        getBlogList: function (bloglist) {
            if (bloglist.length > 0) {
                bloglist.map((item) => {
                    if (item.router == this.$route.params.blogname) {
                        this.fetchBlogDetail({ id: item.id });
                    }
                });
            }
        },
        getBlogDetail: function (info) {
            this.setMetaInfoByParams({
                title: info.ELEMENT_META_TITLE,
                description: info.ELEMENT_META_DESCRIPTION,
                link: "https://bw.com.kz/blog/" + info.CODE + "/",
                image:
                    info.DETAIL_PICTURE_SRC == "https://api.blackandwhite.kz"
                        ? "https://bw.com.kz/public/social.jpg"
                        : info.DETAIL_PICTURE_SRC,
            });
        },
    },
    created() {
        this.getBlogList.map((item) => {
            if (item.router == this.$route.params.blogname) {
                this.fetchBlogDetail({ id: item.id });
            }
        });
    },

    methods: {
        ...mapActions(["fetchBlogDetail"]),

        ...mapMutations(["setMetaInfoByParams"]),
    },
};
</script>

<style lang="scss" scoped>
</style>