const ru = {
    messages: {
        mainpage: {
            clients1: "клиенты",
            clients2: "Посмотреть всех клиентов",

            about1: "фильм о нас",

            contacts1: "Понравилось?",
            contacts2: "давайте обсудим",
            contacts3: "Ваша заявка успешно отправлена",
            contacts4: "Жду звонка",
            contacts5:
                "Нажимая кнопку «Жду звонка», я даю свое согласие на обработку моих персональных данных",

            contacts6: "Ваше имя",
            contacts7: "E-mail",
            contacts8: "Телефон",

            projects1: "портфолио",
            projects2: "Посмотреть все проекты",

            report1: "Black & White в цифрах",

            main1: "Связаться с нами",
            main2: "смотреть showreel",

            footer: "Республика Казахстан, г. Астана, ул. Токпанова, 10, блок 3, этаж 2",
        },

        header: {
            // Оставить заявку
            //+7 717 279-30-88
            contacts: "Заказать видео",

            menu0: "Главная",
            menu1: "Услуги",
            menu2: "Проекты",
            menu3: "Вакансии",
            menu4: "Премьера",
            menu5: "Заявка на кастинг",
        },

        vacancies: {
            btn: "Связаться с нами",
            details: "Подробнее о вакансии",
        },

        contacts: {
            success: "Ваша заявка успешно отправлена",
            name: "Фамилия Имя Отечество",
            phone: "Номер телефона",
            message: "сообщение",
            send: "отправить",

            error0: "Заполните все поля",
            error1: "Заполните поле для имени",
            error2: "Заполните поле для телефона",
            error3: "Напишите корректный номер телефона",
            error4: "Напишите корректный email",
            subject: "Сообщение с формы на сайте bw.com.kz",

            address:
                "Республика Казахстан, г. Астана, ул. Токпанова, 10, блок 3, этаж 2",
            callback: "Заказать звонок",
            notification: "В течение 10 минут с Вами свяжется менеджер",
            notification1:
                "Наш менеджер свяжется с Вами завтра в рабочее время",
            notification2:
                "Менеджер свяжется с Вами в рабочее время (будние дни с 09:00 до 18:00)",
        },

        projectslist: {
            all: "Все проекты",
            download: "Загрузить еще",
        },

        projectdetail: {
            awards: "Награды:",
            team: "Команда проекта",
            similar: "Похожие проекты:",

            client: "Заказчик",
            year: "Год",
            format: "Формат",

            back: "Вернуться к проектам",
        },

        notfound: {
            title: "404",
            message: "вы потерялись ?",
            btntext: "вернуться на главную страницу",
        },

        services: {
            details: "Подробнее",
        },

        cookie: {
            warning1:
                "Чтобы пользоваться нашим сайтом было удобно, мы используем файлы cookies.",
            warning2: "Что это значит",

            title1: "как наш сайт использует файлы cookies?",
            text1: "Мы, как и другие сайты в интернете, используем файлы cookies. Это нужно нам для того, чтобы следить за нашими сервисами, знать, что они работают и не упали, анализировать данные и совершенствовать работу сайта.",

            title2: "что такое cookies файлы?",
            text2: "Это небольшие текстовые фрагменты сайта, которые временно сохраняются на вашем компьютере или мобильном устройстве, когда вы заходите на сайт. Файлы cookies не содержат никакой личной информации о вас!",

            title3: "какие cookiеs мы используем и с какой целью?",
            text3: "Cookie для статистики: нам важно знать, сколько человек смотрят контент на нашем сайте. На основе этих данных мы понимаем размер нашей аудитории, а также что мы делаем правильно и что неправильно.",

            title4: "что делать, если вы не хотите использовать cookies?",
            text4: "Вы можете отключить их в настройках безопасности вашего браузера. Эти настройки нужно применить во всех браузерах, которыми вы пользуетесь (на компьютере и в телефоне). В случае если вы решите отключить cookiеs, помните, часть функций сайта перестанут быть вам доступны или могут работать некорректно.",
            text5: "Если мы внесем изменения в правила использования файлов cookies, то разместим данную информацию на этой странице.",
        },

        specialprojects: {
            film: "Фильм о нас",
            play: "Посмотреть видео",
            slogan: "Мы предлагаем вам погрузиться в атмосферу офиса Black&White.",
            who: "Кто мы?",
            clients: "Кто наши клиенты?",
            principles: "Каким принципам следуем в работе?",
            people: "Рассказывают сотрудники компании.",
            analog: "аналогичные проекты",

            persons: [
                {
                    status: "автор сценария и режиссёр",
                    list: ["Ксения Придатченко"],
                },
                {
                    status: "ассистент операторов-постановщиков",
                    list: ["Куаныш Сансызбаев"],
                },
                {
                    status: "продюсер",
                    list: ["Айгерим Токаманова"],
                },
                {
                    status: "цветокоррекция",
                    list: ["Марлен Нурашев"],
                },
                {
                    status: "операторы-постановщики",
                    list: ["Айвар Кыдырбаев", "Жанибек Машрапов"],
                },
                {
                    status: "звукооператор",
                    list: ["Казбек Есмурзаев"],
                },
                {
                    status: "режиссёр монтажа",
                    list: ["Улан Камзин"],
                },
                {
                    status: "звукорежиссёр",
                    list: ["Диас Лестеги"],
                },
                {
                    status: "дизайнер и моушен-дизайнер",
                    list: ["Аян Кизабекова"],
                },
                {
                    status: "гримёр",
                    list: ["Толганай Абуталип"],
                },
                {
                    status: "автор заставки",
                    list: ["Тогжан Кемелова"],
                },
                {
                    status: "водитель",
                    list: ["Мухагали Байболганов"],
                },
                {
                    status: "редактор",
                    list: ["Жанель Жазетова"],
                },
            ],
        },
    },
};

export default ru;
