export default {
	mainpage: {
		title:
			"ᐉ Компания видеопроизводства «Black&White» •  Услуги профессиональной съемки видеороликов и фильмов в Нур-Султан (Астана)",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Компания видеопроизводства «Black&White» •  Услуги профессиональной съемки видеороликов и фильмов в Нур-Султан (Астана)",
			},
			{
				id: "desc",
				name: "description",
				content:
					"▷ Услуги по созданию профессиональных видеороликов и фильмов ➦  Создание имиджевых видеороликов и фильмов в Казахстане ⏩ Полный спектр услуг по производству видеопродукции для вашего бизнеса.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Компания видеопроизводства «Black&White» •  Услуги профессиональной съемки видеороликов и фильмов в Нур-Султан (Астана)",
			},
			{
				property: "og:description",
				content:
					"▷ Услуги по созданию профессиональных видеороликов и фильмов ➦  Создание имиджевых видеороликов и фильмов в Казахстане ⏩ Полный спектр услуг по производству видеопродукции для вашего бизнеса.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{
				property: "og:site_name",
				content:
					"Black&White - профессиональное производство видеороликов и фильмов",
			},

			{
				itemprop: "name",
				content:
					"ᐉ Компания видеопроизводства «Black&White» •  Услуги профессиональной съемки видеороликов и фильмов в Нур-Султан (Астана)",
			},
			{
				itemprop: "description",
				content:
					"▷ Услуги по созданию профессиональных видеороликов и фильмов ➦  Создание имиджевых видеороликов и фильмов в Казахстане ⏩ Полный спектр услуг по производству видеопродукции для вашего бизнеса.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Компания видеопроизводства «Black&White» •  Услуги профессиональной съемки видеороликов и фильмов в Нур-Султан (Астана)",
			},
			{
				name: "twitter:description",
				content:
					"▷ Услуги по созданию профессиональных видеороликов и фильмов ➦  Создание имиджевых видеороликов и фильмов в Казахстане ⏩ Полный спектр услуг по производству видеопродукции для вашего бизнеса.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{ name: "twitter:image:alt", content: "https://bw.com.kz/social.jpg" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz" }],
	},
	team: {
		title: "ᐉ Команда компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Команда компании видеопроизводства «Black&White»",
			},
			{
				id: "desc",
				name: "description",
				content:
					"▷ Команда компании производства видеопродукции «Black&White» ➦ Познакомьтесь с режиссерами, продюсерами, операторами, и другими сотрудниками компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},

			{
				property: "og:title",
				content: "ᐉ Команда компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Команда компании производства видеопродукции «Black&White» ➦ Познакомьтесь с режиссерами, продюсерами, операторами, и другими сотрудниками компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Команда компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Команда компании производства видеопродукции «Black&White» ➦ Познакомьтесь с режиссерами, продюсерами, операторами, и другими сотрудниками компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Команда компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Команда компании производства видеопродукции «Black&White» ➦ Познакомьтесь с режиссерами, продюсерами, операторами, и другими сотрудниками компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{ name: "twitter:image:alt", content: "Команда" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/team/" }],
	},
	vacancies: {
		title: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Список актуальных вакансий компании производства видео и фильмов «Black&White» ➦ Вакансии в нашу команду в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},

			{
				property: "og:title",
				content: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Список актуальных вакансий компании производства видео и фильмов «Black&White» ➦ Вакансии в нашу команду в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Список актуальных вакансий компании производства видео и фильмов «Black&White» ➦ Вакансии в нашу команду в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Список актуальных вакансий компании производства видео и фильмов «Black&White» ➦ Вакансии в нашу команду в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Вакансии в компании видеопроизводства «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/vacancies/" }],
	},
	services: {
		title:
			"ᐉ Услуги компании видеопроизводства «Black&White» • Заказать услуги компании профессиональной съемки видеороликов и фильмов в Казахстане",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Услуги компании видеопроизводства «Black&White» • Заказать услуги компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "description",
				content:
					"▷ Полный перечень услуг видеопроизводства ➦ предоставляемых компанией «Black&White» ⏩ Профессиональные услуги производства видеороликов и фильмов ⏩ Студия видеопроизводства полного цикла в Астане 🥇 Преимущества сотрудничества с нашей компанией.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Услуги компании видеопроизводства «Black&White» • Заказать услуги компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				property: "og:description",
				content:
					"▷ Полный перечень услуг видеопроизводства ➦ предоставляемых компанией «Black&White» ⏩ Профессиональные услуги производства видеороликов и фильмов ⏩ Студия видеопроизводства полного цикла в Астане 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content:
					"ᐉ Услуги компании видеопроизводства «Black&White» • Заказать услуги компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				itemprop: "description",
				content:
					"▷ Полный перечень услуг видеопроизводства ➦ предоставляемых компанией «Black&White» ⏩ Профессиональные услуги производства видеороликов и фильмов ⏩ Студия видеопроизводства полного цикла в Астане 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Услуги компании видеопроизводства «Black&White» • Заказать услуги компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "twitter:description",
				content:
					"▷ Полный перечень услуг видеопроизводства ➦ предоставляемых компанией «Black&White» ⏩ Профессиональные услуги производства видеороликов и фильмов ⏩ Студия видеопроизводства полного цикла в Астане 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{ name: "twitter:image:alt", content: "Услуги" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/services/" }],
	},
	projectlist: {
		title: "ᐉ Проекты компании «Black&White»",
		metaArray: [
			{ name: "application-name", content: "ᐉ Проекты компании «Black&White»" },
			{
				name: "description",
				content:
					"▷ Обзор проектов компании видеопроизводства «Black&White» ➦  Видео проекты компании ⏩ Примеры имиджевых, рекламных, социальных видео и документальных фильмов 🤝 Портфолио видеостудии.",
			},

			{ property: "og:title", content: "ᐉ Проекты компании «Black&White»" },
			{
				property: "og:description",
				content:
					"▷ Обзор проектов компании видеопроизводства «Black&White» ➦  Видео проекты компании ⏩ Примеры имиджевых, рекламных, социальных видео и документальных фильмов 🤝 Портфолио видеостудии.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{ itemprop: "name", content: "ᐉ Проекты компании «Black&White»" },
			{
				itemprop: "description",
				content:
					"▷ Обзор проектов компании видеопроизводства «Black&White» ➦  Видео проекты компании ⏩ Примеры имиджевых, рекламных, социальных видео и документальных фильмов 🤝 Портфолио видеостудии.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{ name: "twitter:title", content: "ᐉ Проекты компании «Black&White»" },
			{
				name: "twitter:description",
				content:
					"▷ Обзор проектов компании видеопроизводства «Black&White» ➦  Видео проекты компании ⏩ Примеры имиджевых, рекламных, социальных видео и документальных фильмов 🤝 Портфолио видеостудии.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Проекты компании «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/projects/" }],
	},
	contacts: {
		title: "ᐉ Контактные данные компании «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Контактные данные компании «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Контактная информация компании видеопроизводства «Black&White» ➦ Адреса, телефоны, форма обратной связи 🥇 У вас есть вопросы по услугам нашей компании?",
			},

			{
				property: "og:title",
				content: "ᐉ Контактные данные компании «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Контактная информация компании видеопроизводства «Black&White» ➦ Адреса, телефоны, форма обратной связи 🥇 У вас есть вопросы по услугам нашей компании?",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Контактные данные компании «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Контактная информация компании видеопроизводства «Black&White» ➦ Адреса, телефоны, форма обратной связи 🥇 У вас есть вопросы по услугам нашей компании?",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Контактные данные компании «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Контактная информация компании видеопроизводства «Black&White» ➦ Адреса, телефоны, форма обратной связи 🥇 У вас есть вопросы по услугам нашей компании?",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{ name: "twitter:image:alt", content: "Контакты" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/contacts/" }],
	},
	conceptions: {
		title: "ᐉ Порядок работы компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Порядок работы компании видеопроизводства «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Этапы работы компании видеопроизводства «Black&White» ➦ Общие условия сотрудничества ⏩ Условия съемки видеороликов ⏩ Срок выполнения работ 🥇 Преимущества сотрудничества с нашей компанией.",
			},

			{
				property: "og:title",
				content: "ᐉ Порядок работы компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Этапы работы компании видеопроизводства «Black&White» ➦ Общие условия сотрудничества ⏩ Условия съемки видеороликов ⏩ Срок выполнения работ 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Порядок работы компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Этапы работы компании видеопроизводства «Black&White» ➦ Общие условия сотрудничества ⏩ Условия съемки видеороликов ⏩ Срок выполнения работ 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Порядок работы компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Этапы работы компании видеопроизводства «Black&White» ➦ Общие условия сотрудничества ⏩ Условия съемки видеороликов ⏩ Срок выполнения работ 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{ name: "twitter:image:alt", content: "Как мы работаем" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/conceptions/" }],
	},
	about: {
		title: "ᐉ Информация о компании «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Информация о компании «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Подробная информация о компании «Black&White» ➦  Профессиональное производство видеороликов и фильмов ⏩ Команда специалистов 🥇 Преимущества сотрудничества с нашей компанией.",
			},

			{
				property: "og:title",
				content: "ᐉ Информация о компании «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Подробная информация о компании «Black&White» ➦  Профессиональное производство видеороликов и фильмов ⏩ Команда специалистов 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{ itemprop: "name", content: "ᐉ Информация о компании «Black&White»" },
			{
				itemprop: "description",
				content:
					"▷ Подробная информация о компании «Black&White» ➦  Профессиональное производство видеороликов и фильмов ⏩ Команда специалистов 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Информация о компании «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Подробная информация о компании «Black&White» ➦  Профессиональное производство видеороликов и фильмов ⏩ Команда специалистов 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Информация о компании «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/about/" }],
	},
	awards: {
		title: "ᐉ Награды компании «Black&White»",
		metaArray: [
			{ name: "application-name", content: "ᐉ Награды компании «Black&White»" },
			{
				name: "description",
				content:
					"▷ Подробная информация о  наградах компании «Black&White» ➦  Награды и признание за нашу работу ⏩ Профессионализм и статус нашей компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},

			{ property: "og:title", content: "ᐉ Награды компании «Black&White»" },
			{
				property: "og:description",
				content:
					"▷ Подробная информация о  наградах компании «Black&White» ➦  Награды и признание за нашу работу ⏩ Профессионализм и статус нашей компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{ itemprop: "name", content: "ᐉ Награды компании «Black&White»" },
			{
				itemprop: "description",
				content:
					"▷ Подробная информация о  наградах компании «Black&White» ➦  Награды и признание за нашу работу ⏩ Профессионализм и статус нашей компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{ name: "twitter:title", content: "ᐉ Награды компании «Black&White»" },
			{
				name: "twitter:description",
				content:
					"▷ Подробная информация о  наградах компании «Black&White» ➦  Награды и признание за нашу работу ⏩ Профессионализм и статус нашей компании 🥇 Преимущества сотрудничества с нашей компанией.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Награды компании «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/awards/" }],
	},
	about_us: {
		title:
			"ᐉ Премьера фильма о компании «Black&White» • Имиджевый видеоролик компании профессиональной съемки видеороликов и фильмов в Казахстане",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Премьера фильма о компании «Black&White» • Имиджевый видеоролик компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "description",
				content:
					"▷ Фильм о компании видеопроизводства «Black&White» ➦  Миссия и ценности компании ⏩  Кто наши клиенты 🤝 Каким принципам следуем в работе 🥇 Предлагаем вам погрузиться в атмосферу офиса Black&White.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Премьера фильма о компании «Black&White» • Имиджевый видеоролик компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				property: "og:description",
				content:
					"▷ Фильм о компании видеопроизводства «Black&White» ➦  Миссия и ценности компании ⏩  Кто наши клиенты 🤝 Каким принципам следуем в работе 🥇 Предлагаем вам погрузиться в атмосферу офиса Black&White.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content:
					"ᐉ Премьера фильма о компании «Black&White» • Имиджевый видеоролик компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				itemprop: "description",
				content:
					"▷ Фильм о компании видеопроизводства «Black&White» ➦  Миссия и ценности компании ⏩  Кто наши клиенты 🤝 Каким принципам следуем в работе 🥇 Предлагаем вам погрузиться в атмосферу офиса Black&White.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Премьера фильма о компании «Black&White» • Имиджевый видеоролик компании профессиональной съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "twitter:description",
				content:
					"▷ Фильм о компании видеопроизводства «Black&White» ➦  Миссия и ценности компании ⏩  Кто наши клиенты 🤝 Каким принципам следуем в работе 🥇 Предлагаем вам погрузиться в атмосферу офиса Black&White.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "Премьера фильма о компании «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/about-us/" }],
	},
	news: {
		title: "ᐉ Новости компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Новости компании видеопроизводства «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},

			{
				property: "og:title",
				content: "ᐉ Новости компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Новости компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Новости компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Новости компании видеопроизводства «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/news/" }],
	},
	clients: {
		title: "ᐉ Клиенты компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Клиенты компании видеопроизводства «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Раздел клиентов компании производства видео и фильмов «Black&White» ➦ Компании, которые нам доверяют ⏩ Предприятия и компании с которыми мы работали и продолжаем сотрудничество.",
			},

			{
				property: "og:title",
				content: "ᐉ Клиенты компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Раздел клиентов компании производства видео и фильмов «Black&White» ➦ Компании, которые нам доверяют ⏩ Предприятия и компании с которыми мы работали и продолжаем сотрудничество.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Клиенты компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Раздел клиентов компании производства видео и фильмов «Black&White» ➦ Компании, которые нам доверяют ⏩ Предприятия и компании с которыми мы работали и продолжаем сотрудничество.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Клиенты компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Раздел клиентов компании производства видео и фильмов «Black&White» ➦ Компании, которые нам доверяют ⏩ Предприятия и компании с которыми мы работали и продолжаем сотрудничество.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Клиенты компании видеопроизводства «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/clients/" }],
	},
	castings: {
		title:
			"ᐉ Регистрация на кастинг в проекты компании «Black&White» • Анкета заявки на кастинг для съемки видеороликов и фильмов в Казахстане",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Регистрация на кастинг в проекты компании «Black&White» • Анкета заявки на кастинг для съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "description",
				content:
					"▷ Кастинги проектов компании производства видео и фильмов «Black&White» ➦ Анкета для участия в кастингах и съемках в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Регистрация на кастинг в проекты компании «Black&White» • Анкета заявки на кастинг для съемки видеороликов и фильмов в Казахстане",
			},
			{
				property: "og:description",
				content:
					"▷ Кастинги проектов компании производства видео и фильмов «Black&White» ➦ Анкета для участия в кастингах и съемках в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content:
					"ᐉ Регистрация на кастинг в проекты компании «Black&White» • Анкета заявки на кастинг для съемки видеороликов и фильмов в Казахстане",
			},
			{
				itemprop: "description",
				content:
					"▷ Кастинги проектов компании производства видео и фильмов «Black&White» ➦ Анкета для участия в кастингах и съемках в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Регистрация на кастинг в проекты компании «Black&White» • Анкета заявки на кастинг для съемки видеороликов и фильмов в Казахстане",
			},
			{
				name: "twitter:description",
				content:
					"▷ Кастинги проектов компании производства видео и фильмов «Black&White» ➦ Анкета для участия в кастингах и съемках в Нур-Султан (Астана) ⏩ Просмотрите карьерные возможности в сфере производства видеопродукции.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Регистрация на кастинг в проекты компании «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/castings/" }],
	},
	errorpage: {
		title: "404",
		metaArray: [
			{ name: "application-name", content: "404" },
			{ name: "description", content: "Упс! Что-то пошло не так" },

			{ name: "robots", content: "noindex" },

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/404/" }],
	},
	videos: {
		title:
			"ᐉ Заказать имиджевый видеоролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Заказать имиджевый видеоролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "description",
				content:
					"▷ Создание имиджевого ролика о компании ➦ Съемки имиджевых видеороликов в Казахстане ⏩ Стоимость видеосъемки социальных видео в Астане ⏩ Услуги по созданию профессионального имиджевого ролика компании.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Заказать имиджевый видеоролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
			},
			{
				property: "og:description",
				content:
					"▷ Создание имиджевого ролика о компании ➦ Съемки имиджевых видеороликов в Казахстане ⏩ Стоимость видеосъемки социальных видео в Астане ⏩ Услуги по созданию профессионального имиджевого ролика компании.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "Имиджевый видеоролик",
			},
			{
				itemprop: "description",
				content:
					"▷ Создание имиджевого ролика о компании ➦ Съемки имиджевых видеороликов в Казахстане ⏩ Стоимость видеосъемки социальных видео в Астане ⏩ Услуги по созданию профессионального имиджевого ролика компании.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Заказать имиджевый видеоролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "twitter:description",
				content:
					"▷ Создание имиджевого ролика о компании ➦ Съемки имиджевых видеороликов в Казахстане ⏩ Стоимость видеосъемки социальных видео в Астане ⏩ Услуги по созданию профессионального имиджевого ролика компании.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "Имиджевый видеоролик",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/castings/" }],
	},
	films: {
		title:
			"ᐉ Заказать корпоративный фильм в «Black&White» • Услуги профессиональной съемки корпоративных фильмов Нур-Султан (Астана), Казахстан",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Заказать корпоративный фильм в «Black&White» • Услуги профессиональной съемки корпоративных фильмов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "description",
				content:
					"▷ Создание корпоративного фильма ➦ Производство корпоративных фильмов в Казахстане ⏩ Стоимость видеосъемки",
			},

			{
				property: "og:title",
				content:
					"ᐉ Заказать корпоративный фильм в «Black&White» • Услуги профессиональной съемки корпоративных фильмов Нур-Султан (Астана), Казахстан",
			},
			{
				property: "og:description",
				content:
					"▷ Создание корпоративного фильма ➦ Производство корпоративных фильмов в Казахстане ⏩ Стоимость видеосъемки",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "Корпоративный фильм",
			},
			{
				itemprop: "description",
				content:
					"▷ Создание корпоративного фильма ➦ Производство корпоративных фильмов в Казахстане ⏩ Стоимость видеосъемки",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Заказать корпоративный фильм в «Black&White» • Услуги профессиональной съемки корпоративных фильмов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "twitter:description",
				content:
					"▷ Создание корпоративного фильма ➦ Производство корпоративных фильмов в Казахстане ⏩ Стоимость видеосъемки",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "Корпоративный фильм",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ href: "https://bw.com.kz/korporativnyj-film/" }],
	},
	animations: {
		title:
			"ᐉ Заказать анимационный видеоролик в «Black&White» • Услуги профессионального изготовления анимационных роликов Нур-Султан (Астана), Казахстан",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Заказать анимационный видеоролик в «Black&White» • Услуги профессионального изготовления анимационных роликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "description",
				content:
					"▷ Создание анимационного видео и инфографики ➦ Разработка анимационных видеороликов в Казахстане ⏩ Стоимость изготовления анимации инфографики в Астане ⏩ Услуги по созданию профессионального анимационного видео.",
			},

			{
				property: "og:title",
				content:
					"ᐉ Заказать анимационный видеоролик в «Black&White» • Услуги профессионального изготовления анимационных роликов Нур-Султан (Астана), Казахстан",
			},
			{
				property: "og:description",
				content:
					"▷ Создание анимационного видео и инфографики ➦ Разработка анимационных видеороликов в Казахстане ⏩ Стоимость изготовления анимации инфографики в Астане ⏩ Услуги по созданию профессионального анимационного видео.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "Инфографика и анимация",
			},
			{
				itemprop: "description",
				content:
					"▷ Создание анимационного видео и инфографики ➦ Разработка анимационных видеороликов в Казахстане ⏩ Стоимость изготовления анимации инфографики в Астане ⏩ Услуги по созданию профессионального анимационного видео.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content:
					"ᐉ Заказать анимационный видеоролик в «Black&White» • Услуги профессионального изготовления анимационных роликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "twitter:description",
				content:
					"▷ Создание анимационного видео и инфографики ➦ Разработка анимационных видеороликов в Казахстане ⏩ Стоимость изготовления анимации инфографики в Астане ⏩ Услуги по созданию профессионального анимационного видео.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "Инфографика и анимация",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/castings/" }],
	},
	blog: {
		title: "ᐉ Блог компании видеопроизводства «Black&White»",
		metaArray: [
			{
				name: "application-name",
				content: "ᐉ Блог компании видеопроизводства «Black&White»",
			},
			{
				name: "description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},

			{
				property: "og:title",
				content: "ᐉ Блог компании видеопроизводства «Black&White»",
			},
			{
				property: "og:description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{ property: "og:site_name", content: "https://bw.com.kz" },

			{
				itemprop: "name",
				content: "ᐉ Блог компании видеопроизводства «Black&White»",
			},
			{
				itemprop: "description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },

			{ name: "twitter:card", content: "summary_large_image" },
			{
				name: "twitter:title",
				content: "ᐉ Блог компании видеопроизводства «Black&White»",
			},
			{
				name: "twitter:description",
				content:
					"▷ Новостной блог и интересные статьи компании «Black&White» ➦ Полезные и интересные статьи о производстве видеороликов и фильмов ⏩ Самое интересное о жизни нашей компании и наших достижениях.",
			},
			{ name: "twitter:image:src", content: "https://bw.com.kz/social.jpg" },
			{
				name: "twitter:image:alt",
				content: "ᐉ Блог компании видеопроизводства «Black&White»",
			},

			{ name: "fragment", content: "!" },
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/blog/" }],
	},
	socialpage: {
		title:
			"ᐉ Заказать социальный ролик в «Black&White» • Услуги профессиональной съемки социальных видеороликов Нур-Султан (Астана), Казахстан",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Заказать социальный ролик в «Black&White» • Услуги профессиональной съемки социальных видеороликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "description",
				content:
					"▷ Создание социального ролика ➦ Съемки социальных видеороликов в Казахстане  Стоимость видеосъемки социальных видео в Астане  Услуги по созданию профессионального социального ролика.",
			},
		],
		link: [
			{ rel: "canonical", href: "https://bw.com.kz/socialnyj-videorolik/" },
		],
	},
	imagepage: {
		title:
			"ᐉ Заказать имиджевый ролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
		metaArray: [
			{
				name: "application-name",
				content:
					"ᐉ Заказать имиджевый ролик в «Black&White» • Услуги профессиональной съемки имиджевых видеороликов Нур-Султан (Астана), Казахстан",
			},
			{
				name: "description",
				content:
					"▷ Создание имиджевого ролика ➦ Съемки имиджевых видеороликов в Казахстане  Стоимость видеосъемки имиджевых видео в Астане  Услуги по созданию профессионального имиджевого ролика.",
			},
		],
		link: [
			{ rel: "canonical", href: "https://bw.com.kz/imidzhevyj-videorolik/" },
		],
	},
	rental: {
		title: "ᐉ Аренда оборудования «Black&White» ",
		metaArray: [
			{
				name: "application-name",
				content:
					"Black & White Production — один из лидеров видеопроизводства в Республике Казахстан. Мы обладаем собственным профессиональным кинооборудованием и готовы к сотрудничеству с профессионалами, которым необходима техника для съемок.",
			},
			{
				name: "description",
				content:
					"Black & White Production — один из лидеров видеопроизводства в Республике Казахстан. Мы обладаем собственным профессиональным кинооборудованием и готовы к сотрудничеству с профессионалами, которым необходима техника для съемок.",
			},
			{ itemprop: "image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:image", content: "https://bw.com.kz/social.jpg" },
			{ property: "og:url", content: "https://bw.com.kz" },
			{
				property: "og:site_name",
				content:
					"Black&White - профессиональное производство видеороликов и фильмов",
			},
		],
		link: [{ rel: "canonical", href: "https://bw.com.kz/rental" }],
	},
};
