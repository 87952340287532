<template>
    <div class="totop" ref="totop" @mouseover="mouseoverHandler" @mouseleave="mouseleaveHandler">

        <div class="totop__iconwrap" @click="clickHandler"  v-if="!getTotopParams">
            <img v-if="getAppTheme" src="@/assets/img/icons/scroll--dark.svg" alt="" class="totop__icon">
            <img v-else src="@/assets/img/icons/scroll.svg" alt="" class="totop__icon">
        </div>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {
        TweenMax,
        Back,
        Power3,
    } from 'gsap'

    export default {
        name:'AppTopScroller',
        computed:{
            ...mapGetters([
                'getTotopParams',
                'getAppTheme',
            ]),
        },
        data(){
            return{
                scrolledFromTop:0,
                showed:false,
            }
        },
        mounted(){
            TweenMax.set(this.$refs.totop,{scale:0})
            this.scrollHandler()
        },
        methods:{
            scrollHandler(){
                window.onscroll = () =>{
                    let {
                        totop
                    } = this.$refs;

                    if(window.pageYOffset>=200&&!this.showed){
                        TweenMax.to(totop, .8, {
                            scale:1,
                            ease:Back.easeInOut,
                        })
                        
                        this.showed = true

                    } else if(window.pageYOffset<200&&this.showed){
                        
                        TweenMax.to(totop, .8, {
                            scale:0,
                            ease:Back.easeInOut,
                        })

                        this.showed=false
                    }
                }
            },

            clickHandler(){
                window.scrollTo({
                    top:'0',
                    behavior:'smooth'
                })
            },

            mouseoverHandler(){

                let {totop} = this.$refs

                TweenMax.to(totop, .6,{
                    css:{
                        alpha:1,
                        ease:Power3.easeInOut,
                    }
                })

            },

            mouseleaveHandler(){

                let {totop} = this.$refs

                TweenMax.to(totop, .6,{
                    css:{
                        alpha:.6,
                        ease:Power3.easeInOut,
                    }
                })

            }
        }
    }
</script>

<style lang="scss" scoped>

</style>