<template>
    <div class="vacancies">
        <div class="container">
            <div class="row">

                <div class="col-10 col-start-2">
                    <div class="app__titlewrap">
                        <h1 class="app__title" v-html="getVacanciesInfo.title"></h1>
                    </div>

                    <div class="vacancies__textwrap" v-if="getVacanciesInfo.mainInfo">
                        <h2 class="vacancies__pretitle" v-html="getVacanciesInfo.mainInfo.title"></h2>
                        <p class="vacancies__text" v-for="(item,index) in getVacanciesInfo.mainInfo.texts" :key="index" v-html="item"></p>
                    </div>

                    <div class="vacancies__textwrap" v-if="false">
                        <h2 class="vacancies__pretitle" v-html="getVacanciesInfo.footerInfo.title"></h2>
                        <p class="vacancies__text vacancies__text--nmb" v-for="(item,index) in getVacanciesInfo.footerInfo.texts" :key="index" v-html="item"></p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="container" v-if="getVacanciesList.list">
            <div class="row"> 
                <div class="col-10 col-start-2">
                    
                    <h2 class="vacancies__pretitle" v-html="getVacanciesList.title"></h2>

                </div>
            </div>
        </div>

        <div class="container container_xs_nopad"  v-if="getVacanciesList.list">
            <div class="row">
                <div class="col-12 col-start-2 col-xs-12 col-xs-start-1">

                    <div class="vacancies__cardwrap">
                        <vcard 
                            v-for="(item,index) in getVacanciesList.list"  
                            :key="index" 
                            :data="item"
                            @moreButtonHandler="openModal"
                        />
                    </div>

                </div>
            </div>
        </div>

        <transition name="fade" mode="in-out">
            <vmodal v-if="modalOpened" :opened="modalOpened" :data="modalData" @closeModal="closeModal"/>
        </transition>


        <!-- <div class="container">
            <div class="row">
                <div class="col-start-2 col-10">

                    <div class="vacancies__btnwrap">
                        <app-btn @clickHandler="$router.push('/contacts/')" :text="$t('messages.vacancies.btn')" />
                    </div>

                </div>
            </div>
        </div> -->


    </div>
</template>

<script>   
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    import  Vcard from './components/VacancyCard'
    import  Vmodal from './components/VacancyModal'

    // import AppBtn from "../../components/ui/AppBtn" 

    export default {
        name:'Vacancies',
        components:{
            Vcard,
            Vmodal,
            // AppBtn,
        },
        computed:{
            ...mapGetters([
                'getVacanciesInfo',
                'getVacanciesList',
                'getCurrentLanguage',
            ]),
        },
        mounted(){
            this.fetchVacancies()

            this.setMetaInfo({
                page:'vacancies'
            })
        },

        beforeDestroy(){
            document.querySelector('body').classList.remove('app--unscroll')
        },
        data(){
            return {
                modalOpened:false,
                modalData:{}
            }
        },
        watch:{
            getCurrentLanguage:function(){
                this.fetchVacancies()
            }
        },
        methods:{
            ...mapMutations([
                'setMetaInfo',
            ]),

            ...mapActions([
                'fetchVacancies',
            ]),

            openModal(data){
                this.modalOpened = true
                this.modalData = data

                document.querySelector('body').classList.add('app--unscroll')
            },
            
            closeModal(){
                this.modalOpened = false
                this.modalData = {}

                document.querySelector('body').classList.remove('app--unscroll')
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>