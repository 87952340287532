import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import moduleMain from "./modulesByPages/moduleMain";
import moduleProjects from "./modulesByPages/moduleProjects";
import moduleContacts from "./modulesByPages/moduleContacts";
import moduleAbout from "./modulesByPages/moduleAbout";
import moduleTeam from "./modulesByPages/moduleTeam";
import moduleServices from "./modulesByPages/moduleServices";
import moduleVacancies from "./modulesByPages/moduleVacancies";
import moduleAwards from "./modulesByPages/moduleAwards";
import moduleConceptions from "./modulesByPages/moduleConceptions";
import moduleNews from "./modulesByPages/moduleNews";
import moduleClients from "./modulesByPages/moduleClients";
import moduleSpecial from "./modulesByPages/moduleSpecial";
import moduleBlog from "./modulesByPages/moduleBlog";

import moduleMenu from "./modulesCommon/moduleMenu";
import moduleFullpage from "./modulesCommon/moduleFullpage";
import moduleCommon from "./modulesCommon/moduleCommon";
import moduleMeta from "./modulesCommon/moduleMeta";

Vue.use(Vuex);

const mainState = createPersistedState({
	paths: ["main", "common"],
});
const store = new Vuex.Store({
	modules: {
		about: moduleAbout,
		awards: moduleAwards,
		clients: moduleClients,
		conceptions: moduleConceptions,
		contacts: moduleContacts,
		main: moduleMain,
		news: moduleNews,
		projects: moduleProjects,
		services: moduleServices,
		special: moduleSpecial,
		team: moduleTeam,
		vacancies: moduleVacancies,
		blog: moduleBlog,
		common: moduleCommon,
		fullpage: moduleFullpage,
		menu: moduleMenu,
		meta: moduleMeta,
	},

	plugins: [mainState],
});

export default store;
