const moduleFullpage = {
    state:{
        currentIndex:0,
        currentText:'',
        scrolling:false,
        lastSection:false,
        length:null,
    },
    mutations:{
        
        onLeave(state,info){
            state.currentIndex = info.destination.index

            if(state.currentIndex !== (state.length-1)){
                state.lastSection = false;
            } 

            state.scrolling = true
        },
        
        afterLoad(state,info){
            state.currentIndex =info.destination.index;

            if(state.currentIndex === (state.length-1)){
                state.lastSection = true;
            } else{
                state.lastSection=false
            }

            state.scrolling = false
        },
        
        setElementsLength(state,info){
            state.length = info.length
        }
    },
    actions:{},
    getters:{
        
        getScrolling(state){
            return state.scrolling
        },

        isLastSection(state){
            return state.lastSection
        },


        getMainFullpageLength(state){
            return state.length
        },

        getMainActiveIndex(state){
            return state.currentIndex
        }
    },
}

export default moduleFullpage