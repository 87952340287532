const axios = require('axios')
axios.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest'

const moduleConceptions = {
    state:{
        inScroll:false,
        conceptions:[],
        activeIndex:0,
    },
    mutations:{
        
        onConceptionLeave(state,info){
            state.activeIndex = info.destination.index

            if(state.activeIndex !== (state.length-1)){
                state.lastSection = false;
            } 

            state.scrolling = true
        },
        
        afterConceptionLoad(state,info){
            state.activeIndex =info.destination.index;

            if(state.activeIndex === (state.length-1)){
                state.lastSection = true;
            } 

            state.scrolling = false
        },

        clearConceptions(state){
            state.conceptions = []
        }

        

    },
    actions:{

        fetchConceptions({state,rootGetters}){
            let locale = rootGetters.getCurrentLanguageKey

            axios
                .post('https://api.blackandwhite.kz/bw/conceptions/?c=10&lang='+locale)
                .then((response)=>{
                    return response.data.conceptions
                })
                .then((list)=>{
                    state.conceptions = []

                    let sortedArr = list.sort((a,b)=>{
                        return parseInt(a.SORT) - parseInt(b.SORT)
                    })

                    sortedArr.forEach((item, index) => {
                        let {
                            ID:id,
                            NAME:title,
                            PREVIEW_TEXT:text,
                            IMG:images,
                            SORT:sort,
                        }  = item

                        state.conceptions.push({
                            index,
                            id,
                            title,
                            text,
                            images,
                            sort
                        })
                    })

                })
        }

    },
    getters:{
        getConceptionList(state){
            return state.conceptions
        },

        getConceptionLength(state){
            return state.conceptions.length
        },

        getConceptionActiveIndex(state){
            return state.activeIndex
        },

    }
}

export default moduleConceptions