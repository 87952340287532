<template>
    <div class="projectsblock" id="projectsmain">
        
        <div class="container container_mobile_nopad container_xs_nopad">
            <div class="row">
                <div class="col-10 col-start-2 col-sm-12 col-sm-start-1">

                    <div class="projectsblock__header">
                        <Title :text=" $t('messages.mainpage.projects1') " class="projectsblock__title"/>
                        <Link route="/projects/" class="projectsblock__link" :text=" $t('messages.mainpage.projects2') "/>
                    </div>

                    <div class="projectsblock__videowrap">

                        <div class="projectsblock__indicator">
                            <p class="projectsblock__indicator-text">{{activeIndex+1}}</p>
                            <hr class="projectsblock__indicator-line">
                            <p class="projectsblock__indicator-text">{{data.length}}</p>
                        </div>

                        <div class="projectsblock__slide">
                            <div class="projectsblock__video" v-for="(item,index) in data" :key="index">
                                <!-- <img :src="item.PREVIEW_PICTURE_MOBILE" v-if="ismobile" class="projectsblock__video-image" alt=""> -->
                                <!-- <img v-else :src="item.PREVIEW_PICTURE" alt="" class="projectsblock__video-image"> -->
                                <img :src="item.PREVIEW_PICTURE" alt="" class="projectsblock__video-image">
                                <router-link :to=" '/projects/' + item.CODE + '/'" class="projectsblock__btn">
                                    <img src="@/assets/img/pages/mainpage/mplay.svg" alt="" class="projectsblock__btn-icon">
                                    <div class="projectsblock__btn-des">
                                        <p class="projectsblock__btn-title" v-html="item.NAME"></p>
                                        <p class="projectsblock__btn-text" v-html="item.TYPE"></p>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="projectsblock__mobile">
                        <Link route="/projects/" :text=" $t('messages.mainpage.projects2') "/>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import Title from './Title'
    import Link from './Link'

    export default {
        name:'ProjectsBlock',
        components:{
            Title,
            Link,
        },
        props:{
            data:{
                type:Array,
                required:false,
                default:()=>[]
            },
            ismobile:{
                type:Boolean,
                required:false,
                default:false,
            }
        },
        computed:{
            translatePercent:function(){
                return 100 / this.data.length
            }
        },
        data(){
            return {
                translated:0,
                activeIndex:0,
            }
        },
        mounted(){
            let slide = document.querySelector('.projectsblock__slide')

            setInterval(() => {
                if(this.activeIndex==(this.data.length-1)){
                    this.activeIndex=0
                } else {
                    this.activeIndex+=1
                }

                slide.setAttribute('style',`transform:translateY(-${this.activeIndex * this.translatePercent}%)`)
            }, 5000);
        }
    }
</script>

<style lang="scss" scoped>
    .projectsblock{
        position: relative;

        margin:160.63px 0px 126.94px;

        &__mobile{
            display: none;
        }

        &__indicator{
            display:flex;
            flex-direction:column;
            justify-content:flex-start;
            align-items:center;

            position: absolute;
            top:26.53px;
            left:30px;

            z-index: 1;

            &-text{
                font-size: 24px;
                line-height: 28px;
            }

            &-line{
                width: 18px;
            }
            }

        &__header{
            display:flex;
            flex-direction:row;
            justify-content:space-between;
            align-items:flex-end;

            margin-bottom: 76.33px;

            overflow: hidden;
        }

        &__slide{
            transition: transform .8s ease-in-out;
        }

        &__videowrap{
            position: relative;

            width: 100%;
            height: 590px;

            overflow: hidden;
        }

        &__slide{
            position: relative;
        }

        &__video{
            position: relative;

            &::before{
                content:'';
                position: absolute;
                left:0;
                top:0;

                width: 100%;
                height: 100%;

                background:linear-gradient(to top, #000000 -10%, transparent 60%);
            }

            &-src{
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            &-image{
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }

        &__btn{
            position: absolute;
            bottom: 69px;
            left:40px;

            cursor: pointer;

            display:flex;
            flex-direction:row;
            justify-content:center;
            align-items:center;

            &-icon{
                height: 60.18px;
                widows: auto;
                object-fit: cover;

                margin-right: 30px;
            }

            &-des{
                display:flex;
                flex-direction:column;
                justify-content:flex-start;
                align-items:flex-start;
            }

            &-title{
                font-weight: 800;
                font-size: 36px;
                line-height: 44px;

                text-transform: uppercase;

                margin-bottom: 10px;

            }

            &-text{
                font-size: 18px;
                line-height: 22px;
            }
        }
    }


    @media (max-width: 1600.98px) {
        .projectsblock{
            margin:60.63px 0px 106.94px;

            &__videowrap{
                height: 480px;
            }

            &__video{
                
                &-image{
                    height: 480px;
                }
            }

            &__btn{

                &-title{
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }

    @media (max-width: 1460.98px) {
        .projectsblock{
           
            &__btn{
                &-icon{
                    height: 48px;
                }

                &-title{
                    font-size: 18px;
                    line-height: 18px;
                }

                &-text{
                    font-size: 14px;
                    line-height: 15px;
                }
            }
        }
    }

    @media (max-width: 1366.98px){
        .projectsblock{
            &__header{
                margin-bottom: 38px;
            }
        }
    }

    @media (max-width: 768.98px){
        .projectsblock{
            margin:60.63px 0px 139.94px;

            &__header{
                padding-left: 20px;
                padding-right: 20px;
            }

            &__btn{
                left:20px;
            }
        }
    }

    @media (max-width: 480.98px){
        .projectsblock{

            &__mobile{
                display: block;

                padding: 37.61px 20px 0px;
            }

            &__header{

                .link{
                    display: none;
                }
            }

            &__videowrap{
                height: 562px;
            }

            &__video{

                &-image{
                    height: 562px;
                    object-fit: contain;
                }
            }

            &__btn{
                top:unset;
                bottom: 36.99px;
                left:20px;

                display:flex;
                flex-direction:row;
                justify-content:flex-start;
                align-items:center;
                
                &-des{
                    max-width: unset;
                }
            }
        }
    }
</style>