<template>
    <div class="smodal">

        <div class="smodal__block">
            <div class="smodal__title">
                <p class="smodal__title-text">подробнее</p>
            </div>

            <p class="smodal__text"><span>Период:</span> 20 мая – 9 июня</p>
            <p class="smodal__text"><span>Заказчик:</span> Министерство информации и общественного развития РК</p>
            <p class="smodal__text"><span>Целевая аудитория:</span> все граждане РК</p>
        </div>


        <div class="smodal__more">
            <div class="smodal__more-item">
                <img src="@/assets/img/pages/special/more/smore-1.jpg" alt="" class="smodal__more-item-image">
                <p class="smodal__more-item-title">Контекст: </p>
                <p class="smodal__more-item-text">Ролик приурочен к 30-летию Независимости Республики Казахстан.</p>
                <p class="smodal__more-item-title">Задачи: </p>
                <p class="smodal__more-item-text">-	Подвести итоги 30-летия Независимости РК</p>
                <p class="smodal__more-item-text">-	Вспомнить главные события из истории нашей Независимости</p>
                <p class="smodal__more-item-text">-	Рассказать о пути становления новой Республики </p>

                <p class="smodal__more-item-quote">«Бывают моменты, когда остро чувствуешь связь между прошлым и будущим. Когда важно оглянуться назад 
и увидеть путь, который был пройден»</p>
                
            </div>
            <div class="smodal__more-item">
                <img src="@/assets/img/pages/special/more/smore-2.jpg" alt="" class="smodal__more-item-image">
                <p class="smodal__more-item-title">Nomad Stunts</p>
                <p class="smodal__more-item-text">Международная группа профессиональных каскадеров, которую в 2000 году организовал постановщик трюков, заслуженный деятель Республики Казахстан Жайдарбек Кунгужинов.</p>
                
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'SpecialMore',
    }
</script>

<style lang="scss" scoped>

</style>