<template>
    <div class="upload-file">
        <label class="upload-file__input">
                <img src="@/assets/img/icons/add-file.svg" alt="">
                <input type="file" name="file" @change="onFileChange">
                
                загрузить файлы (rar, jpeg)
        </label>
        <div class="upload-file__info" v-if="fileName">
            <img src="@/assets/img/icons/check-file.svg" alt="">
            <div class="upload-file__name">
                {{fileName}} <span>{{fileSize}}</span>
            </div>
        </div>
        <div class="upload-file__info" v-else>
            <div class="upload-file__input">
                Файл не должен превышать 25 мб
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'AppAddFile',

    data() {
        return  {
            fileName: '',
            filesize: '',
        };
    },

    methods: {
        onFileChange(event){
            let fileData =  event.target.files[0];
            this.fileName = fileData.name;
            this.fileSize = (fileData.size / 1000000).toFixed(2) + ' MB';
        }
    }
}
</script>