<template>
    <div class="acookie" ref="cookieModal" :class=" getAppTheme?'acookie--light':'' ">

        <div class="container">
            <div class="row">
                <div class="col-12">

                    <div class="acookie__content">
                        <div class="acookie__icon">
                            <img src="@/assets/img/icons/cookie-light.svg" v-if="getAppTheme" alt="" class="acookie__icon-img">
                            <img src="@/assets/img/icons/cookie.svg" v-else alt="" class="acookie__icon-img">
                        </div>

                        <div class="acookie__center">
                            <!-- <p class="acookie__text">Чтобы предоставить нашим посетителям наилучший пользовательский опыт, наш сайт использует Cookie. Продолжая работу с нашим сайтом вы подтверждаете, что соглашаетесь с  <span @click="openCookiePage">нашей политикой Cookie</span> </p> -->
                            <p class="acookie__text"> {{$t('messages.cookie.warning1')}} <span @click="openCookiePage"> {{$t('messages.cookie.warning2')}}  </span> </p>
                        </div>

                        <button class="acookie__btn" @click="closeCookieModal">
                            <p class="acookie__btn-text">Согласен</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    
    import {
        TweenMax,
        Power3,
    } from 'gsap'

    export default {
        name:'AppCookie',
        computed:{
            ...mapGetters([
                'getAppTheme'
            ])
        },
        mounted(){
            // TODO: убрать потом проверку на 404 и расскоментировать для других путей
            // let cookieAccepted = this.$cookie.get('cookie');
            
            // if(cookieAccepted=='accepted'){
                if(this.$route.path!=='/404'){
                    TweenMax.set(this.$refs.cookieModal, {
                        alpha:0,
                        ease:Power3.easeOut,
                    })
                }
            // }
        },

        watch:{
            $route:function(route){
                if(route.path!=='/404'){
                    TweenMax.set(this.$refs.cookieModal, {
                        alpha:0,
                        ease:Power3.easeOut,
                    })
                }
            }
        },
        methods:{
            closeCookieModal(){
                let {cookieModal} = this.$refs
                
                TweenMax.to(cookieModal, .5, {
                    alpha:0,
                    ease:Power3.easeOut,
                })

                this.$cookie.set('cookie', 'accepted', 30);
            },

            openCookiePage(){
                this.$router.push('/cookies')
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>