<template>
    <!-- @click="clickHandler" -->
    <div class="orderbtn">
        <div class="orderbtn__textwrap">
            <a
                href="https://forms.gle/NkJdywjh8fqQsKnq5"
                target="_blank"
                class="orderbtn__text"
                v-html="text"
            ></a>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderOrderBtn",
    props: {
        text: {
            type: String,
            required: false,
            default: "",
        },
    },
    methods: {
        clickHandler() {
            this.$emit("clickHandler");
        },
    },
};
</script>

<style lang="scss" scoped></style>
