<template>
    <div class="mconceptions">

        <div class="container">
            <div class="row">
                <div class="col-12">

                    <div class="mconceptions__item" 
                         :id="'conceptionsTrigger'+(index+1)" 
                         v-for="(item,index) in getConceptionList" 
                         :key="index"
                    >

                        <div class="mconceptions__titlewrap">
                            <h4 class="mconceptions__title" v-html="item.title"></h4>
                        </div>

                        <div class="mconceptions__textwrap" v-html="item.text"></div>

                        <div class="mconceptions__images">
                            <img :src="item.images[0]" alt="" class="mconceptions__image mconceptions__image--left">
                            <img :src="item.images[1]" alt="" class="mconceptions__image mconceptions__image--right">
                        </div>

                    </div>


                </div>
            </div>
        </div>
        

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    import {
        TimelineMax,
        Power3,
    } from 'gsap'

    export default {
        name:'ConceptionsMobile',
        computed:{
            ...mapGetters([
                    'getConceptionList',
            ])
        },
        mounted(){
            let length = this.getConceptionList.length

            for(let i= 0; i<length;i++){
                let timeline = new TimelineMax()
                
                let element = document.getElementById('conceptionsTrigger'+(i+1))

                let title = element.querySelector('.mconceptions__title')
                let text = element.querySelector('.mconceptions__textwrap')
                let imageLeft = element.querySelector('.mconceptions__image--left')
                let imageRight = element.querySelector('.mconceptions__image--right')

                timeline
                    .from(title, .6,{
                        yPercent:100,
                        ease:Power3.easeInOut,
                    })
                    .from(text,.6,{
                        yPercent:50,
                        alpha:0,
                        ease:Power3.easeInOut
                    },'-=.3')
                    .from(imageLeft, .6,{
                        alpha:0,
                        ease:Power3.easeInOut
                    },'-=.3')
                    .from(imageRight, .6,{
                        alpha:0,
                        ease:Power3.easeInOut
                    },'-=.3')

                const scene = this.$scrollmagic.scene({
                    triggerElement: '#conceptionsTrigger'+(i+1),
                    triggerHook: 0.5,
                    duration: 0
                }).setTween(timeline)

                this.$scrollmagic.addScene(scene)
            }

            
        }
    }
</script>

<style lang="scss" scoped>

</style>