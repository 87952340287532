const moduleSpecial  = {
    state:{
        comments:[],
        sameProjects:[]
    },

    mutations:{

    },

    actions:{
        fetchComments({state},info){

            let {
                route
            } = info

            console.log(route)

            state.comments = [
                /* {
					name:"Сергей Корносенков",
					date:'2 дня назад',
					text:'Сюжет несколько запутанный и местами технически противоречивый, но ролик посмотреть стоит хотя бы ради хорошей реализации идеи, известных актеров и наполненности остросюжетными моментами.",',
					replies:[],
					shareCount:5,
                },
                {
					name:"Сергей Корносенков",
					date:'2 дня назад',
					text:'Сюжет несколько запутанный и местами технически противоречивый, но ролик посмотреть стоит хотя бы ради хорошей реализации идеи, известных актеров и наполненности остросюжетными моментами.",',
					replies:[],
					shareCount:5,
                },
                {
					name:"Сергей Корносенков",
					date:'2 дня назад',
					text:'Сюжет несколько запутанный и местами технически противоречивый, но ролик посмотреть стоит хотя бы ради хорошей реализации идеи, известных актеров и наполненности остросюжетными моментами.",',
					replies:[],
					shareCount:5,
				} */
            ]

        }
    },

    getters:{


        getSpecialProjectComments(state){
            return state.comments
        }
    }
}

export default moduleSpecial