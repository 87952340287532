<template>
    <div class="slist">
       
        <div class="container">
            <div class="row">
                <div class="col-1 col-start-2">
                     <div class="slist__item" @click="$router.push('/specialprojects/svyaz-vremen/')">
                        <p class="slist__item-text">Связь времен</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'SpecialList'
    }
</script>

<style lang="scss" scoped>

</style>