const axios = require("axios");
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";

const moduleNews = {
	state: {
		newslist: [],
		newsdetail: {},
	},

	actions: {
		fetchNewsList({ state, rootGetters }) {
			let locale = rootGetters.getCurrentLanguageKey;

			axios
				.post("https://api.blackandwhite.kz/bw/news/?c=30&lang=" + locale)
				.then((response) => {
					return response.data.news;
				})
				.then((news) => {
					let newslist = [];

					news.map((item) => {
						let {
							CODE: router,
							ACTIVE_FROM: date,
							ID: id,
							NAME: title,
							PREVIEW_PICTURE: poster,
							PREVIEW_TEXT,
						} = item;

						newslist.push({
							router,
							id,
							title,
							poster,

							// TODO: потом после изменений со стороны админки
							date: date.split(" ")[0],
							text: PREVIEW_TEXT,
						});
					});

					state.newslist = newslist;
				});
		},

		fetchNewsDetail({ state, rootGetters }, info) {
			let locale = rootGetters.getCurrentLanguageKey;
			let id = info.id;

			axios
				.post(
					"https://api.blackandwhite.kz/bw/news/detail.php?id=" +
						id +
						"&lang=" +
						locale
				)
				.then((response) => {
					return response.data.news[0];
				})
				.then((newsdetail) => {
					let {
						ID: id,
						CODE: route,
						ACTIVE_FROM: date,

						DETAIL_PICTURE: image,

						NAME: title,
						PREVIEW_TEXT: slogan,
						DETAIL_TEXT: text,

						DETAIL_PICTURE_SRC,
						ELEMENT_META_DESCRIPTION,
						ELEMENT_META_TITLE,
						CODE,
					} = newsdetail;

					let dateArr = date.split(" ");

					state.newsdetail = {
						id,
						date: dateArr[0],
						dateTime: dateArr[1],
						route,
						title,
						slogan,
						image: image == "https://api.blackandwhite.kz" ? "" : image,
						text,

						DETAIL_PICTURE_SRC,
						ELEMENT_META_DESCRIPTION,
						ELEMENT_META_TITLE,
						CODE,
					};
				});
		},
	},

	mutations: {},

	getters: {
		getNewsList(state) {
			return state.newslist;
		},

		getNewsDetail(state) {
			return state.newsdetail;
		},
	},
};

export default moduleNews;
