<template>
    <div class="loader" ref="loader">
        <div class="loader__container">
            <div class="loader__imagewrap">
                <img
                    :src="imagesMobile[index]"
                    v-if="getIsMobile"
                    alt=""
                    class="loader__image"
                />
                <img :src="images[index]" alt="" v-else class="loader__image" />
            </div>

            <div class="loader__percentwrap">
                <p class="loader__percent">{{ percent }}%</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "AppLoader",
    computed: {
        ...mapGetters(["getSiteLoader", "getIsMobile"]),
    },
    mounted() {
        if (this.getSiteLoader) {
            this.startLoadingAnimation();
        }
    },
    watch: {
        getSiteLoader: function (value) {
            if (value) {
                this.startLoadingAnimation();
            } else {
                this.stopLoadingAnimation();
            }
        },
    },
    data() {
        return {
            imageTimer: null,
            percentTimer: null,
            percent: 0,
            index: 0,
            images: [
                require("@/assets/img/pages/loader/image-1.webp"),
                require("@/assets/img/pages/loader/image-2.webp"),
                require("@/assets/img/pages/loader/image-3.webp"),
                require("@/assets/img/pages/loader/image-4.webp"),
                require("@/assets/img/pages/loader/image-5.webp"),
                require("@/assets/img/pages/loader/image-6.webp"),
                require("@/assets/img/pages/loader/image-7.webp"),
                require("@/assets/img/pages/loader/image-8.webp"),
                require("@/assets/img/pages/loader/image-9.webp"),
                require("@/assets/img/pages/loader/image-10.webp"),
            ],
            imagesMobile: [
                require("@/assets/img/pages/loader-mobile/image-1.webp"),
                require("@/assets/img/pages/loader-mobile/image-2.webp"),
                require("@/assets/img/pages/loader-mobile/image-3.webp"),
                require("@/assets/img/pages/loader-mobile/image-4.webp"),
                require("@/assets/img/pages/loader-mobile/image-5.webp"),
                require("@/assets/img/pages/loader-mobile/image-6.webp"),
                require("@/assets/img/pages/loader-mobile/image-7.webp"),
                require("@/assets/img/pages/loader-mobile/image-8.webp"),
                require("@/assets/img/pages/loader-mobile/image-9.webp"),
                require("@/assets/img/pages/loader-mobile/image-10.webp"),
            ],
            layoutElements: ["header", "fdots", "scroller"],
        };
    },
    methods: {
        startLoadingAnimation() {
            let loader = document.querySelector(".loader");
            loader.setAttribute("style", "opacity:1;z-index:99;");

            // Некоторые начальные элементы ставятся в z-index:-99, чтобы не мешать при загрузке
            this.layoutElements.forEach((item) => {
                if (document.getElementById(item)) {
                    document
                        .getElementById(item)
                        .setAttribute("style", "z-index:-99;");
                }
            });

            this.imageTimer = setInterval(
                function () {
                    if (this.index == this.images.length - 1) {
                        this.index = 0;
                    } else {
                        this.index += 1;
                    }
                }.bind(this),
                200
            );

            this.percentTimer = setInterval(
                function () {
                    let step = 12;

                    if (this.percent == 100) {
                        this.percent = 100;
                    } else if (this.percent > 80) {
                        step = 1;
                    } else {
                        this.percent += Math.floor(Math.random() * step) + 1;
                    }
                }.bind(this),
                150
            );
        },

        stopLoadingAnimation() {
            let loader = document.querySelector(".loader");

            setTimeout(() => {
                this.percent = 100;

                loader.setAttribute("style", "opacity:0;z-index:-99;");

                setTimeout(() => {
                    this.index = 0;
                    this.percent = 0;
                }, 500);

                this.layoutElements.forEach((item) => {
                    if (document.getElementById(item)) {
                        document.getElementById(item).removeAttribute("style");
                    }
                });

                clearInterval(this.imageTimer);
                clearInterval(this.percentTimer);
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: #1b1d1e;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 99;
    opacity: 1;

    transition: all 0.5s ease-in;

    &__container {
        position: relative;
    }

    &__imagewrap {
        position: relative;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__imagewrap::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: rgba($color: #0b0b0b, $alpha: 0.6);
    }

    &__image {
        width: 350px;
        height: 350px;
        object-fit: cover;
    }

    &__percentwrap {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__percent {
        font-weight: 700;
        font-size: 80px;

        user-select: none;
    }
}

@media (max-width: 1600.98px) {
    .loader {
        &__image {
            width: 280px;
            height: 280px;
        }

        &__percent {
            font-size: 68px;
        }
    }
}

@media (max-width: 480.98px) {
    .loader {
        &__image {
            width: 200px;
            height: 200px;
        }

        &__percent {
            font-size: 40px;
        }
    }
}
</style>
