<template>
	<div class="about">
		<div class="container">
			<div class="row">
				<div class="col-start-2 col-10 col-xs-start-1 col-xs-12">
					<div class="app__titlewrap">
						<h1 class="app__title" v-html="getAboutInfo.title"></h1>
					</div>

					<div class="app__pretitlewrap">
						<p
							class="app__pretitle"
							v-for="(item, index) in getAboutInfo.headerText"
							:key="index"
							v-html="item"
						></p>
					</div>
				</div>
			</div>

			<div class="row" v-if="getAboutInfo.video">
				<div class="col-12">
					<div class="about__videowrap">
						<div
							style="
								padding: 56.25% 0 0 0;
								position: relative;
								width: 100%;
								height: auto;
							"
						>
							<iframe
								:src="
									'https://player.vimeo.com/video/' +
									getAboutInfo.video +
									'?title=0&byline=0&portrait=0'
								"
								style="
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
								"
								frameborder="0"
								allow="autoplay; fullscreen"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-start-2 col-10">
					<div class="about__des">
						<p
							class="about__text"
							v-for="(item, index) in getAboutInfo.mainText"
							:key="index"
							v-html="item"
						></p>
					</div>
				</div>
			</div>
		</div>

		<div class="about__sliderwrap">
			<slider
				v-if="getAboutInfo.slider"
				:dots="true"
				:elements="getAboutInfo.slider"
			/>
		</div>

		<div class="container" v-if="false">
			<div class="row">
				<div class="col-start-2 col-10">
					<div class="about__titlewrap">
						<h2 class="about__title">Нам доверяют</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapMutations, mapGetters, mapActions } from "vuex";

	import Slider from "../../components/common/Slider";

	export default {
		name: "About",
		components: {
			Slider,
		},
		computed: {
			...mapGetters(["getAboutInfo", "getCurrentLanguage"]),
		},
		mounted() {
			this.fetchAboutInformation();

			this.setMetaInfo({
				page: "about",
			});
		},
		watch: {
			getCurrentLanguage: function () {
				this.fetchAboutInformation();
			},
		},
		data() {
			return {};
		},
		methods: {
			...mapMutations(["setMetaInfo"]),

			...mapActions(["fetchAboutInformation"]),

			connectCompany() {
				this.$router.push("/contacts/");
			},
		},
	};
</script>

<style lang="scss" scoped>
</style>