import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import store from "@/store/index.js";

import MainIndex from "../pages/mainpage/Index";

import Projects from "../pages/projects/Index";
import ProjectsList from "../pages/projects/ProjectsList";
import ProjectsDetail from "../pages/projects/ProjectsDetail";

import NewsPage from "../pages/news/Index";
import NewsList from "../pages/news/NewsList";
import NewsDetail from "../pages/news/NewsDetail";

import SpecialProjects from "../pages/special/Index";
import SpecialList from "../pages/special/SpecialList";
import SpecialDetailSvyazVremen from "../pages/special/SpecialDetail";
import SpecialDetailAboutUs from "../pages/special/SpecialAboutUs";

import Contacts from "../pages/contacts/Index";

import About from "../pages/about/Index";

import Team from "../pages/team/Index";

import Services from "../pages/services/Index";

import Vacancies from "../pages/vacancies/Index";

import Clients from "../pages/clients/Index";

import Awards from "../pages/awards/Index";

import Conceptions from "../pages/conceptions/Index";
import CastingsPage from "../pages/castings/Index";

import CookiePage from "../pages/cookie/Index";
import Error404 from "../pages/errors/404";

// import PageNotFound from "../pages/errors/PageNotFound";

// import Seopage from "../pages/seopage/Index";
import Socialpage from "../pages/seopage/socialpage/Index";
import Imagepage from "../pages/seopage/imagepage/Index";
import Films from "../pages/films/Index";
import Animations from "../pages/animations/Index";
import specialprojects from "../pages/special-projects/Index";

import BlogPage from "../pages/blog/Index";
import BlogList from "../pages/blog/BlogList";
import BlogDetail from "../pages/blog/BlogDetail";

import Rental from "../pages/rental/Index";

let routes = [
    {
        path: "/",
        name: "mainroute",
        component: MainIndex,
    },
    {
        path: "/projects",
        component: Projects,
        children: [
            {
                path: ":symbolcode",
                name: "projectsDetailroute",
                component: ProjectsDetail,
            },
            {
                path: "",
                name: "projectsIndex",
                component: ProjectsList,
            },
        ],
    },
    {
        path: "/specialprojects",
        component: SpecialProjects,
        children: [
            {
                path: "about-us",
                name: "specialDetailAboutUs",
                component: SpecialDetailAboutUs,
            },
            {
                path: "svyaz-vremen",
                name: "specialDetailSvyazVremen",
                component: SpecialDetailSvyazVremen,
            },
            {
                path: "",
                name: "specialIndex",
                component: SpecialList,
            },
        ],
    },
    {
        path: "/news",
        component: NewsPage,
        children: [
            {
                path: "",
                name: "newslist",
                component: NewsList,
            },
            {
                path: ":newsname",
                name: "newsdetail",
                component: NewsDetail,
            },
        ],
    },
    {
        path: "/about",
        name: "aboutroute",
        component: About,
    },
    {
        path: "/contacts",
        name: "contactsroute",
        component: Contacts,
    },
    {
        path: "/clients",
        name: "clientsroute",
        component: Clients,
    },

    {
        path: "/services",
        name: "servicesroute",
        component: Services,
    },

    {
        path: "/team",
        name: "teamroute",
        component: Team,
    },

    {
        path: "/vacancies",
        name: "vacanciesroute",
        component: Vacancies,
    },

    {
        path: "/awards",
        name: "awardsroute",
        component: Awards,
    },

    {
        path: "/conceptions",
        name: "conceptionsroute",
        component: Conceptions,
    },

    {
        path: "/castings",
        name: "castingspage",
        component: CastingsPage,
    },

    {
        path: "/cookies",
        name: "cookies",
        component: CookiePage,
    },

    {
        path: "/404",
        name: "404",
        component: Error404,
    },

    {
        path: "*",
        name: "notfound",
        // component: PageNotFound,
        redirect: "/404",
    },
    {
        path: "/korporativnyj-film/",
        name: "films",
        component: Films,
    },
    {
        path: "/infografika-i-animaciya",
        name: "animations",
        component: Animations,
    },
    {
        path: "/special-projects",
        name: "specialProjects",
        component: specialprojects,
    },
    {
        path: "/blog",
        component: BlogPage,
        children: [
            {
                path: "",
                name: "bloglist",
                component: BlogList,
            },
            {
                path: ":blogname",
                name: "blogdetail",
                component: BlogDetail,
            },
        ],
    },
    {
        path: "/socialnyj-videorolik/",
        name: "socialpage",
        component: Socialpage,
    },
    {
        path: "/imidzhevyj-videorolik",
        name: "imagepage",
        component: Imagepage,
    },
    {
        path: "/rental/",
        name: "rental",
        component: Rental,
    },
];

const router = new VueRouter({
    mode: "history",
    routes,

    // Данная опция при переходе с одного роутинга на другой - меняет положение страницы и скроллит наверх
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
let firstTime = 0;
router.beforeResolve((to, from, next) => {
    if (to.name && (firstTime == 0 || to.name != "projectsIndex")) {
        firstTime++;
        store.commit("setSiteLoader", true);
    }
    next();
});

router.afterEach(() => {
    // Данная опция нужна, чтобы убирать нежелательный класс после каждого перехода route
    document.querySelector("body").classList.remove("app--unscroll");
    // store.commit('setSiteLoader',false)
    setTimeout(() => store.commit("setSiteLoader", false), 1000);
});

export default router;
