<template>
    
    <div class="smodal">

        <div class="smodal__block">
            <div class="smodal__title">
                <p class="smodal__title-text">команда проекта</p>
            </div>

            <p class="smodal__text">Маржан Бекмаганбетова, исполнительный продюсер: <br>
                <span class="smodal__text_quote">«Для меня, как продюсера, было важно объединить команду. Все ребята очень талантливые, но они никогда не работали вместе. Не знали, у кого какой подход, кто как привык устраивать рабочий процесс. Поэтому нужно было, чтобы каждый нашел общий язык друг с другом. И нам это удалось!». </span>
            </p>

            <div class="smodal__team">

                <div class="smodal__team-item" v-for="(item,index) in testData" :key="index">
                    <p class="smodal__team-item-position" v-html="item.position"></p>
                    <p class="smodal__team-item-name" v-html="item.name"></p>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'SpecialAbout',
        data(){
            return{
                testData:[
                    {
                        position:'Генеральный продюсер',
                        name:'Темирбек Аманжол ',
                    },
                    {
                        position:'Исполнительный продюсер',
                        name:'Маржан Бекмаганбетова',
                    },
                    {
                        position:'Режиссёр, оператор-постановщик',
                        name:'Ален Ниязбеков',
                    },
                    {
                        position:'Второй режиссер',
                        name:'Евгения Морева ',
                    },
                    {
                        position:'Художник-постановщик',
                        name:'Куат Тлеубаев',
                    },
                    {
                        position:'Оператор-постановщик',
                        name:'Азамат Жанабеков',
                    },
                    {
                        position:'Оператор Steadicam',
                        name:'Нурсултан Базарбай',
                    },
                    {
                        position:'Сценарий',
                        name:'Темирбек Аманжол, Илья Лебедев',
                    },
                    {
                        position:'Ассистент продюсера',
                        name:'Дана Мутурганова',
                    },
                    {
                        position:'Администратор',
                        name:'Ерлан Мутурганов',
                    },
                    {
                        position:'Кастинг',
                        name:'Дана Алибаева',
                    },
                    {
                        position:'Звукооператор',
                        name:'Санжар Мурат',
                    },
                    {
                        position:'Группа каскадеров',
                        name:'Nomad Stunts',
                    },
                    {
                        position:'Бэкстейдж',
                        name:'Зия Рупатов, Али Амангельдин ',
                    },
                    {
                        position:'Гаффер',
                        name:'Айнур Кажкаримова',
                    },
                    {
                        position:'Фокус-пуллер',
                        name:'Аян Базылбек',
                    },
                    {
                        position:'Механик камеры',
                        name:'Нурлыбек Смайыл',
                    },
                    {
                        position:'Художник по гриму ',
                        name:'Альфия Джунусбаева',
                    },
                    {
                        position:'Художник по костюму',
                        name:'Бахыт Таженова',
                    },
                    {
                        position:'Ассистент режиссера',
                        name:'Диана Толегенова',
                    },
                    {
                        position:'Моушн-дизайнер',
                        name:'Абай Умурзаков',
                    },
                    {
                        position:'Водитель',
                        name:'Мухагали Байболганов',
                    },
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>