<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	import { mapMutations, mapActions } from "vuex";

	export default {
		name: "BlogPage",
		mounted() {
			this.fetchBlogList();
			this.setMetaInfo({
				page: "blog",
			});
		},
		methods: {
			...mapMutations(["setMetaInfo"]),
			...mapActions(["fetchBlogList"]),
		},
	};
</script>

<style lang="scss" scoped>
</style>