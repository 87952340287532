<template>
	<div class="slider">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div
					class="swiper-slide slider__item"
					v-for="(item, index) in elements"
					:key="index"
					@click="setModal(index)"
				>
					<img
						:src="item.src"
						:alt="item.alt"
						class="slider__item-image"
					/>

					<div class="slider__item-tip">
						<div class="slider__item-tip-overlay"></div>
						<img
							src="@/assets/img/icons/scale.svg"
							alt=""
							class="slider__item-tip-icon"
						/>
						<div class="slider__item-tip-textwrap">
							<p class="slider__item-tip-text">Увеличить</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 
            Данная часть нужно для того, чтобы при нажатии открывать фотографию отдельно модалкой
         -->
		<div
			class="slider__modal"
			:class="activeModal ? 'slider__modal--opened' : ''"
			ref="modal"
		>
			<div class="slider__modal-overlay" @click="closeModal"></div>

			<div class="slider__modal-imagewrap">
				<img
					src="@/assets/img/icons/close.svg"
					alt=""
					@click="closeModal"
					class="slider__modal-close"
				/>
				<img :src="image" alt="" class="slider__modal-image" />

				<div class="slider__modal-controls">
					<div
						class="
							slider__modal-control slider__modal-control--left
						"
						@click="prevImage"
					>
						<img
							src="@/assets/img/icons/arrow.svg"
							alt=""
							class="slider__modal-arrow"
						/>
					</div>

					<div
						class="
							slider__modal-control slider__modal-control--right
						"
						@click="nextImage"
					>
						<img
							src="@/assets/img/icons/arrow.svg"
							alt=""
							class="slider__modal-arrow"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { TimelineMax, Power3 } from "gsap";

	import Swiper from "swiper";
	import "swiper/swiper-bundle.css";

	let timeline = new TimelineMax();

	export default {
		name: "Slider",
		props: {
			autoplay: {
				type: Boolean,
				required: false,
				default: false,
			},
			nav: {
				type: Boolean,
				required: false,
				default: true,
			},
			dots: {
				type: Boolean,
				required: false,
				default: false,
			},
			elements: {
				type: Array,
				required: false,
				default: () => [],
			},
		},
		mounted() {
			this.sliderInitHandler();
		},
		beforeDestroy() {
			this.closeModal;
		},
		data() {
			return {
				image: "",
				currentIndex: 0,
				activeModal: false,
				options: {},
				responsive: {
					0: {
						items: 1,
					},
					768: {
						items: 1,
						sePadding: 150,
					},
					1024: {
						items: 1,
						stagePadding: 300,
					},
					1366: {
						items: 2,
					},
					1440: {
						items: 2,
					},
					1900: {
						items: 3,
					},
					2560: {
						items: 2,
					},
				},
			};
		},
		methods: {
			setModal(index) {
				this.image = this.elements[index].src;
				this.currentIndex = index;
				this.activeModal = true;

				document.querySelector("body").classList.add("app--unscroll");

				let { modal } = this.$refs;

				let modalOverlay = modal.querySelector(".slider__modal-overlay");
				let modalImagewrap = modal.querySelector(
					".slider__modal-imagewrap"
				);

				let controlRight = modal.querySelector(
					".slider__modal-control--left"
				);
				let controlLeft = modal.querySelector(
					".slider__modal-control--right"
				);

				timeline
					.from(modalOverlay, 0.4, {
						alpha: 0,
						ease: Power3.easeIn,
					})
					.from(
						modalImagewrap,
						0.4,
						{
							scale: 0,
							ease: Power3.easeInOut,
						},
						"-=.2"
					)
					.from(controlRight, 0.4, {
						xPercent: "-=100",
						ease: Power3.easeInOut,
					})
					.from(
						controlLeft,
						0.4,
						{
							xPercent: "100",
							ease: Power3.easeInOut,
						},
						"-=.4"
					);
			},

			closeModal() {
				this.image = "";
				this.activeModal = false;

				document.querySelector("body").classList.remove("app--unscroll");
			},

			nextImage() {
				if (this.currentIndex == this.elements.length - 1) {
					this.currentIndex = 0;
				} else {
					this.currentIndex += 1;
				}
				this.image = this.elements[this.currentIndex].src;
			},

			prevImage() {
				if (this.currentIndex === 0) {
					this.currentIndex = this.elements.length - 1;
				} else {
					this.currentIndex -= 1;
				}
				this.image = this.elements[this.currentIndex].src;
			},

			sliderInitHandler() {
				new Swiper(".swiper-container", {
					loop: false,
					slidesPerView: 3,

					autoplay: {
						delay: 5000,
					},

					breakpoints: {
						320: {
							slidesPerView: 1,
							spaceBetween: 0,
						},
						800: {
							slidesPerView: 3,
							spaceBetween: 30,
						},
					},
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.slider {
		position: relative;

		width: 100%;
		height: auto;

		overflow: hidden;

		&__item {
			cursor: pointer;
			position: relative;

			&-image {
				width: 100%;
				height: auto;
			}

			&-handler {
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;
			}

			&-tip {
				position: absolute;
				top: 0;
				left: 0;

				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				opacity: 0;

				transition: all 0.6s ease-in;

				&-overlay {
					position: absolute;
					left: 0;
					top: 0;

					width: 100%;
					height: 100%;

					background-color: rgba($color: #0b0b0b, $alpha: 0.5);
				}

				&-icon {
					position: relative;

					height: 85px;
					width: auto;

					margin-bottom: 25px;
				}

				&-textwrap {
					position: relative;

					width: 100%;
					height: auto;

					text-align: center;
				}

				&-text {
					font-size: rem(18px);
					color: #fff;
					text-transform: uppercase;
				}

				&:hover,
				&:focus {
					opacity: 1;
				}
			}
		}

		.owl-dots {
			margin-top: 20px !important;
		}

		.owl-nav {
			position: absolute;
			left: 0;
			top: 40%;

			width: 100%;
			height: 0px;

			padding: 0 28px;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		&__arrow {
			position: relative;
			transform: rotate(-90deg);

			img {
				height: 40px;
				width: auto;
			}
		}

		&__arrow--prev {
			img {
				transform: rotate(-180deg);
			}
		}

		.owl-dot {
			margin-left: 15px;
			margin-right: 15px;

			span {
				background-color: transparent !important;
				border: 1px solid #fff;
			}
		}

		.owl-dot.active {
			span {
				background-color: #fff !important;
			}
		}

		.owl-theme .owl-nav [class*="owl-"] {
			background-color: transparent;
		}

		.owl-theme .owl-nav [class*="owl-"]:hover {
			background-color: transparent;
		}

		&__modal {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;

			margin-top: auto;
			margin-bottom: auto;
			margin-left: auto;
			margin-right: auto;

			width: 100%;
			height: 100%;

			z-index: -99;
			opacity: 0;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&-overlay {
				position: absolute;
				width: 100%;
				height: 100%;

				background-color: rgba($color: #000000, $alpha: 0.8);
			}

			&-close {
				position: absolute;
				right: -35px;
				top: -35px;

				height: 25px;
				width: auto;

				cursor: pointer;
			}

			&-imagewrap {
				position: relative;

				height: 75vh;
				width: auto;
				object-fit: cover;
			}

			&-image {
				width: auto;
				height: 100%;
				object-fit: cover;
			}

			&-controls {
				position: absolute;
				left: 0;
				top: 0;

				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				overflow: hidden;
			}

			&-control {
				height: auto;
				width: auto;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				background-color: rgba($color: #fff, $alpha: 0.4);

				padding: 45px 5px;

				cursor: pointer;

				user-select: none;
			}

			&-control--left {
				img {
					transform: rotate(180deg);
				}
			}

			&-arrow {
				height: 50px;
				width: auto;
				object-fit: contain;
			}
		}

		&__modal--opened {
			z-index: 999;
			opacity: 1;
		}
	}

	@media (max-width: 1600.98px) {
		.slider {
			.owl-nav {
				top: 50%;
			}
		}
	}

	@media (max-width: 1366.98px) {
		.slider {
			.owl-nav {
				top: 45%;
			}

			&__modal {
				&-close {
					height: 18px;
				}
			}
		}
	}

	@media (max-width: 768.98px) {
		.slider {
			.owl-dots {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
			}

			.owl-theme .owl-dots .owl-dot {
				margin-left: 0px;
				margin-right: 0px;

				span {
					width: 8px;
					height: 8px;
				}
			}

			&__modal {
				&-imagewrap {
					height: 35vh;
				}

				&-control {
					padding: 25px 5px;
				}

				&-arrow {
					height: 15px;
				}
			}
		}
	}

	@media (max-width: 480.98px) {
		.slider {
			.owl-theme .owl-dots .owl-dot {
				max-width: 12px;

				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;

				span {
					width: 6px;
					height: 6px;

					margin: 0px;
				}
			}

			&__item {
				padding-left: 0px;
				padding-right: 0px;
			}

			&__modal {
				&-imagewrap {
					height: 25vh;
				}

				&-control {
					padding: 18px 5px;
				}
			}
		}
	}
</style>