const moduleAwards = {
    state:{
        awardsList:[

            {
				year:'2021',
				blocks:[
					{
						href:'/projects',
						image:require('@/assets/img/pages/awards/award_2.jpg'),
						title:'Argonya – Өзіңнен баста',
						show: true,
                        elements:[
                            {
                                title:'RED JOLBORS',
                                image:require('@/assets/img/pages/awards/award-2.png'),
                                list:[
									'Номинация: «Film craft: direction, art-direction',
                                    'Награда: серебро',
                                ]
                            },
                        ]
					},
					{
						href:'/projects/shyn-jurekten/',
						image:require('@/assets/img/pages/awards/award_1.webp'),
						title:'«ШЫН ЖҮРЕКТЕН»',
						show: true,
						elements:[
							{
								title:'АКМЕРГЕН',
								image:'',
								list:[
									'Номинация: «Социальные коммуникации и благотворительность»',
                                    'Награда: Специальный приз',
								]
							},
						]
					},
					{
						href:'/projects/svyaz-vremen/',
						image:require('@/assets/img/pages/awards/award-2021.jpg'),
						title:'«Связь времён» <br> (The Link of times)',
						show: true,
						elements:[
							{
								title:'',
								image:'',
								list:[
									'Международный фестиваль <br> New York Film Festival.',
									'<br>',
									'Шорт-лист по номинациям: <br> «Direction», «Writing»',
								]
							},
						]
					}
				]
            },
            {
				year:'2020',
				blocks:[
					{
						href:'/projects/sotsialnyy-eksperiment-erg/',
						image:require('@/assets/img/pages/awards/erg.jpg'),
						title:'производственная система ERG',
						show: true,
						elements:[
							{
								title:'АКМЕРГЕН',
								image:'',
								list:[
									'Социальный эксперимент',
									'<br>',
									'Номинация: «Внутрикорпоративные коммуникации»',
								]
							},
						]
					}
				]
            },
            {
				year:'2019',
				blocks:[
					{
						href:'/projects/armandastar/',
						image:require('@/assets/img/pages/awards/image-5.jpg'),
						title:'film social projects a series of documentaries ARMANDASTAR',
						show: true,
						elements:[
							{
								title:'RED JOLBORS',
								image:require('@/assets/img/pages/awards/award-3.png'),
								list:[
									'Номинация: «Социальные ролики»',
									'Награда: бронза',
								]
							},
						]
					},
					{
						href:'https://www.youtube.com/watch?v=qZzP1Ivqi-Y',
						image:require('@/assets/img/pages/awards/qazaqgeo.jpg'),
						title:'Qazaq Geography',
						show: true,
						elements:[
							{
								title:'',
								image:'',
								list:[
									'Благодарность от А. Мамина',
								]
							},
						]
					}
				]
            },
            {
				year:'2017',
				blocks:[
					{
						href:'https://www.youtube.com/watch?v=0OGpIW35obc',
						image:require('@/assets/img/pages/awards/expo.jpg'),
						title:'международная специализированная выставка ЭКСПО-2017  в городе Астана',
						show: true,
						elements:[
							{
								title:'',
								image:'',
								list:[
									'Благодарность за значительный вклад в организацию и проведение',
									'<br>',
									'А.Есимов',
								]
							},
						]
					},
					{
						href:'/projects/v-ozhidanii-expo/',
						image:require('@/assets/img/pages/awards/open_expo.jpg'),
						title:'Открывая мир ЭКСПО',
						show: true,
						elements:[
							{
								title:'RED JOLBORS',
								image:'',
								list:[
									'',
								]
							},
						]
					}
				]
                
            },
            {
				year:'2016',
				blocks:[
					{
						href:'https://www.youtube.com/watch?v=tmOUJUjc_sM',
						image:require('@/assets/img/pages/awards/shubarkol.jpg'),
						title:'Репозиционирование шубаркольского угля',
						show: true,
						elements:[
							{
								title:'Ақмерген',
								image:'',
								list:[
									'Номинация: «Лучший проект в области бизнес-коммуникаций»',
								]
							},
						]	
					},
					{
						image:require('@/assets/img/pages/awards/tedx.jpg'),
						title:'«TEDX Almaty»',
						show: false,
						elements:[
							{
								title:'',
								image:'',
								list:[
									'Амедиа общественный фонд благодарность за поддержку идей, достойных распространения',
								]
							},
						]
					}
				]
            },
            {
				year:'2015',
				blocks:[
					{
						href:'/projects/davayte-pomnit-o-pobedakh-chashche/',
						image:require('@/assets/img/pages/awards/image-1.jpg'),
						title:'First documentary about advertising industry from the inside',
						show: true,
						elements:[
							{
								title:'СОЦИАЛЬНЫЙ РОЛИК «ДАВАЙТЕ ПОМНИТЬ О ПОБЕДАХ ЧАЩЕ!»',
								image:require('@/assets/img/pages/awards/award-3.png'),
								list:[
									'Kyiv international advertising festival',
									'Номинация: «Лучший сценарий»',
									'Награда: бронза',
								]
							},
						]
					},
				]
            },
            {
                year:'2014',
                blocks:[
                    {
						href:'/projects/davayte-pomnit-o-pobedakh-chashche/',
                        image:require('@/assets/img/pages/awards/image-1.jpg'),
                        title:'СОЦИАЛЬНЫЙ РОЛИК «ДАВАЙТЕ ПОМНИТЬ О ПОБЕДАХ ЧАЩЕ!»',
						show: true,
                        elements:[
                            {
                                title:'RED JOLBORS',
                                image:require('@/assets/img/pages/awards/award-2.png'),
                                list:[
                                    'Номинация: «Социальные ролики»',
                                    'Награда: серебро',
                                ]
                            },
                            {
                                title:'RED JOLBORS',
                                image:require('@/assets/img/pages/awards/award-3.png'),
                                list:[
                                    'Номинация: «FILM Мастерство исполнения»',
                                    'Награда: бронза',
                                ]
                            },
                        ]
                    },
                    {
						href:'/projects/bolshe-chem-kollegi-bolshe-chem-semya/',
                        image:require('@/assets/img/pages/awards/image-2.jpg'),
                        title:'СОЦИАЛЬНЫЙ РОЛИК «ТРУДОВЫЕ ДИНАСТИИ»',
						show: true,
                        elements:[
                            {
                                title:'RED JOLBORS',
                                image:require('@/assets/img/pages/awards/award-3.png'),
                                list:[
                                    'Номинация: «Социальные ролики»',
                                    'Награда: бронза',
                                ]
                            },
                        ]
                    },
					{
						href:'/projects/malenkimi-shagami-k-bolshoy-tseli/',
						image:require('@/assets/img/pages/awards/dobromilo.jpg'),
						title:'«ДОБРОВОЛЬНОЕ ОБЩЕСТВО «МИЛОСЕРДИЕ»',
						show: true,
						elements:[
							{
								title:'Благотворительный Фонд',
								image:'',
								list:[
									'Благодарность за помощь детям.  Директор Аружан Саин',
								]
							},
						]
					}
                ]
                

            },
        ]
    },
    mutations:{

    },
    actions:{

    },
    getters:{
        getAwards(state){
            return state.awardsList
        }
    }
}

export default moduleAwards;