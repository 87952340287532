<template>
    <div class="saboutus">

        <div class="saboutus__header">

             <video playsinline
                    webkit-playsinline
                    preload="metadata" 
                    muted
                    loop 
                    autoplay
                    class="saboutus__header-video"
                    poster="@/assets/img/pages/special/aboutus/cinema.jpg"
                    >
                <source src="@/assets/video/special/back-2.mp4" type="video/mp4"/>
            </video>

            <transition name="fade">
                <div v-if="!loaded" class="saboutus__header-back">
                    <img src="@/assets/img/pages/special/aboutus/cinema.jpg" alt="" class="saboutus__header-back-img">
                </div>
            </transition>


            <div class="sdetail__anim">
				<img src="@/assets/img/pages/special/round-sm.svg" alt="" class="sdetail__round sdetail__round_sm">
				<img src="@/assets/img/pages/special/round-sm.svg" alt="" class="sdetail__round sdetail__round_sm-2">
				<img src="@/assets/img/pages/special/round-md.svg" alt="" class="sdetail__round sdetail__round_md">
				<img src="@/assets/img/pages/special/round-l.svg" alt="" class="sdetail__round sdetail__round_l">
				<img src="@/assets/img/pages/special/round-xl.svg" alt="" class="sdetail__round sdetail__round_xl">
				<div class="sdetail__play" @click="modalOpened=true">
					<img src="@/assets/img/pages/special/play-light.svg" alt="" class="sdetail__play-icon">
					<p class="sdetail__play-text sdetail__play-text_light sdetail__play-text_courier" v-html="$t('messages.specialprojects.play')">Посмотреть видео</p>
				</div>
			</div>

            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <div class="saboutus__header-content">
                            <div class="saboutus__header-title">
                                <img src="@/assets/img/pages/special/aboutus/logo.svg" alt="" class="saboutus__header-title-logo">
                                <p class="saboutus__header-title-text" v-html="$t('messages.specialprojects.film')"></p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        
        <div class="saboutus__about">
            <div class="container">
                <div class="row">
                    <div class="col-5 col-md-12">

                        <h1 class="saboutus__about-slogan" v-html="$t('messages.specialprojects.slogan')"></h1>
                        
                    </div>
                    <div class="col-7 col-start-6 col-md-12 col-md-start-1">

                        <p class="saboutus__about-question" v-html="$t('messages.specialprojects.who')"></p>
                        <p class="saboutus__about-question" v-html="$t('messages.specialprojects.clients')"></p>
                        <p class="saboutus__about-question" v-html="$t('messages.specialprojects.principles')"></p>
                        <p class="saboutus__about-text" v-html="$t('messages.specialprojects.people')"></p>

                    </div>
                </div>
            </div>
        </div>

        <div class="saboutus__slider">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in sliderImages" :key="index">
                        <div class="saboutus__slider-item">
                            <img :src="item.src" alt="" class="saboutus__slider-item-image">
                        </div>
                    </div>

                </div>
            </div>

            <div class="saboutus__slider-dots">
                <div class="saboutus__slider-dots-item" @click="slideTo(index)" :class="activeIndex==index?'saboutus__slider-dots-item_active':''" v-for="(item,index) in sliderImages" :key="index"></div>
            </div>
        </div>

        <div class="saboutus__team">
            <div class="container">
                <div class="row">
                    <div class="col-12">

                        <project-team :persons="$t('messages.specialprojects.persons')" theme="dark"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="sdetail__same">

			<div class="container  container--overvisible">
				<div class="row">
					<div class="col-12">

						<div class="sdetail__blocktitle sdetail__blocktitle_color_red  sdetail__blocktitle_mb">
							<p class="sdetail__blocktitle-text"  v-html="$t('messages.specialprojects.analog')"></p>
						</div>

                        <div class="sdetail__same-list">
                            <project-similar :elements="similarProjects" />
                        </div>

					</div>
				</div>
			</div>

		</div>

        <transition name="fade" mode="out-in">
			<div class="sdetail__modal sdetail_mode_video" v-if="modalOpened">
				<div class="sdetail__modal-overlay" @click="modalOpened=false"></div>

				<div class="sdetail__modal-content">

					<img  src="@/assets/img/icons/close.svg" @click="modalOpened=false" alt="" class="sdetail__modal-close sdetail__modal-close_accent">

                    <transition name="fade">
                        <div class="sdetail__video" >
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="sdetail__videowrap">
                                            <div style="padding:56.25% 0 0 0;position:relative;width:100%;height:auto">
                                                <iframe :src="'https://player.vimeo.com/video/518434430?title=0&byline=0&portrait=0'" 
                                                style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                                                frameborder="0" 
                                                allow="autoplay; fullscreen" 
                                                allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>


				</div>
			</div>
		</transition>

    </div>
</template>

<script>
    import ProjectTeam from '../projects/components/ProjectTeam'

    import {
        mapMutations
    } from 'vuex'

    import Swiper from 'swiper';
    import 'swiper/swiper-bundle.css';

    import ProjectSimilar from "../projects/components/ProjectSimilar"

    export default {
        name:'SpecialAboutUs',
        components:{
            ProjectTeam,

            ProjectSimilar,
        },

        mounted(){
			document.querySelector('.header').classList.add('header--absolute')
			// document.querySelector('.langswitcher').classList.add('notvisible')

            this.setMetaInfo({
                page:'about_us',
            })
            // this.setMetaInfoByParams({
            //     title:'Black&White: фильм о нас',
            //     type:'Имиджевый видеоролик',
            //     description:'Мы предлагаем вам погрузиться в атмосферу офиса Black&White. Кто мы? Кто наши клиенты? Каким принципам следуем в работе? Рассказывают сотрудники компании.',
            //     link:'https://bw.com.kz/projects/specialprojects/about-us',
            //     image:'https://bw.com.kz/thumbnail-specialabout.jpg'
            // })

            this.loaded = true

            const swiper = new Swiper('.swiper-container', {
                loop: true,
                spaceBetween: 31,
                slidesPerView:3,
                autoplay: {
                    delay: 5000,
                },
                breakpoints:{
                    320:{
                        slidesPerView:1,
                    },
                    600:{
                        slidesPerView:3,
                    },
                },
            });

            swiper[0].on('slideChange', function () {
                    this.activeIndex = swiper[0].activeIndex
            }.bind(this))

        },
        watch:{
            modalOpened(value){
                let backgroundVideo = document.querySelector('.saboutus__header-video')

                if(value){
                    backgroundVideo.pause()
                } else {
                    backgroundVideo.play()
                }
            }
        },
		beforeDestroy(){
			document.querySelector('.header').classList.remove('header--absolute')
			document.querySelector('body').classList.remove('app--unscroll')
			document.querySelector('.langswitcher').classList.remove('notvisible')

		},
        data(){
            return {
                loaded:false,
                modalOpened:false,
                swiper:'',
                sliderImages:[
                    {
                        src:require('@/assets/img/pages/special/slide/1.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/2.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/3.jpg'),
                        alt:'',
                    },
                     {
                        src:require('@/assets/img/pages/special/slide/4.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/5.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/6.jpg'),
                        alt:'',
                    },
                     {
                        src:require('@/assets/img/pages/special/slide/7.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/8.jpg'),
                        alt:'',
                    },
                    {
                        src:require('@/assets/img/pages/special/slide/9.jpg'),
                        alt:'',
                    },
                ],
                activeIndex:0,
                persons:[
                    {
                        status:'автор сценария и режиссёр',
                        list:[
                            'Ксения Придатченко',
                        ]
                    },
                     {
                        status:'ассистент операторов-постановщиков',
                        list:[
                            'Куаныш Сансызбаев',
                        ]
                    },
                    {
                        status:'продюсер',
                        list:[
                            'Айгерим Токаманова',
                        ]
                    },
                    {
                        status:'цветокоррекция',
                        list:[
                            'Марлен Нурашев',
                        ]
                    },
                    {
                        status:'операторы-постановщики',
                        list:[
                            'Айвар Кыдырбаев',
                            'Жанибек Машрапов',
                        ]
                    },
                    {
                        status:'звукооператор',
                        list:[
                            'Казбек Есмурзаев',
                        ]
                    },
                    {
                        status:'режиссёр монтажа',
                        list:[
                            'Улан Камзин',
                        ]
                    },
                     {
                        status:'звукорежиссёр',
                        list:[
                            'Диас Лестеги',
                        ]
                    },
                    {
                        status:'дизайнер и моушен-дизайнер',
                        list:[
                            'Аян Кизабекова',
                        ]
                    },
                    {
                        status:'гримёр',
                        list:[
                            'Толганай Абуталип',
                        ]
                    },
                    {
                        status:'автор заставки',
                        list:[
                            'Тогжан Кемелова',
                        ]
                    },
                    
                    {
                        status:'водитель',
                        list:[
                            'Мухагали Байболганов',
                        ]
                    },
                    {
                        status:'редактор',
                        list:[
                            'Жанель Жазетова',
                        ]
                    },
                ],
                similarProjects:[
                    {
                        "id": "40",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/6d3/419_236_2/6d3fafa4e29eeab329c33fb96eef68c1.jpg",
                        "name": "Давайте помнить о победах чаще!",
                        "code": "davayte-pomnit-o-pobedakh-chashche"
                    },
                    {
                        "id": "42",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/a81/419_236_2/a81ff8ff20d45df9a3a09fe2b4d011f7.jpg",
                        "name": "Армандастар",
                        "code": "armandastar"
                    },
                    {
                        "id": "77",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/643/419_236_2/6431557eecc1258268857c8c1c417303.jpg",
                        "name": "Социальный эксперимент ERG",
                        "code": "sotsialnyy-eksperiment-erg"
                    },
                    {
                        "id": "81",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/fac/419_236_2/fac9767894f05ab0396aa798ebc377ef.jpg",
                        "name": "Приходи на выборы!",
                        "code": "idi-na-vybory"
                    },
                    {
                        "id": "83",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/103/419_236_2/103a20ccda2f7002e9c3abe79115812d.jpg",
                        "name": "Больше, чем коллеги, больше, чем семья",
                        "code": "bolshe-chem-kollegi-bolshe-chem-semya"
                    },
                    {
                        "id": "86",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/293/419_236_2/293221f1437aae1bfbce0a204ae7a36e.jpg",
                        "name": "Маленькими шагами к большой цели",
                        "code": "malenkimi-shagami-k-bolshoy-tseli"
                    },
                    {
                        "id": "87",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/366/419_236_2/36695373e5ec66dd2306244b690d2069.jpg",
                        "name": "Меня зовут Ардак",
                        "code": "menya-zovut-ardak"
                    },
                    {
                        "id": "467",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/b02/419_236_2/b0295ed63a030678c1d29254e9f115fa.jpg",
                        "name": "Социальный ролик «Отан Ана»",
                        "code": "rodina-mat"
                    },
                    {
                        "id": "468",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/02f/419_236_2/02f74c932afd48899d983693638120c9.jpg",
                        "name": "Социальный ролик «Взрослый мир глазами ребенка»",
                        "code": "videorolik-glazami-detey"
                    },
                    {
                        "id": "483",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/e30/419_236_2/e30381426e95fd6abd0b4238cc209b69.jpg",
                        "name": "Новая реальность. «Самоизолироваться #НеСтыдно»",
                        "code": "rolik-1-na-kazakhskom-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey-"
                    },
                    {
                        "id": "484",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/365/419_236_2/365fb7de41e8fc8850a04b5430a64554.jpg",
                        "name": "Новая реальность. «Носить маску #НеСтыдно»",
                        "code": "rolik-2-na-kazakhskom-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "485",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/b8f/419_236_2/b8f0ceddcff647612df49d35391b2fe9.jpg",
                        "name": "Новая реальность. «Соблюдать правлила #НеСтыдно»",
                        "code": "rolik-3-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "486",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/a6f/419_236_2/a6f863b30411df1f3a956234e95c0bb8.jpg",
                        "name": "Новая реальность. «Дистанцироваться #НеСтыдно»",
                        "code": "rolik-4-iz-serii-rolikov-novaya-realnost-s-virusnoy-distributsiey"
                    },
                    {
                        "id": "487",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/f5a/419_236_2/f5ae9147ad81d6c078e543a959201d48.jpg",
                        "name": "Стоп VIRUS",
                        "code": "rolik-ekspleyner-stopvirus"
                    },
                    {
                        "id": "488",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/f31/419_236_2/f31bd3a34867903eb699bf808cc9a1f4.jpg",
                        "name": "Казатомпром. Наши истории. Акмарал Сарыбаева",
                        "code": "videoroliki-o-sotrudnikakh-kap-akmaral-"
                    },
                    {
                        "id": "489",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/62b/419_236_2/62baed26ee4d3f22eeb49d67f04cc5c0.jpg",
                        "name": "Казатомпром. Наши истории. Ринат Байсултанов",
                        "code": "videoroliki-o-sotrudnikakh-kap-2"
                    },
                    {
                        "id": "490",
                        "src": "https://api.blackandwhite.kz/upload/resize_cache/iblock/848/419_236_2/848a8c1451331c2af7361da4cbef256f.jpg",
                        "name": "Казатомпром. Наши истории. Жаксылык Тажибаев",
                        "code": "videoroliki-o-sotrudnikakh-kap-3"
                    },
                ]
            }
        },
        methods:{
             ...mapMutations([
                'setMetaInfoByParams',
                'setMetaInfo',
            ]),

            slideTo(index){

                let swiper = document.querySelector('.swiper-container').swiper

                swiper.slideTo(index)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>