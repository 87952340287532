import { render, staticRenderFns } from "./ClientsList.vue?vue&type=template&id=46690fed&scoped=true&"
import script from "./ClientsList.vue?vue&type=script&lang=js&"
export * from "./ClientsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46690fed",
  null
  
)

export default component.exports